import React, {FunctionComponent, useEffect, useState} from 'react';
import {
  setOpenDocumentList,
  setOpenPartnerConventionAnnexFormEvent,
  setOpenPartnerUploadForm
} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import PartnerCommissionDatalist from "../../../Datalist/partnerCommissionDatalist";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {PartnerInterface} from "../../../../../../domain/Partner/Partner";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {setConventionAnnex} from "../../../../../store/component/partner";
import DocumentGateway from "../../../../../../gateway/Partner/Document/DocumentGateway";
import PartnerGateway from "../../../../../../gateway/Partner/PartnerGateway";
import {ConventionAnnexInterface} from "../../../../../../domain/Partner/ConventionAnnex";

type Props = {
  referential: ReferentielInterface
  partner?: PartnerInterface
  isLectureMode?: boolean
}

const Convention: FunctionComponent<Props> = ({referential, partner, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const partnerGateway = new PartnerGateway()

  const conventionAnnexArray = useAppSelector((state) => state.partner.conventionAnnexArray)

  useEffect(() => {
    if (partner?.id) {
      partnerGateway.getConventionList(partner.id)
        .then((convention) => {
          dispatch(setConventionAnnex(convention))
        })
    }
  }, [partner?.id])

  const deleteCommission = (commissionId: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirmDeleteCommission(commissionId)} onClose={onClose}
                                   message={t('partner.form.commission.delete-alert-message')}/>)
      }
    });
  }

  const onConfirmDeleteCommission = (commissionId: string) => {
    if (partner?.id) {
      partnerGateway.deleteCommission(partner.id, commissionId).finally(
        () => {
          const newConventionAnnexList = conventionAnnexArray.filter(value => value.id !== commissionId)
          dispatch(setConventionAnnex(newConventionAnnexList));
        }
      )
    }
  }

  const viewFilesCallback = (conventionId: string | null) => {
    if (conventionId && partner?.id) {
      new DocumentGateway(partner?.id).getConventionFiles(partner?.id, conventionId).then(response => {
        dispatch(setOpenDocumentList({
          show: true,
          documentList: response || [],
          gateway: null,
        }))
        if (response && response.length > 0) {
          response.map((document) => {
            if (partner?.id) {
              new DocumentGateway(partner?.id).getConventionFile(partner.id, conventionId, document.id).then(response => {
                const file = window.open('')

                if (null !== file && response !== null) {
                  file.document.write(
                    "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='" + response + "'></iframe></body>"
                  )
                }
              })
            }
          })
        }
      })
    }
  }

  const uploadCallBack = (show: boolean, documents: any[] | null, partnerId: string | null, type: string | null, conventionId: string | null) => {
    dispatch(setOpenPartnerUploadForm({
      show: show,
      documents: documents,
      partnerId: partnerId,
      type: type,
      conventionId: conventionId
    }))
  }

  return (
    <div>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.convention-annex.title')}</div>
        {!isLectureMode && <div className="form-bloc__shortcut">
            <button type="button" className="button-reset u-txt-with-icon"
                    onClick={() => dispatch(setOpenPartnerConventionAnnexFormEvent({
                      show: true,
                      conventionAnnex: null,
                      partner: partner ?? null
                    }))}>

                <img src={iconAdd} alt=""/>
              {t('partner.form.convention-annex.add')}
            </button>
        </div>}
        {partner &&
            <PartnerCommissionDatalist
                commissions={conventionAnnexArray ?? []}
                partner={partner}
                uploadCallback={uploadCallBack}
                deleteCommissionCallback={deleteCommission}
                viewFilesCallback={viewFilesCallback}
                callerType={"datalist"}
            />
        }
      </div>
    </div>
  );
}

export default Convention;
