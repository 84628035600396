import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenPartnerRelationLinkFormEvent} from '../../../../store/component/event'
import {addRelationLink, updateRelationLink} from '../../../../store/component/partner'
import {RelationLink} from '../../../../../domain/Partner/RelationLink'
import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import {
  ReferentielInterface
} from '../../../../../domain/Referentiel/ReferentielInterface'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastSuccess} from "../../../util/Toast";
import {usePrompt} from "../../../util/Navigation";
import Input from '../../Elements/Input'
import SelectCustom from '../../Elements/Select'

interface IFormInput {
  id: string
  lastName: string
  firstName: string
  link: string
}

type Props = {
  referential: ReferentielInterface
}

const AddRelationLink: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openPartnerRelationLinkForm = useAppSelector((state) => state.event.openPartnerRelationLinkForm)

  useEffect(() => {
    blockScrollBody(openPartnerRelationLinkForm.show)

    if (openPartnerRelationLinkForm.relationLink) {
      setValue('id', openPartnerRelationLinkForm.relationLink.id)
      setValue('lastName', openPartnerRelationLinkForm.relationLink.lastName)
      setValue('firstName', openPartnerRelationLinkForm.relationLink.firstName)
      setValue('link', openPartnerRelationLinkForm.relationLink.link)
    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('lastName', '')
      setValue('firstName', '')
      setValue('link', '')
    }
  }, [openPartnerRelationLinkForm])

  const { register, control, watch, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const relationLink = new RelationLink(
      data.id,
      data.lastName,
      data.firstName,
      data.link,
    )

    if (openPartnerRelationLinkForm.relationLink) {
      dispatch(updateRelationLink(relationLink))
    } else {
      dispatch(addRelationLink(relationLink))
    }
    dispatch(setOpenPartnerRelationLinkFormEvent({show: false, relationLink: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('partner.notify.add-relation-link-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenPartnerRelationLinkFormEvent({show: false, relationLink: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPartnerRelationLinkForm.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openPartnerRelationLinkForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-relationLink-form">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.relation-link.add')}</div>
            <div className="form-bloc__form flex-container">
              <Input id={`id`}
                     name={`id`}
                     label={t('partner.form.relation-link.id')}
                     register={register}
                     classes={`col-md-6`}
                     disabled={true}
              />
              <Input id={`lastName`}
                     name={`lastName`}
                     label={t('partner.form.relation-link.lastname')}
                     register={register}
                     required={true}
                     classes={`col-md-12`}
              />
              <Input id={`firstName`}
                     name={`firstName`}
                     label={t('partner.form.relation-link.firstname')}
                     register={register}
                     required={true}
                     classes={`col-md-12`}
              />
              <SelectCustom classes="col-md-12"
                            id={`link`}
                            name={`link`}
                            label={t('partner.form.relation-link.link')}
                            options={referential.global.relationship}
                            register={register}
                            required={true}
              />
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddRelationLink
