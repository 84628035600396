import dayjs from 'dayjs'
import Caller from '../../domain/Api/Caller'
import {
  ActionList,
  FilterSessionInterface,
  FilterSessionInvestorInterface,
  ISettingForm,
  SessionGatewayInterface,
  SessionInterface,
  SessionInvestorListInterface,
  SessionListInterface,
  TSessionProducts,
} from '../../domain/Distribution'
import { AddressInterface } from '../../domain/Distribution/Address'
import envVariable from '../../infrastructure/presentation/util/envVariable'

interface InvestorApiInterface {
  accountCode: string
  accountType: string
  personType: string
  legalName: string
  iban: string | null
  address: AddressInterface
  email: string
  optinDividend: boolean
  optinAll: boolean
  extranet: boolean
}

export default class DistributionSessionGateway implements SessionGatewayInterface {
  async getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterSessionInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: SessionListInterface[] | null; nb_element: number } | null> {
    try {
      const data = await Caller.executeGet(
        `${envVariable('REACT_APP_API_URL')}/distribution/sessions?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
        {
          keyword: filter?.keyword || '',
        },
        signal
      )
      if (!data) {
        return {
          data: [],
          nb_element: 0,
        }
      }

      return data
    } catch (e) {
      return {
        data: [],
        nb_element: 0,
      }
    }
  }

  async getInvestorList(
    sessionId: string,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterSessionInvestorInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: SessionInvestorListInterface[] | null; nb_element: number } | null> {
    try {
      const data = await Caller.executeGet(
        `${envVariable('REACT_APP_API_URL')}/distribution/sessions/${sessionId}/investors?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
        {
          keyword: filter?.keyword || '',
          product: filter?.product?.label || '',
          term: filter?.term || '',
          periodicity: filter?.periodicity || '',
          paymentMethod: filter?.paymentMethod || '',
        },
        signal
      )
      if (!data) {
        return {
          data: [],
          nb_element: 0,
        }
      }

      return {
        data: data.data.map((item: any) => {
          const subscriber = item.investors.filter(
            (investor: InvestorApiInterface) => investor.personType === 'souscripteur'
          )[0]
          const coSubscriber = item.investors.filter(
            (investor: InvestorApiInterface) => investor.personType === 'co-souscripteur'
          )[0]

          const subscriberAddressFormatted =
            subscriber && subscriber.address
              ? `${subscriber.address.address1 ?? ''} ${subscriber.address.address2 ?? ''} ${subscriber.address.address3 ?? ''} ${subscriber.address.city ?? ''} ${subscriber.address.postcode ?? ''} ${subscriber.address.country ?? ''}`.trim()
              : null
          const coSubscriberAddressFormatted =
            coSubscriber && coSubscriber.address
              ? `${coSubscriber.address.address1 ?? ''} ${coSubscriber.address.address2 ?? ''} ${coSubscriber.address.address3 ?? ''} ${coSubscriber.address.city ?? ''} ${coSubscriber.address.postcode ?? ''} ${coSubscriber.address.country ?? ''}`.trim()
              : null

          return {
            sessionId: item.sessionId,
            accountId: item.accountId,
            totalNbShare: item.totalNbShare,
            subscriber: {
              ...subscriber,
              addressFormatted: subscriberAddressFormatted,
            },
            coSubscriber: {
              ...coSubscriber,
              addressFormatted: coSubscriberAddressFormatted,
            },
            product: item.product,
            financialAmount: item.financialAmount,
            propertyAmount: item.propertyAmount,
            pl: item.pl,
            ps: item.ps,
            amount: item.amount,
            paymentDate: dayjs(item.paymentDate.date, 'YYYY-MM-DD HH:II:SS').format('DD/MM/YYYY') || null,
            paymentMethod: item.paymentMethod,
            paymentState: item.paymentState,
            sendMode: item.sendMode,
            sendDate: dayjs(item.sendDate.date, 'YYYY-MM-DD HH:II:SS').format('DD/MM/YYYY') || null,
            sendState: item.sendState,
          }
        }),
        nb_element: data.nb_element,
      }
    } catch (e) {
      console.log(e)
      return {
        data: [],
        nb_element: 0,
      }
    }
  }

  async investorListExport(sessionId: string, filter: FilterSessionInvestorInterface | null): Promise<Blob | null> {
    const data = await Caller.executeGetAsBlob(
      `${envVariable('REACT_APP_API_URL')}/distribution/sessions/${sessionId}/investors/extract`,
      {
        keyword: filter?.keyword || '',
      }
    )

    if (data instanceof Blob) {
      return data
    }

    return null
  }

  get(sessionId: string): Promise<SessionInterface | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${sessionId}`, {})
      .then(data => {
        if (data) {
          data.calculeAt = data.calculeAt ? dayjs(data.calculeAt, 'DD/MM/YYYY').format('DD/MM/YYYY') : null
          data.validateAt = data.validateAt ? dayjs(data.validateAt, 'DD/MM/YYYY').format('DD/MM/YYYY') : null
          data.paidAt = data.paidAt ? dayjs(data.paidAt, 'DD/MM/YYYY').format('DD/MM/YYYY') : null
          data.sendAt = data.sendAt ? dayjs(data.sendAt, 'DD/MM/YYYY').format('DD/MM/YYYY') : null
          data.year = dayjs(data.year, 'YYYY').toDate()

          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  create(session: ISettingForm, type: string): Promise<SessionInterface> {
    const params = {
      type: type,
      label: session.sessionCriteria.label,
      month: session.sessionCriteria.month,
      year: dayjs(session.sessionCriteria.year).format('YYYY'),
      amounts: Object.values(session.sessionProducts).map((product: Partial<TSessionProducts>) => ({
        productId: product.productId,
        financialAmount: product.financialAmount,
        propertyAmount: product.propertyAmount,
      })),
    }

    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/distribution/sessions`, params).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0,
      }
    })
  }

  update(session: ISettingForm, type: string, uuid: string): Promise<SessionInterface> {
    const params = {
      type: type,
      label: session.sessionCriteria.label,
      month: session.sessionCriteria.month,
      year: dayjs(session.sessionCriteria.year).format('YYYY'),
      amounts: Object.values(session.sessionProducts).map((product: Partial<TSessionProducts>) => ({
        productId: product.productId,
        financialAmount: product.financialAmount,
        propertyAmount: product.propertyAmount,
      })),
    }

    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${uuid}`, { ...params }).then(
      data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      }
    )
  }

  getActions(uuid: string): Promise<ActionList | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/sessions/${uuid}/actions`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string | null): Promise<ActionList | null> {
    return Caller.executePut(
      `${envVariable('REACT_APP_API_URL')}/distribution/sessions/${uuid}/actions/${actionSlug}`,
      {
        date: date,
        reason: reason,
      }
    ).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }
}
