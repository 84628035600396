import { InvestorGatewayInterface, InvestorSheetListInterface } from '../../../domain/Distribution'
import {ListRequestInterface} from './ListRequest'


export default class ListUseCase
{
  public investorGateway

  constructor(investorGateway: InvestorGatewayInterface) {
    this.investorGateway = investorGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: InvestorSheetListInterface[]|null, nb_element: number}|null> {
    return await this.investorGateway.getList(
      ListRequest.accountId,
      ListRequest.currentPage,
      ListRequest.itemPerPage,
      ListRequest.filter,
      signal,
      sort,
      order).then(response => response)
  }
}
