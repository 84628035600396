import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'
import Input from '../../../../Elements/Input'

const ImmatriculationBlock: FunctionComponent = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <div className={`form-bloc form--bloc--immatriculation`}>
      <div className="form-bloc__title">{t('account.form.immatriculation.title')}</div>
      <div className="form-bloc__form flex-container">
        <div className="col-md-3">
          <Input id={`customerCode`}
                 name={`customerCode`}
                 label={t('account.form.immatriculation.customerCode')}
                 register={register}
                 disabled={true} />
        </div>
        <div className="col-md-3">
          <Input id={`externalDepositaryCode`}
                 name={`externalDepositaryCode`}
                 label={t('account.form.immatriculation.externalDepositaryCode')}
                 register={register}
                 disabled={true} />
        </div>
        <div className="col-md-3">
          <Input id={`externalCode`}
                 name={`externalCode`}
                 label={t('account.form.immatriculation.externalCode')}
                 register={register}
                 disabled={true} />
        </div>
        <div className="col-md-3">
          <Input id={`recoveryCode`}
                 name={`recoveryCode`}
                 label={t('account.form.immatriculation.recoveryCode')}
                 register={register}
                 disabled={true} />
        </div>
      </div>
    </div>
  );
}

export default ImmatriculationBlock
