import React, {FunctionComponent, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import RadioYesOrNot from '../../../../Elements/RadioYesOrNot'
import InputDate from '../../../../Elements/InputDate'

type Props = {
  referential: ReferentielInterface
  isLectureMode: boolean | undefined
  indexPerson: number
}

const CommunicationBlock: FunctionComponent<Props> = ({referential, isLectureMode, indexPerson}) => {
  const { t } = useTranslation()
  const {register, setValue, watch} = useFormContext()

  const watchReceiveAllBy = watch(`persons.${indexPerson}.optinAll.type`);

  useEffect(() => {
    if (watchReceiveAllBy) {
      synchronizeOptInFields(watchReceiveAllBy);
    }
  }, [watchReceiveAllBy]);

  const synchronizeOptInFields = (value: boolean) => {
    setValue(`persons.${indexPerson}.optinDividend.type`, value);
    setValue(`persons.${indexPerson}.optinFiscality.type`, value);
    setValue(`persons.${indexPerson}.optinGeneralAssembly.type`, value);
    setValue(`persons.${indexPerson}.optinInvitation.type`, value);
    setValue(`persons.${indexPerson}.optinMarketing.type`, value);
  };

  return (
    <table className="table--communication">
          <thead>
          <tr>
            <th/>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <th key={`communication-type-th-${indexPerson}-${communication_type.value}`} className="form-control__label">{communication_type.label}</th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinMarketing')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={`persons.${indexPerson}.optinMarketing-${communication_type.value}`}>
                  <div className="form-control__input">
                    <input type="radio"
                           value={communication_type.value} {...register(`persons.${indexPerson}.optinMarketing.type`)}
                           disabled={isLectureMode}/>
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinDividend')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={`persons.${indexPerson}.optinDividend-${communication_type.value}`}>
                  <div className="form-control__input">
                    <input type="radio"
                           value={communication_type.value} {...register(`persons.${indexPerson}.optinDividend.type`)}
                           disabled={isLectureMode}/>
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinFiscality')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={`persons.${indexPerson}.optinFiscality-${communication_type.value}`}>
                  <div className="form-control__input">
                    <input type="radio"
                           value={communication_type.value} {...register(`persons.${indexPerson}.optinFiscality.type`)}
                           disabled={isLectureMode}
                    />
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinGeneralAssembly')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={`persons.${indexPerson}.optinGeneralAssembly-${communication_type.value}`}>
                  <div className="form-control__input">
                    <input type="radio"
                           value={communication_type.value} {...register(`persons.${indexPerson}.optinGeneralAssembly.type`)}
                           disabled={isLectureMode}/>
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinInvitation')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={`persons.${indexPerson}.optinInvitation-${communication_type.value}`}>
                  <div className="form-control__input">
                    <input type="radio"
                           value={communication_type.value} {...register(`persons.${indexPerson}.optinInvitation.type`)}
                           disabled={isLectureMode}/>
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinAll')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={`persons.${indexPerson}.optinAll-${communication_type.value}`}>
                  <div className="form-control__input">
                    <input type="radio"
                           value={communication_type.value} {...register(`persons.${indexPerson}.optinAll.type`)}
                           disabled={isLectureMode}/>
                  </div>
                </td>
              )
            })}
          </tr>
          </tbody>
        </table>
  )
}

export default CommunicationBlock
