import React, {useMemo} from "react";
import CheckListIcon from "../../../../../assets/images/icons/checklist.svg";
import DocumentIcon from "../../../../../assets/images/icons/document_export.gif";
import ProcessBar from "../../ProcessBar/ProcessBar";

export interface ICommissionCheckListDetailChild {
  formName: string,
  title: string|null,
  exportUrl: string|null
  status: boolean
}

export interface ICommissionCheckListDetail {
  formName: string,
  title: string|null,
  exportUrl: string|null
  status: boolean,
  children: ICommissionCheckListDetailChild[]
}

export interface ICommissionCheckList {
  title?: string|undefined
  icon?: string|undefined
  checkLists: ICommissionCheckListDetail[]|null
  handleClick?: CallableFunction|undefined
  hideIcon?: boolean,
  styles?: React.CSSProperties|undefined
  cssClass?: string|undefined
}

const CommissionCheckList: React.FC<ICommissionCheckList> = ({
  title,
  icon,
  checkLists,
  handleClick,
  hideIcon = false,
  styles,
  cssClass
}) => {
  const process = useMemo(() => {
    return checkLists ? checkLists.reduce((totalIsTrue,item) => {
      if (item.children.length > 0) {
        return totalIsTrue + item.children.reduce((a, b) => {
          return a + Number(b.status);
        }, 0)
      } else {
        return totalIsTrue + Number(item.status)
      }

    }, 0) : 0;

  }, [checkLists])

  const sizeOfCheckList = useMemo(() => {
    if (!checkLists) {
      return 0;
    }

    return checkLists.reduce((totalSize, item) => {
      if (item.children.length > 0) {
        return totalSize + item.children.length;
      } else {
        return totalSize + 1;
      }
    }, 0)
  }, [checkLists])

  const calPercentage = (process: number, total: number) => {
    return parseInt(((100 / total) * process).toFixed(1));
  }

  return <div className="flex items-center" onClick={() => handleClick ? handleClick() : ''} style={styles}>
    { !hideIcon && <img src={icon ? icon : DocumentIcon} width={40} alt="Checklist"/> }
    <div className="control-list-process flex-col">
      <div className={ `control-list-process${cssClass ? cssClass : ''}__title`}>
        { title }
        <div className={`control-list-process${cssClass ? cssClass : ''}__status`}>{` ${process}/${sizeOfCheckList}`}</div>
      </div>
      <ProcessBar completed={calPercentage(process, sizeOfCheckList)} processColor={'#F26E22'} bgColor={'#E4E4E4'}/>
    </div>
  </div>
}

export default CommissionCheckList;
