import {ProductListInterface, ProductGatewayInterface} from '../../../domain/Distribution'

import {ListRequestInterface} from './ListRequest'

export default class ListUseCase
{
  public productGateway

  constructor(productGateway: ProductGatewayInterface) {
    this.productGateway = productGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: ProductListInterface[]|null, nb_element: number}|null> {
    return await this.productGateway.getList(
      ListRequest.month,
      ListRequest.year,
      ListRequest.type,
      signal).then(response => response)
  }
}
