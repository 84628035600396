import React, {Component, FunctionComponent, useEffect, useState} from "react";
import Authentication from "../oauth/Authentication";
import MeGateway from "../../gateway/Me/MeGateway";
import { useAppDispatch, useAppSelector } from '../store/hook'
import { setMe } from '../store/component/me'
import GeneralSettingsGateway from "../../gateway/Settings/GeneralSettings/GeneralSettingsGateway";
import store from "../store/store";
import {setColors} from "../store/component/settings";
import PartnerLayout from "../presentation/page/Partner/Layout/PartnerLayout";
import ProductLayout from "../presentation/page/Product/Layout/ProductLayout";
import ProspectLayout from "../presentation/page/Prospect/Layout/ProspectLayout";
import CustomerLayout from "../presentation/page/Customer/Layout/CustomerLayout";
import Bubble from '../presentation/component/Bubble/Bubble'
import ReferentielGateway from '../../gateway/Referentiel/ReferentielGateway'
import {
  InitializeDefaultCity,
  InitializeReferential,
} from '../store/component/referential'
import { Country } from '../../domain/Referentiel/Country/Country'
import {
  saveAnimationInLocalStorage
} from '../presentation/util/SavePreferencesInLocalStorage'
import {useLocation, useParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import CommissioningLayout from "../presentation/page/Commissioning/Add/CommissioningLayout";
import RecurrenceLayout from "../presentation/page/Recurrence/Add/RecurrenceLayout";
import DistributionLayout from "../presentation/page/Distribution/Layout";

interface Props {
  component?: React.FC;
  layout?: "partner" | "product" | "prospect" | "customer" | "commissioning" | "recurrence" | "distribution"
  page?: string
  mode?: "add" | "edit" | "read"
}
const PrivateRoute: FunctionComponent<Props> = ({component: Component, layout, page, mode}) => {
  const dispatch = useAppDispatch()
  const {uuid} = useParams()
  const {t} = useTranslation()

  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [animation, setAnimation] = useState<boolean | null>(null)

  const location = useLocation()

  const animationStore = useAppSelector((state) => state.me.bubbleAnimation)
  const preferences = localStorage.getItem("preferences")
  const isMainPage = location.pathname === "/"

  useEffect(() => {
    if(!uuid || (uuid && animationStore.state)) {
      handleAnimation()
    }
  }, [animationStore])

  const handleAnimation = () => {
    if(!preferences) {
      // pas de preference
      if(animationStore.state === null) {
        saveAnimationInLocalStorage(preferences, true)
        setAnimation(true) // pas de preference et pas de clique sur un logo
      } else if (animationStore.state === false) {
        setAnimation(false) // pas de preference et clique sur un logo
      } else {
        setAnimation(true) // pas de preference et pas encore clique sur un logo
      }
    } else if(preferences && animationStore.state === null) {
      // preference existe et rien dans le store
      const preferenceObj = JSON.parse(preferences)
      if(preferenceObj.animation && isMainPage) {
        setAnimation(true) // preference existe et animation is true
      } else {
        setAnimation(false) // preference existe et animation is false
      }
    } else if (animationStore.state !== null) {
      // else if animation store existe
      if(animationStore.state) {
        setAnimation(true) // animation store is true
      } else {
        setTimeout(() => {
          setAnimation(false) // animation store is false
        }, 2500)
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      Authentication.isLogged()
        .then(isAuthenticated => {
          if(!isAuthenticated) {
            Authentication.authorize()
          } else {
            setAuthenticated(true);
            initializeMeAndSettingAndReferential()

          }
        })
    }
  }, []);

  const initializeMeAndSettingAndReferential = () => {
    new MeGateway().me().then((response) => dispatch(setMe(response)))
    new GeneralSettingsGateway().get().then((response => {
      if (response) {
        store.dispatch(setColors({
            colorPrimary: response.colorPrimary,
            colorSecondary: response.colorSecondary,
            colorFont: response.colorFont,
            colorBackground: response.colorBackground,
            colorHeader: response.colorHeader,
          }
        ))
      }
    }))
    new ReferentielGateway().getGlobal().then((response) => {
      if(response) {
        dispatch(InitializeReferential(response))
      }
    });
    new ReferentielGateway().getCountry("france")
      .then((response: Country[]) => dispatch(InitializeDefaultCity(response)))
  }

  const handleLayout = () => {
    if(layout && page) {
      if((layout && layout === "partner") && mode) {
        return <PartnerLayout page={page} mode={mode} />
      }
      if((layout && layout === "product") && mode) {
        return <ProductLayout page={page} mode={mode} />
      }
      if((layout && layout === "prospect") && mode) {
        return <ProspectLayout page={page} mode={mode} />
      }
      if((layout && layout === "customer") && mode) {
        return <CustomerLayout page={page} mode={mode} />
      }
      if((layout && layout === "commissioning") && mode) {
        return <CommissioningLayout page={page} mode={mode} />
      }
      if((layout && layout === "recurrence") && mode) {
        return <RecurrenceLayout page={page} mode={mode} />
      }
      if((layout && layout === "distribution") && mode) {
        return <DistributionLayout page={page} mode={mode} />
      }
    } else if(Component) {
      return <Component />
    }
  }

  return (
    <>
      {
        isAuthenticated && (
          <>
            {handleLayout()}
            {animation && <Bubble />}
          </>
        )
      }
    </>
  )
}

export default PrivateRoute;
