import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {PersonInterface} from '../../../../../../../domain/Customer/Person'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import KycForm from './KycForm'

type Props = {
  customer: CustomerInterface
  referential: ReferentielInterface
}

const KycBlock: FunctionComponent<Props> = ({customer, referential}) => {
  const { t } = useTranslation()

  return (
    <div className={`form-bloc form--bloc--kyc`}>
      <div className="form-bloc__title">{t('account.form.kyc.title')}</div>
      <div className="form-bloc__form flex-container">
        {customer.persons?.map((person: PersonInterface, index: number) => {
          return person.id &&
            <KycForm referential={referential} indexPerson={index} key={`kyc-form-${index}`}/>
        })}
      </div>
    </div>
  );
}

export default KycBlock
