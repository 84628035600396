import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Recurrence} from '../../../domain/PreSubscription/Recurrence'
import {InvestorInterface} from '../../../domain/PreSubscription/Investor'
import {SliceListInterface} from '../../../domain/Share/Slice/SliceList'
import {ActionListInterface} from "../../../domain/Recurrence/RecurrenceSessionAction";

interface recurrence {
  countTotal: number
  recurrence: Recurrence|null
  investors: {
    owner: InvestorInterface|null
    usufructuary: InvestorInterface|null
  }
  sliceToGiveUp: {
    redemptionWithdrawal: SliceListInterface|null
    agreement: SliceListInterface|null
  },
  refreshRecurrenceSession: string,
  actions: ActionListInterface | null
}

const initialState: recurrence = {
  countTotal: 0,
  recurrence: null,
  investors: {
    owner: null,
    usufructuary: null
  },
  sliceToGiveUp: {
    redemptionWithdrawal: null,
    agreement: null
  },
  refreshRecurrenceSession: '',
  actions: null
}

export const recurrenceSlice = createSlice({
  name: 'recurrence',
  initialState: initialState,
  reducers: {
    setCountTotal: (state, action: PayloadAction<number>) => {
      state.countTotal = action.payload
    },
    setInvestorOwner: (state, action: PayloadAction<InvestorInterface>) => {
      state.investors.owner = action.payload
    },
    setInvestorUsufructuary: (state, action: PayloadAction<InvestorInterface>) => {
      state.investors.usufructuary = action.payload
    },
    setSliceToGiveUpRedemptionWithdrawal: (state, action: PayloadAction<SliceListInterface>) => {
      state.sliceToGiveUp.redemptionWithdrawal = action.payload
    },
    setRefreshRecurrenceSession: (state, action: PayloadAction<string>) => {
      state.refreshRecurrenceSession = action.payload
    },
    setRecurrenceSessionActions: (state, action: PayloadAction<ActionListInterface | null>) => {
      state.actions = action.payload;
    }
  }
})

export const {
  setCountTotal,
  setInvestorOwner,
  setInvestorUsufructuary,
  setSliceToGiveUpRedemptionWithdrawal,
  setRefreshRecurrenceSession,
  setRecurrenceSessionActions
} = recurrenceSlice.actions

export default recurrenceSlice.reducer
