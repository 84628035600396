import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {
  setOpenAccountBeneficialOwnerForm,
  setOpenCustomerUploadForm,
  setOpenDocumentList, setOpenProspectUploadForm
} from '../../../../../store/component/event'
import {ProspectInterface} from '../../../../../../domain/Prospect/Prospect'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import ReferentielGateway from '../../../../../../gateway/Referentiel/ReferentielGateway'
import InitializeProspect
  from '../../../../../../useCase/Prospect/Initialization/InitializeProspect'
import {BeneficialOwner} from '../../../../../../domain/Prospect/BeneficialOwner'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'
import AddUseCase from '../../../../../../useCase/Prospect/Add/AddUseCase'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconExtend from '../../../../../../assets/images/icons/expend.svg'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {reloadProspect} from "../../../../../store/component/prospect";
import BeneficialOwnerDatalist from "../../../Datalist/BeneficialOwnerDatalist";
import DocumentGateway from "../../../../../../gateway/Prospect/Document/DocumentGateway";
import FooterBlock from "../../../Block/FooterBlock";

interface IFormInput {
  id: string|null
}

type Props = {
  prospect?: ProspectInterface
  isLectureMode?: boolean
}

const BeneficialOwnerForm: FunctionComponent<Props> = ({prospect, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { prospectType } = useParams()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false);
  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const beneficialOwnerArray = useAppSelector((state) => state.prospect.beneficialOwnerArray)
  const [beneficialOwnerList, setBeneficialOwnerList] = useState<BeneficialOwner[]|[]>(beneficialOwnerArray)
  const beneficialOwnerRef = useRef<string|null>(null)

  useEffect(() => {
    setBeneficialOwnerList(beneficialOwnerArray)
  }, [beneficialOwnerArray])

  useEffect(() => {
    return () => {
      dispatch(setOpenDocumentList({
        show: false,
        documentList: [],
        gateway: null,
        openCallback: null
      }))
    };
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    setSubmitted(true);
    reset(data)
    if (beneficialOwnerList.length == 0) {
      return
    }

    if (undefined === prospect) {
      prospect = new InitializeProspect().initializeProspect()
    }

    if (null == prospect.prospectType ) {
      prospect.prospectType = undefined !== prospectType ? prospectType : 'moral'
    }

    prospect.beneficialOwners = beneficialOwnerList

    if (null !== prospect.id) {
      new UpdateUseCase(new ProspectGateway()).execute(prospect).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'))
          dispatch(reloadProspect());
        } else {
          toastError(t('account.notify.update-error'));
        }
        setSubmitted(false);
      })
      .catch(error => {
        toastError(t('account.notify.update-error'));
      })
      .finally(() => {
        setSubmitted(false);
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  const uploadCallback = (beneficialOwnerId: string|null) => {
    if (prospect?.id) {
      dispatch(setOpenProspectUploadForm(( {
        show: true,
        documents: null,
        prospectId: prospect.id,
        type: 'BE',
        beneficialOwnerId: beneficialOwnerId
      })))
    }
  }

  const openCallback = (fileId: number) => {
    if (prospect?.id && beneficialOwnerRef.current) {
      new DocumentGateway(prospect.id).getBeneficialOwnerFile(prospect.id, beneficialOwnerRef.current, fileId).then(response => {
        const file = window.open('')
        if (null !== file && response !== null) {
          file.document.write(
            "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='"+response+"'></iframe></body>"
          )
        }
      })
    }
  }

  const viewFilesCallback = (beneficialOwnerId: string|null) => {
    if (prospect?.id && beneficialOwnerId) {
      beneficialOwnerRef.current = beneficialOwnerId
      new DocumentGateway(prospect?.id).getBeneficialOwnerFiles(prospect?.id, beneficialOwnerId).then((response) => {
        if (response && response.length > 0) {
          dispatch(setOpenDocumentList({
            show: true,
            documentList: response,
            gateway: null,
            openCallback: openCallback
          }))
        }
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.beneficial-beneficiaries.title')}</div>
        <div className="form-bloc__shortcut">
          <button type="button" className="button-reset u-txt-with-icon" onClick={() => dispatch(setOpenAccountBeneficialOwnerForm({show: true, beneficialOwner: null}))}>
            <img src={iconAdd} alt="" />
            {t('partner.form.beneficial-beneficiaries.add')}
          </button>
        </div>
        {beneficialOwnerList?.length > 0 && prospect?.id && <BeneficialOwnerDatalist beneficialOwnerList={beneficialOwnerList} uploadCallback={uploadCallback} viewFilesCallback={viewFilesCallback}/>}
      </div>
      {!isLectureMode && <FooterBlock disabled={submitted} onCancelUrl={t('url.prospect.dashboard')}/>}
    </form>
  )
}

export default BeneficialOwnerForm
