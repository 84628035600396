import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../../../../assets/styles/components/_tab.scss';
import { CustomerInterface } from '../../../../../domain/Customer/Customer';

type Props = {
  uuid: string|null,
  isLectureMode: boolean,
  customer?: CustomerInterface
}

const TabCustomerEditPhysicalPerson: FunctionComponent<Props> = ({uuid, isLectureMode, customer}) => {
  const {t} = useTranslation()
  return (
    <div className={`tab tab--prospect-add`}>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-general-information`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-general-information')}
      </NavLink>
        { customer?.persons && (( customer.persons[0] && customer.persons[0].legalCapacity) || (customer.persons[1] && customer.persons[1].legalCapacity)) ? (
          <NavLink
              to={`/${t('url.customer.edit-legal-representatives')}/${uuid}`}
              className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
          >
           {t('account.add.tab-legal-representatives')}
          </NavLink>
        ) : ''}
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-addresses`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-addresses')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-bank-details`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-bank-details')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-investment-information`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-investment-information')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-particularities`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-particularities')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-notary`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-notary')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-pledge`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-pledge')}
      </NavLink>
      <NavLink
        to={`/${t(`url.customer.${isLectureMode ? "read" : "edit"}-distribution`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-distribution')}
      </NavLink>
    </div>
  )
}

export default TabCustomerEditPhysicalPerson
