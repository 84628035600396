import CommissioningSessionGateway from "../../../gateway/Commissioning/CommissioningSessionGateway";
import {CommissioningSessionFilters} from "../../../domain/Commissioning/CommissioningSessionFilters";
export default class ListFilter {
  public commissioningGateway

  constructor(CommissioningGateway: CommissioningSessionGateway) {
    this.commissioningGateway = CommissioningGateway
  }

  async execute(
    sessionId: string
  ): Promise<CommissioningSessionFilters | null> {
    return await this.commissioningGateway.getFilters(sessionId).then(response => {
      return response
    })
  }
}
