import React, {FunctionComponent, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {usePrompt} from "../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../util/setReadOnlyFormElements";
import SessionSetting from "../Blocks/SessionSetting/SessionSetting";
import Footer from "../../Footer/Footer";
import SessionCriteriaToggle from "../Blocks/SessionCriteriaToggle/SessionCriteriaToggle";
import EditionAndDownload, { TEditionDownloadItem } from '../Blocks/EditionAndDownload'
import {useNavigate, useParams} from "react-router-dom";
import {toastError, toastSuccess} from "../../../util/Toast";
import {CommissioningSessionInterface} from "../../../../../domain/Commissioning/CommissioningList";
import {ISettingForm} from "../../../../../domain/Commissioning/setting";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import {useAppSelector} from "../../../../store/hook";
import CommissioningSessionGateway from "../../../../../gateway/Commissioning/CommissioningSessionGateway";
import {v4 as uuidV4} from "uuid";
import ActionsForm from "./ActionsForm";
import download from '../../../util/Download';
import downloadBlobFile from '../../../util/DownloadBlobFile';

type TProps = {
  commissioning?: CommissioningSessionInterface
  isLectureMode?: boolean
}

const Setting: FunctionComponent<TProps> = ({commissioning, isLectureMode}) => {
  const {t} = useTranslation()
  const { commissioningType, uuid } = useParams()
  const navigate = useNavigate()
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const {register, control, handleSubmit, reset, getValues, watch, setValue} = useForm<ISettingForm>({
    defaultValues: {
      session: {
        sessionNb: commissioning?.code || "",
        sessionStatut: referential?.commission?.status?.find(status => status.value === commissioning?.status )?.label || "",
        sessionPayment: commissioning?.paymentScheduledAt || "",
      },
      actions: {
        calcul: "",
        cancel: "",
        control: "",
        invalidate: "",
        validate: "",
        verify: ""
      },
      editions: {
        accounting: "",
        invoice: "",
        transfer: "",
        syntheses: ""
      },
      sessionCriteria: {
        products: commissioning?.criterias.products || [],
        partners: commissioning?.criterias.partners || [],
        regularization: commissioning?.regularisationBoolean,
        dateStart: commissioning?.period?.start || "",
        dateEnd: commissioning?.period?.end || "",
        multiCriteria: commissioning?.criteriaType === "multi" || false,
        criteria_movement: commissioning?.criterias?.transactions?.[0].code || "",
        periodicity: commissioning?.periodicity || ""
      }
    }
  });

  const watchCriteriaMovement: string|null|undefined = watch('sessionCriteria.criteria_movement')
  const watchMultiCriteria: boolean| null = watch('sessionCriteria.multiCriteria')
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const openDatalistFilterCommissioningSession = useAppSelector((state) => state.event.openDatalistFilterCommissioningSession)
  
  const editionArray: TEditionDownloadItem[] = [
    {
      type: 'accounting',
      title: 'commissioning.form.setting.actions-editions.accounting',
    },
    {
      type: 'invoice',
      title: 'commissioning.form.setting.actions-editions.invoice',
    },
    {
      type: 'transfer',
      title: 'commissioning.form.setting.actions-editions.transfer',
    },
    {
      type: 'syntheses',
      title: 'commissioning.form.setting.actions-editions.syntheses',
    }
  ]

  const handleClickDownload = (item: TEditionDownloadItem) => {
    const sessionId = uuid
    const type = item.type
    if (type === 'syntheses' && sessionId) {
      new CommissioningSessionGateway().getExport(sessionId, openDatalistFilterCommissioningSession.filters).then(response => {
        if (response) {
          download(t(`settings.extract.${type}`), response)
        }
      }).catch(response => {
        if (response.messageApi.includes(' status code 500') || !response.blob) {
          toastError(t('commissioning.notify.export-control-error'))
        }
        else
          toastError(t(response.blob))
      })
    } else if(sessionId) {
      new CommissioningSessionGateway().getExtractAsBlob(sessionId, type, openDatalistFilterCommissioningSession.filters).then(response => {
        if (response) {
          if (response) {
            downloadBlobFile(response, t(`settings.extract.${type}`))
          }
        }
      }).catch(response => {
        if (!response.message?.includes(' status code 500') && response.blob) {
          toastError(response.blob)
        }
        else
          toastError(t('commissioning.notify.export-control-error'))
      })
    }
  }

  const onSubmit: SubmitHandler<ISettingForm> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: ISettingForm) => {
    const type = commissioningType ? commissioningType.replace('_', '') : ''

    if(uuid) {
      new CommissioningSessionGateway()
        .updateCommissioningSession(data, type, uuid)
        .then(() => {
          setUuidRefresh(uuidV4())
          toastSuccess(t('commissioning.notify.update-success'))
        })
        .catch((e) => {
          if(e.data?.errors?.session?.[0]) {
            const errorParsed :any = Object.values(e.data.errors.session[0])
            toastError(t('commissioning.notify.update-error'), errorParsed[0])
          } else if(e.data?.errors?.criteria_movement?.errors?.criteria_movement) {
            toastError(t('commissioning.notify.update-error'), e.data.errors.criteria_movement.errors.criteria_movement)
          } else if(e.data?.errors?.start?.errors?.start) {
            toastError(t('commissioning.notify.update-error'), e.data?.errors?.start?.errors?.start)
          } else if(e.data?.errors?.end?.errors?.end) {
            toastError(t('commissioning.notify.update-error'), e.data?.errors?.end?.errors?.end)
          } else {
            toastError(t('commissioning.notify.update-error'))
          }
        })
    } else {
      new CommissioningSessionGateway()
        .postCommissioningSession(data, type)
        .then((res) => {
          toastSuccess(t('commissioning.notify.add-success'))
          navigate(`/${t('url.commissioning.edit-setting')}/${res.type}/${res.id}`)
        })
        .catch((e) => {
          if(e.data?.errors?.session?.[0]) {
            const errorParsed :any = Object.values(e.data.errors.session[0])
            toastError(t('commissioning.notify.add-error'), errorParsed[0])
          } else if(e.data?.errors?.criteria_movement?.errors?.criteria_movement) {
            toastError(t('commissioning.notify.add-error'), e.data.errors.criteria_movement.errors.criteria_movement)
          } else if(e.data?.errors?.start?.errors?.start) {
            toastError(t('commissioning.notify.add-error'), e.data?.errors?.start?.errors?.start)
          } else if(e.data?.errors?.end?.errors?.end) {
            toastError(t('commissioning.notify.add-error'), e.data?.errors?.end?.errors?.end)
          } else {
            toastError(t('commissioning.notify.add-error'))
          }
        })
    }
    reset(data)
  };

  const {isDirty} = useFormState({ control });

  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [isLectureMode]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}
          ref={measuredRef}
          className="flex-container"
    >
      <SessionSetting register={register}
                      control={control}
                      classes={"col-md-12"}
                      parentPath={"session."}
                      title={t('commissioning.form.setting.session.title')}
      />

      <SessionCriteriaToggle register={register}
                             control={control}
                             watch={watch}
                             getValues={getValues}
                             setValue={setValue}
                             classes={uuid ? "col-md-6" : "col-md-12"}
                             parentPath={"sessionCriteria."}
                             title={t('commissioning.form.setting.sessionCriteria.title')}
                             titleSecondary={t('commissioning.form.setting.sessionCriteria.titleSecondary')}
      />

      {uuid && <ActionsForm register={register}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        sessionId={uuid}
                        uuidRefresh={uuidRefresh}
                        commissioning={commissioning}
      />}

      {uuid &&  <EditionAndDownload title={t('commissioning.form.setting.actions-editions.title-edition')}
                          register={register}
                          control={control}
                          editionArray={editionArray}
                          sessionId={uuid}
                          parentPath={"editions."}
                          classes={"col-md-12"}
                          onClick={handleClickDownload}
      />}

      <Footer isLectureMode={isLectureMode}
              disabled={!watchMultiCriteria && watchCriteriaMovement === ""}
              onCancelUrl={t('url.commissioning.dashboard')}
      />
    </form>

  )
}

export default Setting