import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {
  setOpenDatalistFilterRecurrenceMandate
} from '../../../../../store/component/event'
import blockScrollBody from '../../../../util/BlockScroll'
import {
  ReferentielInterface,
} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {useForm, useFormState} from "react-hook-form";
import {usePrompt} from "../../../../util/Navigation";
import getClassForOverlay from "../../../../util/Sidebar";
import ProductDalalist from "../../../Datalist/Product/ProductDalalist";
import MultiSelectCustom from "../../../Elements/MultiSelect";
import SelectCustom from "../../../Elements/Select";
import UserDatalist from "../../../Datalist/UsersDatalist";
import {FilterRecurrenceMandateInterface} from "../../../../../../domain/Recurrence/RecurrenceMandateList";
import InputDate from "../../../Elements/InputDate";

const RecurrenceMandateDatalistFilter: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<boolean>(false)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterRecurrenceMandate = useAppSelector(
    (state) => state.event.openDatalistFilterRecurrenceMandate
  );

  const { register, control, handleSubmit, reset, setValue, getValues } = useForm<FilterRecurrenceMandateInterface>();

  useEffect(() => {
    blockScrollBody(openDatalistFilterRecurrenceMandate.show);
  }, [openDatalistFilterRecurrenceMandate]);

  useEffect(() => {
    setValue('product', openDatalistFilterRecurrenceMandate.filters.product)
    setValue('status', openDatalistFilterRecurrenceMandate.filters.status)
    setValue('periodicity', openDatalistFilterRecurrenceMandate.filters.periodicity)
    setValue('start', openDatalistFilterRecurrenceMandate.filters.start)
    setValue('end', openDatalistFilterRecurrenceMandate.filters.end)
    setValue('keyword', openDatalistFilterRecurrenceMandate.filters.keyword)
  }, [openDatalistFilterRecurrenceMandate.filters]);

  const onConfirm = (data: any) => {
    reset(data);
    dispatch(
      setOpenDatalistFilterRecurrenceMandate({
        show: false,
        count: openDatalistFilterRecurrenceMandate.count,
        filters: data,
      })
    );
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    reset(openDatalistFilterRecurrenceMandate.filters);
    setClearMultiSelectValues(true)
    dispatch(
      setOpenDatalistFilterRecurrenceMandate({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          status: [],
          periodicity: [],
          start: '',
          end: '',
          keyword: openDatalistFilterRecurrenceMandate.filters.keyword
        }
      })
    );
  }

  return (
    <>
      { referential &&
        <>
          <div
            className={`overlay ${getClassForOverlay(
              openDatalistFilterRecurrenceMandate.show
            )}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openDatalistFilterRecurrenceMandate.show ? "sidebar--active" : ""
            }`}
          >
            <form
              onSubmit={handleSubmit(onConfirm)}
              className="form-bloc form-bloc--partner-address-form"
            >
              <div className="sidebar__content">
                <div className="title">{t("common.filters")}</div>
                <div className="form-bloc__form flex-container">
                  <ProductDalalist classes="col-md-6"
                                   id="product"
                                   name="product"
                                   label={t('movement.filter.product')}
                                   control={control}
                                   defaultValue={getValues('product')}
                  />
                  <MultiSelectCustom
                    id="status"
                    name="status"
                    label={t('recurrence.filter.status')}
                    classes="col-md-6 form-control__input"
                    control={control}
                    options={referential?.recurrence.mandate_status || []}
                    placeholder={t('common.search-by', {keyword:t('filters.by-status')})}
                    customOnChange={ (options) => {setValue('status', options)}}
                    defaultValue={openDatalistFilterRecurrenceMandate.filters.status}
                    clearValues={clearMultiSelectValues}
                  />
                  <InputDate register={register}
                             control={control}
                             classes="col-md-6"
                             label={t('common.document.date_start')}
                             name={`start`}
                             id={'start'}
                  />
                  <InputDate register={register}
                             control={control}
                             label={t('common.document.date_end')}
                             classes="col-md-6"
                             name={`end`}
                             id={'end'}
                  />
                  <MultiSelectCustom
                    id="periodicity"
                    name="periodicity"
                    label={t('recurrence.filter.periodicity')}
                    classes="col-md-6 form-control__input"
                    control={control}
                    options={referential?.recurrence.session_periodicity || []}
                    placeholder={t('common.search-by', {keyword:t('filters.by-periodicity')})}
                    customOnChange={ (options) => {setValue('periodicity', options)}}
                    defaultValue={openDatalistFilterRecurrenceMandate.filters.periodicity}
                    clearValues={clearMultiSelectValues}
                  />
                  <footer className="sidebar__footer">
                      <button type="submit" className="button button--ink-2 u-mrm">
                        {t("common.save")}
                      </button>
                      <button
                          type="button"
                          className="button button--ink-2 button--ink-2--outline"
                          onClick={() => handleClose()}
                      >
                        {t("common.cancel")}
                      </button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </>
      }
    </>
  );
}

export default RecurrenceMandateDatalistFilter
