import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'
import DocumentForm from '../../../Document/Form'
import DocumentGateway from '../../../../../../../gateway/Prospect/Document/DocumentGateway'
import {useFormContext} from 'react-hook-form'
import {DocumentInterface, DocumentType} from '../../../../../../../domain/Document/DocumentInterface'
import {useAppSelector} from '../../../../../../store/hook'

type Props = {
  customer: CustomerInterface
  isLectureMode: boolean | undefined
  documentArray: DocumentType
  setDocumentArray: React.Dispatch<React.SetStateAction<DocumentType>>
}

const DocumentListBlock: FunctionComponent<Props> = ({
  customer,
  isLectureMode,
  documentArray,
  setDocumentArray,
}) => {
  const {t} = useTranslation()
  const {register, control, setValue} = useFormContext()
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)

  useEffect(() => {
    if (customer && customer.persons) {
      customer.persons.map((person) => {
        if (person.id && documentArray[person.id]) {
          documentArray[person.id].map((doc: DocumentInterface) => {
            setValue(`documents.${person.id}.${doc.type}.date`, doc.date)
            setValue(`documents.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
          })
        }
      })
    }
  }, [documentArray])

  useEffect(() => {
    if (customer && customer.id) {
      const documentGateway = new DocumentGateway(customer.id)

      documentGateway.getFiles().then((response) => {
        if (response) {
          setDocumentArray(response)
        }
      })
    }
  }, [openUploadForm])

  return (
    <>
      {customer?.persons?.map((person, index) => {
        if (customer && customer.id && person && person.id) {
          return (
            <div className="col-md-6" key={`document-form-${index}`}>
              <DocumentForm
                title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${person.lastname + ' ' + person.firstname}`}
                name={`documents.${person.id}`}
                uuid={person.id}
                documentGateway={new DocumentGateway(customer.id)}
                register={register}
                control={control}
                documentData={documentArray[person.id]}
                isLectureMode={isLectureMode}
              />
            </div>
          )
        }
      })}
    </>
  )
}

export default DocumentListBlock
