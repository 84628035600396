import {Presenter} from "../Presenter";
import {ProductCommissionSharesInterface} from "../../domain/Product/CommissionShares";

class ProductCommissionSharesPresenter extends Presenter {
  private useCase: {
    getProductCommissionShares: Promise<{ data: ProductCommissionSharesInterface[] | null, nb_element: number }>
  }

  constructor(getProductCommissionShares: any) {
    super({
      viewModel: {
        title: '',
        heading: [
          {name: 'product.form.commission.shares.year', sort: "year", labelApi: "year"},
          {name: 'product.form.commission.shares.month', sort: "month", labelApi: "month"},
          {name: 'product.form.commission.shares.number-of-parts', sort: "numberOfShares", labelApi: "numberOfShares"},
          {name: 'product.form.commission.shares.accumulation-of-shares', sort: "accumulationOfShares", labelApi: "accumulationOfShares"},
        ],
        data: [],
        filtersShortcut: [
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 10,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getProductCommissionShares,
    }
  }

  async load() {
    try {
      this._setCommissionShares(await this.useCase.getProductCommissionShares);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setCommissionShares(commissionShares: { data: ProductCommissionSharesInterface[] | null, nb_element: number  }) {
    this.update({
      data: commissionShares.data,
      pagination: {
        'itemsPerPage': 10,
        'numberOfItems': commissionShares.nb_element,
      }
    });
  }
}

export default ProductCommissionSharesPresenter
