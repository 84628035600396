import React, {useEffect} from "react";
import "../../../../assets/styles/components/_process-bar.scss";

export interface IProcessBar {
  completed?: number,
  processColor?: string|undefined,
  bgColor?: string|undefined
  styles?: React.CSSProperties|undefined
}

const ProcessBar: React.FC<IProcessBar> = ({
  completed= 0,
  processColor= '',
  bgColor = '',
  styles
}) => {
  const completedStyles = {
    width: `${completed}%`,
    backgroundColor: processColor,
  }

  return (
    <div className="process-bar-container" style={{ backgroundColor: bgColor, ...styles }}>
      <div className="process-bar" style={completedStyles}></div>
    </div>
  )
}

export default ProcessBar;
