import React, {EventHandler, useEffect, useState} from "react";
import CommissionCheckList, {ICommissionCheckListDetail} from "../../Card/Comissioning/CommissionCheckList";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import {setOpenCommissionControlListForm, setOpenPartnerAddAddressEvent} from "../../../../store/component/event";
import getClassForOverlay from "../../../util/Sidebar";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import blockScrollBody from "../../../util/BlockScroll";
import ExportLink from "../../../../../assets/images/icons/export_link.svg";
import {FormProvider, SubmitHandler, useFieldArray, useForm, useWatch} from "react-hook-form";
import lodash from "lodash";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import CommissioningSessionGateway from "../../../../../gateway/Commissioning/CommissioningSessionGateway";
import {setCommissionControlLists, setRefreshCommission} from "../../../../store/component/commission";
import download from "../../../util/Download";
import {COMMISSION_CHECKLIST_ALLOW, COMMISSION_CHECKLIST_MAPPING_EXPORTS} from "../../../../../fixtures/Commissioning";
import {toastError, toastSuccess} from "../../../util/Toast";
import { v4 as uuidV4 } from 'uuid'

interface IFormInput {
  checkLists: ICommissionCheckListDetail[]
}

export interface ICommissionControlListSidebar {
  referential: ReferentielInterface
  isLectureMode: boolean
}

const CommissionControlList: React.FC<ICommissionControlListSidebar> = ({ referential, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const methods = useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenExport, setIsOpenExport] = useState<boolean>(false);

  const openForm = useAppSelector((state) => state.event.openCommissionControlListForm)
  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const { fields } = useFieldArray({
    control,
    name: "checkLists",
  });
  const checkListForm = useWatch({
    name: "checkLists",
    control
  });

  useEffect(() => {
    blockScrollBody(openForm.show)

    if (openForm.show) {
      setValue('checkLists', lodash.cloneDeep<ICommissionCheckListDetail[]>(openForm.checkLists || []));
    }

    return () => {
      setValue('checkLists', [])
    }
  }, [openForm])

  const handleClose = () => {
    dispatch(setOpenCommissionControlListForm({show: false, checkLists: null, commissioning: null }))
  }

  const handleExport = (e: React.MouseEvent, formName: string) => {
    e.preventDefault();

    if (!openForm?.commissioning?.id) {
      return;
    }

    handleExportFile(formName)
  }

  const handleExportFile = (formName: string) => {
    if (!openForm?.commissioning?.id) {
      toastError(t('commissioning.notify.export-control-error'))
      return;
    }

    if (formName in COMMISSION_CHECKLIST_MAPPING_EXPORTS) {
      const config = COMMISSION_CHECKLIST_MAPPING_EXPORTS[`${formName}`];
      new CommissioningSessionGateway().getExportCommissioningSessionControl(config.type, openForm?.commissioning?.id)
        .then((response) => {
          if (response) {
            download(config.fileName, response)
            return;
          }

          toastError(t('commissioning.notify.export-control-error'))
        })
        .catch(e => {
            toastError(t('commissioning.notify.export-control-error'))
        });
    }
  }

  const toggleCheck = (index: number, isChecked: boolean) => {
    const checkList = getValues(`checkLists.${index}`);

    if (checkList.children.length > 0) {
      checkList.children.map((item, childIndex) => {
        setValue(`checkLists.${index}.children.${childIndex}.status`, isChecked)
      })
    }

    setValue(`checkLists.${index}.status`, isChecked)
  }

  const toggleCheckChild = (index: number, childIndex: number, isChecked: boolean) => {
    setValue(`checkLists.${index}.children.${childIndex}.status`, isChecked)
    const checklists = getValues(`checkLists.${index}`);

    if (checklists && checklists.children) {
      const checkListChecked = checklists.children.filter((item) => item.status)

      if (checkListChecked.length === checklists.children.length) {
        setValue(`checkLists.${index}.status`, isChecked)
        return;
      }
    }

    setValue(`checkLists.${index}.status`, false)
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }

  const onConfirm = (data: IFormInput) => {
    setIsLoading(true);
    if (!openForm?.commissioning?.id) {
      toastError(t('commissioning.notify.update-control-error'))
      setIsLoading(false);
      return;
    }

    const controls: string[] = [];

    data.checkLists[0].children.map(item => {
      if (item.status && COMMISSION_CHECKLIST_ALLOW.includes(item.formName)) {
        controls.push(item.formName);
      }
    })

    new CommissioningSessionGateway().updateCommissioningSessionControl(controls, openForm.commissioning.id)
      .then((response) => {
        if (response?.controls) {
          toastSuccess(t('commissioning.notify.update-control-success'))
          dispatch(setCommissionControlLists(data.checkLists))
          dispatch(setOpenCommissionControlListForm({ show: false, checkLists: null, commissioning: null }))
          dispatch(setRefreshCommission(uuidV4()))
          return;
        }

        toastError(t('commissioning.notify.update-control-error'))
      })
      .catch((error) => {
        toastError(t('commissioning.notify.update-control-error'))
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openForm.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openForm.show ? 'sidebar--active' : ''}`}>
        <FormProvider {...methods} >
          <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
            <div className="sidebar__content">
              <div className="title">
                <CommissionCheckList
                  title={t('commissioning.block.control-list')}
                  checkLists={checkListForm}
                  hideIcon={true}
                  cssClass={'__sidebar'}
                />
              </div>
              <div className="form-bloc__form flex-container justify-center">
                <div className="col-md-11 control-list flex-col">
                  {
                    fields.map((field: ICommissionCheckListDetail, index) => (
                    <div key={index}>
                      <div className="flex control-list__item" key={index}>
                        <div className="control-list__title">
                          <p>{ field.title }</p>
                        </div>
                        <div className="control-list__checkbox">
                          <input
                            disabled={isLectureMode}
                            type="checkbox"
                            {...register(`checkLists.${index}.status`)}
                            onChange={(event) => toggleCheck(index, event.target.checked)}
                          />
                        </div>
                        <div className="control-list__export">
                          { field.children.length === 0 &&
                            <button onClick={(event) => handleExport(event, field.formName)} className="button-reset">
                              <img src={ExportLink}/>
                              {t('commissioning.block.link-export')}
                            </button>
                          }
                        </div>
                      </div>
                        {
                          field.children.length > 0 &&
                          field.children.map((child, childIndex) => (
                            <div className="flex control-list__item" key={`child_${childIndex}`}>
                              <div className="control-list__title__child"><p>{ child.title }</p></div>
                              <div className="control-list__checkbox">
                                <input
                                  type="checkbox"
                                  disabled={isLectureMode}
                                  {...register(`checkLists.${index}.children.${childIndex}.status`)}
                                  onChange={(event) => toggleCheckChild(index, childIndex, event.target.checked)}
                                /></div>
                              <div className="control-list__export">
                                <button onClick={(event) => handleExport(event, child.formName)} className="button-reset">
                                  <img src={ExportLink}/>
                                  {t('commissioning.block.link-export')}
                                </button>
                              </div>
                            </div>
                        ))}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            {
              !isLectureMode && (
                <footer className="sidebar__footer">
                  <button disabled={isLoading} type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
                  <button disabled={isLoading} type="button" className="button button--ink-2 button--ink-2--outline"
                          onClick={() => handleClose()}>{t('common.cancel')}</button>
                </footer>
              )
            }
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default CommissionControlList;
