import {Presenter} from '../../Presenter'
import { RecurrenceSessionListInterface } from '../../../domain/Recurrence/RecurrenceSessionList';

class RecurrenceSessionListPresenter extends Presenter {

  private useCase: {
    getMandates: { data: RecurrenceSessionListInterface[] | null, nb_element: number }
  }

  constructor(getMandates: any) {
    super({
      viewModel: {
        title: 'recurrence.session.search',
        heading: [
          {name: 'recurrence.session.table.heading.action'},
          {name: 'recurrence.session.table.heading.product', sort: "product"},
          {name: 'recurrence.session.table.heading.periodicity', sort: "periodicity"},
          {name: 'recurrence.session.table.heading.session-number', sort: "reference"},
          {name: 'recurrence.session.table.heading.period', sort: "period"},
          {name: 'recurrence.session.table.heading.status', sort: "status"},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keyword', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getMandates,
    }
  }

  async load() {
    try {
      this._setRecurrences(await this.useCase.getMandates);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setRecurrences(recurrences: { data: RecurrenceSessionListInterface[] | null, nb_element: number }) {
    this.update({
      data: recurrences.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': recurrences.nb_element,
      }
    });
  }
}

export default RecurrenceSessionListPresenter
