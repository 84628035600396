import {CKEditorConfig} from "ckeditor4-react";

export default class CKEditorUtil {
  public static getConfig(): CKEditorConfig {
    return {
      toolbar: [
        ['Styles', 'Format', 'Font', 'FontSize'],
        { name: 'basicstyles', items: [ 'Underline', 'Italic', 'Bold' ] },
        ['NumberedList','BulletedList'],
        ['Undo', 'Redo']
      ],
      removePlugins: 'image',
      removeButtons: 'Subscript,Superscript',
      versionCheck: false,
    }
  }
}
