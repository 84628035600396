interface ListRequestInterface {
  id: string,
  currentPage: number
  itemPerPage: number
}

class ListRequest implements ListRequestInterface{
  public id: string
  public currentPage: number
  public itemPerPage: number

  constructor(
    id: string,
    currentPage: number,
    itemPerPage: number,
  ) {
    this.id = id
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
