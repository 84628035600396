import React, {FunctionComponent, useMemo} from 'react';
import SelectCustom from "../Elements/Select";
import CityAndPostcode from "../Datalist/CityAndPostcode/CityAndPostcode";
import CountryDatalist from "../Datalist/Country/CountryDatalist";
import CheckboxToggle from "../Elements/CheckboxToggle";
import InputDate from "../Elements/InputDate";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../store/hook";
import {Country} from "../../../../domain/Referentiel/Country/Country";
import {City} from "../../../../domain/Referentiel/City/City";
import {FieldErrors} from "react-hook-form";
import {CUSTOMER_ADDRESS_STATUS} from "../../../../fixtures/Customer";
import iconEdit from "../../../../assets/images/icons/datalist-edit.svg"
import {GENERAL_ADDRESS_STATUS_INACTIVE, GeneralAddressInterface} from "../../../../domain/Address/GeneralAddress";
import {setOpenAccountAddressFormEvent} from "../../../store/component/event";
import {AddressInterface} from "../../../../domain/Address/Address";
import {editCurrentFiscalAddress} from "../../../store/component/prospect";

type Props = {
  typeClient: string | null
  register: any
  control: any
  getValues: any
  setValue: any
  watchPostCode: string | null
  watchCity: City | null
  watchCountry: Country | null
  watchActive?: string | null
  parentPath: string
  typeAddress: "postal" | "fiscal"
  isLectureMode?: boolean
  isInSidebar?: boolean
  errors?: FieldErrors,
  currentAddress?: AddressInterface|null
  isCheckboxStatus?: boolean
}

const AddressBlock: FunctionComponent<Props> = ({
                                                  register,
                                                  control,
                                                  isLectureMode,
                                                  getValues,
                                                  setValue,
                                                  typeClient,
                                                  watchPostCode,
                                                  watchCity,
                                                  watchCountry,
                                                  watchActive,
                                                  parentPath,
                                                  typeAddress,
                                                  isInSidebar,
                                                  errors,
                                                  isCheckboxStatus,
                                                  currentAddress
                                                }) => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const referential = useAppSelector(({referential}) => referential.referential)

  const statusAddress = useMemo(() => {
    return !!(watchActive && watchActive === CUSTOMER_ADDRESS_STATUS.ACTIVE);
  }, [watchActive])
  const handleChangeAddressStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.checked) {
      setValue(`${parentPath}.status`, CUSTOMER_ADDRESS_STATUS.ACTIVE)
      return;
    }

    setValue(`${parentPath}.status`, CUSTOMER_ADDRESS_STATUS.INACTIVE)
  }

  const openAddAddressForm = (typeAddress = "postal") => {
    dispatch(setOpenAccountAddressFormEvent({
      show: true,
      typeAddress: typeAddress,
      defaultCountry: currentAddress?.country,
      defaultUserInfo: {
        firstname: currentAddress?.firstname || null,
        lastname: currentAddress?.lastname || null,
        title: currentAddress?.title || null,
        type: typeClient,
        socialReason: currentAddress?.socialReason || null
      },
      defaultAddress: currentAddress?.address || null,
      defaultAddress2: currentAddress?.address2 || null,
      defaultAddress3: currentAddress?.address3 || null,
      defaultPostcode: currentAddress?.postcode || null,
      defaultCity: currentAddress?.city || null
    }))
  }

  return (
    <div className={`form-bloc`}>
      <div className="form-bloc__form flex-container">
        {!isInSidebar && typeAddress === "postal" && <>
          <div className="col-md-6">
            <div className="form-control form-control--center">
              <label htmlFor="sameFiscalAddress"
                     className="form-control__label">
                {t('account.form.address.addrFiscalitySame')}
              </label>
              <div className="checkbox-toggle__wrapper">
                <input type="checkbox"
                       id="sameFiscalAddress"
                       className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                       {...register('sameFiscalAddress')}
                />
                <label className="checkbox-toggle__button" htmlFor="sameFiscalAddress"/>
              </div>
            </div>
          </div>
          <div className="col-md-6 flex-container justify-end">
            <button type="button" className="button-reset"
                    onClick={() => openAddAddressForm()}
            >
              <img src={iconEdit} alt=""/>
            </button>
          </div>
        </>}
        {!isInSidebar && isCheckboxStatus && referential &&
          <>
            <div className="col-md-6">
              <div className="form-control form-control--center">
                <span className="form-control__label">{t('account.form.address.active')}</span>
                <div className="checkbox-toggle__wrapper">
                  <input type="checkbox"
                         name={`${parentPath}.status`}
                         checked={statusAddress}
                         onChange={(e) => handleChangeAddressStatus(e)}
                         id={`${parentPath}.status`}
                         className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                  />
                  <label className="checkbox-toggle__button" htmlFor={`${parentPath}.status`}/>
                </div>
              </div>
            </div>
            {typeAddress === 'fiscal' && <div className="col-md-6 flex-container justify-end">
              <button type="button" className="button-reset"
                      onClick={() => openAddAddressForm('fiscal')}
              >
                <img src={iconEdit} alt=""/>
              </button>
            </div>}
          </>
        }

        {referential && typeClient !== "moral" &&
            <SelectCustom classes={`col-md-6 ${isInSidebar ? "mandatory-field" : ""}`}
                          id={`${parentPath}.address.title`}
                          name={`${parentPath}.address.title`}
                          options={referential.global.title_physic}
                          register={register}
                          label={t('account.form.address.civility')}
                          readOnly={!isInSidebar}
                          noChoiceOption
            />
        }
        {!isCheckboxStatus && referential &&
          <SelectCustom classes={`${typeClient !== "moral" ? "col-md-6" : "col-md-12"}`}
            id={`${parentPath}.status`}
            name={`${parentPath}.status`}
            options={referential.prospect?.address_status || []}
            register={register}
            label={t('account.form.address.status')}
            readOnly={!isInSidebar}
            noChoiceOption
          />
        }
        {typeClient === "moral" && <div className="col-md-12">
            <div className="form-control">
                <label htmlFor="socialReason"
                       className={`form-control__label ${isInSidebar ? "mandatory-field" : ""}`}>{t('account.form.address.socialReason')}</label>
                <div className="form-control__input">
                    <input type="text"
                           id="socialReason"
                           className={!getValues(`${parentPath}.address.socialReason`) ? 'field-in-error' : ''}
                           readOnly={!isInSidebar}
                           {...register(`${parentPath}.address.socialReason`, {required: t('error.required-field')})}
                    />
                  {errors?.sideBarAddress?.address?.socialReason?.message &&
                      <p className="input-error-msg">{errors?.sideBarAddress?.address?.socialReason?.message}</p>}
                </div>
            </div>
        </div>}
        <div className="col-md-6">
          <div className="form-control">
            <label htmlFor="lastname"
                   className={`form-control__label ${isInSidebar ? "mandatory-field" : ""}`}>{t('account.form.address.lastname')}</label>
            <div className="form-control__input">
              <input type="text"
                     id="lastname"
                     className={!getValues(`${parentPath}.address.lastname`) ? 'field-in-error' : ''}
                     readOnly={!isInSidebar}
                     {...register(`${parentPath}.address.lastname`, {required: t('error.required-field')})}
              />
              {errors?.sideBarAddress?.address?.lastname?.message &&
                  <p className="input-error-msg">{errors?.sideBarAddress?.address?.lastname?.message}</p>}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-control">
            <label htmlFor="firstname"
                   className={`form-control__label ${isInSidebar ? "mandatory-field" : ""}`}>{t('account.form.address.firstname')}</label>
            <div className="form-control__input">
              <input type="text"
                     id={`${parentPath}.address.firstname`}
                     name={`${parentPath}.address.firstname`}
                     className={!getValues(`${parentPath}.address.firstname`) ? 'field-in-error' : ''}
                     readOnly={!isInSidebar}
                     {...register(`${parentPath}.address.firstname`, {required: t('error.required-field')})}
              />
              {errors?.sideBarAddress?.address?.firstname?.message &&
                  <p className="input-error-msg">{errors?.sideBarAddress?.address?.firstname?.message}</p>}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="address1"
                   className={`form-control__label ${isInSidebar ? "mandatory-field" : ""}`}>{t('account.form.address.address1')}</label>
            <div className="form-control__input">
              <input type="text"
                     id="address1"
                     className={!getValues(`${parentPath}.address.address`) ? 'field-in-error' : ''}
                     readOnly={!isInSidebar}
                     {...register(`${parentPath}.address.address`, {required: t('error.required-field')})}
              />
              {errors?.sideBarAddress?.address?.address?.message &&
                  <p className="input-error-msg">{errors?.sideBarAddress?.address?.address?.message}</p>}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="address2" className="form-control__label">{t('account.form.address.address2')}</label>
            <div className="form-control__input">
              <input type="text"
                     id="address2"
                     readOnly={!isInSidebar}
                     {...register(`${parentPath}.address.address2`)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="address3"
                   className="form-control__label">
              {t('account.form.address.address3')}
            </label>
            <div className="form-control__input">
              <input type="text"
                     id="address3"
                     {...register(`${parentPath}.address.address3`)}
                     readOnly={!isInSidebar}
              />
            </div>
          </div>
        </div>
        <CityAndPostcode control={control}
                         nameCity={`${parentPath}.address.city`}
                         namePostcode={`${parentPath}.address.postcode`}
                         country={getValues(`${parentPath}.address.country`)}
                         register={register}
                         setValue={setValue}
                         getValues={getValues}
                         mode="col"
                         classes="col-md-12 flex flex-wrap u-px0 u-mb0"
                         postCodeMandatory={true}
                         postCodeIsOnError={!watchPostCode}
                         cityMandatory={true}
                         cityIsOnError={!watchCity}
                         disabled={!isInSidebar}
                         errors={errors}
        />
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="country"
                   className={`form-control__label ${isInSidebar ? "mandatory-field" : ""}`}>{t('account.form.address.country')}</label>
            <div className="form-control__input">
              <CountryDatalist control={control}
                               isOnError={!watchCountry}
                               name={`${parentPath}.address.country`}
                               defaultValue={getValues(`${parentPath}.address.country`)}
                               disabled={!isInSidebar}
                               errors={errors}
              />
            </div>
          </div>
        </div>
        {!isInSidebar && <>
            <CheckboxToggle id={`${parentPath}.address.npai`}
                            name={`${parentPath}.address.npai`}
                            register={register}
                            label={t('account.form.address.npai')}
                            classes="col-md-12"
            />
            <div className="col-md-6">
                <div className="form-control">
                    <label htmlFor="npaiAt"
                           className="form-control__label">{t('account.form.address.npaiAt')}</label>
                    <div className="form-control__input">
                        <InputDate
                            id="npaiAt"
                            name={`${parentPath}.address.npaiAt`}
                            register={register}
                            control={control}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-control">
                    <label htmlFor="updatedAt"
                           className="form-control__label">{t('account.form.address.updatedAt')}</label>
                    <div className="form-control__input">
                        <InputDate
                            id="updatedAt"
                            name={`${parentPath}.address.updatedAt`}
                            register={register}
                            control={control}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
        </>
        }
      </div>
    </div>
  )
    ;
}
export default AddressBlock;
