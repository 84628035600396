import { FunctionComponent, useEffect } from 'react';
import {useTranslation} from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook';

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import Tab from '../../../component/Tab/Movement/Tab'
import MovementDatalist from '../../../component/Datalist/MovementDatalist'

import '../../../../../assets/styles/page/_movement.scss'
import {useNavigate} from "react-router-dom";
import MovementDatalistFilter from "../../../component/Sidebar/Movement/MovementDatalistFilter";
import { setOpenDatalistFilterMovement } from '../../../../store/component/event';
import Kpi from "../../../component/Kpi/Kpi";
import MovementGateway from "../../../../../gateway/Movement/MovementGateway";
import envVariable from '../../../util/envVariable';

const MovementDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction)

  if(!transactionRules?.visible) navigate(`/`)

  useEffect(() => {
    dispatch(setOpenDatalistFilterMovement( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        investor_id: '',
        name: '',
        keywords: ''
      }
    }))
  }, [dispatch])

  return (
    <>
      <MovementDatalistFilter />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        { 
          <HeaderPage title={t('movement.title')}
                    addLink={transactionRules?.actions?.create && !envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT') ? `/${t('url.movements.choice-type')}` : undefined}
          />
}
        <Tab />
        <div className="partner-keys-figures">
          <div className="movement-keys-figures__title u-mys">{t('common.key-figures')}</div>
          <Kpi getKpi={new MovementGateway().getKpi} />
        </div>
       {transactionRules?.actions?.list && <MovementDatalist/>}
      </main>
    </>
  )
}

export default MovementDashboard
