import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ActionListInterface} from '../../../domain/Distribution';

interface DistributionInterface {
  countTotal: number
  refreshSession: string,
  actions: ActionListInterface | null
}

const initialState: DistributionInterface = {
  countTotal: 0,
  refreshSession: '',
  actions: null
}

export const distributionSlice = createSlice({
  name: 'distribution',
  initialState: initialState,
  reducers: {
    setCountTotal: (state, action: PayloadAction<number>) => {
      state.countTotal = action.payload
    },
    setRefreshSession: (state, action: PayloadAction<string>) => {
      state.refreshSession = action.payload
    },
    setSessionActions: (state, action: PayloadAction<ActionListInterface | null>) => {
      state.actions = action.payload;
    }
  }
})

export const {
  setCountTotal,
  setRefreshSession,
  setSessionActions
} = distributionSlice.actions

export default distributionSlice.reducer
