import {Presenter} from '../../Presenter'
import {RecurrenceSessionMandateInterface} from '../../../domain/Recurrence/RecurrenceSessionMandateInterface'
import React from 'react'

class RecurrenceSessionMandateListPresenter extends Presenter {

  private useCase: {
    getSessionMandates: { data: RecurrenceSessionMandateInterface[] | null, state: string, numberOfItems: number, itemsPerPage: number }
  }

  constructor(getSessionMandates: any) {
    super({
      viewModel: {
        title: 'recurrence.session.search',
        heading: [
          {name: 'recurrence.session-mandate.table.heading.action', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.createdAt', sort: 'created_at'},
          {name: 'recurrence.session-mandate.table.heading.product', sort: 'mandate.product_id'},
          {name: 'recurrence.session-mandate.table.heading.code-partner', sort: 'partner.partner_code'},
          {name: 'recurrence.session-mandate.table.heading.name-partner', sort: 'partner.social_reason'},
          {name: 'recurrence.session-mandate.table.heading.investor-code', sort: 'investor.account_code'},
          {name: 'recurrence.session-mandate.table.heading.payment-status', sort: 'sm.payment_status'},
          {name: 'recurrence.session-mandate.table.heading.nb-part', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.invest-price', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.invest-name', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.slice-start', sort: 'LOWER(sm.part_range)'},
          {name: 'recurrence.session-mandate.table.heading.slice-end', sort: 'UPPER(sm.part_range)'},
          {name: 'recurrence.session-mandate.table.heading.rum-code', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.rum-state', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.payment-reference', sort: ''},
          {name: 'recurrence.session-mandate.table.heading.payment-reject-reason', sort: ''},
        ],
        data: [],
        filtersShortcut: [
          // {'keyword': 'filters.by-keyword-or-id', 'field': 'keyword', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getSessionMandates,
    }
  }

  async load() {
    try {
      const response = await this.useCase.getSessionMandates
      this._setSessionMandates(response);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSessionMandates(sessionMandates: { data: RecurrenceSessionMandateInterface[] | null, state: string, numberOfItems: number, itemsPerPage: number }) {

    this.update({
      data: {
        sessionMandates: sessionMandates.data,
        state: sessionMandates.state,
      },
      pagination: {
        'itemsPerPage': sessionMandates.itemsPerPage,
        'numberOfItems': sessionMandates.numberOfItems,
      }
    });
  }
}

export default RecurrenceSessionMandateListPresenter
