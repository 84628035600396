import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {v4 as uuidV4} from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import '../../../../../../assets/styles/components/_multi-product.scss'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {setOpenChoiceProductChildEvent} from '../../../../../store/component/event'
import {addMultipleProductChild, removeProductChild} from '../../../../../store/component/product'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import {ProductChildInterface} from '../../../../../../domain/Product/ProductChild'
import InputView from '../../../Elements/InputView'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconEdit from '../../../../../../assets/images/icons/datalist-edit.svg'
import removeIcon from '../../../../../../assets/images/icons/remove.svg'
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase'
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import {ProductType} from '../../../../../../domain/Product/ProductType'
import {toastError, toastSuccess} from "../../../../util/Toast";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import FooterBlock from "../../../Block/FooterBlock";

type Props = {
  product: ProductInterface
  isLectureMode?: boolean
}

const MultiProductBlock: FunctionComponent<Props> = ({product, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { productType } = useParams()

  const productChildArray = useAppSelector((state) => state.product.productChildArray)
  const [productChildList, setProductChildList] = useState<ProductChildInterface[]>(productChildArray)

  useEffect(() => {
    setProductChildList(productChildArray)
  }, [productChildArray])

  useEffect(() => {
    if(product.childs) {
      dispatch(addMultipleProductChild(product.childs))
    }
  }, [])

  function onSubmit(event: { preventDefault: () => void }) {
    event.preventDefault()

    if (product.productType === null) {
      product.productType = new ProductType(
        null,
        null,
        null,
        null,
        undefined !== productType ? productType : 'other',
        null,
        null,
        true
      )
    }

    product.childs = productChildList.map(productChild => {
      return productChild.id
    })

    if(product.generalInformation) {
      product.generalInformation.multiproduct = true
    }

    if (null !== product.id) {
      new UpdateUseCase(new ProductGateway()).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
        } else {
          toastError(t('product.notify.update-error'));
        }
      })
    }
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <>
      <form onSubmit={onSubmit} ref={measuredRef}>
        <div className={`form-bloc`}>
          <div className="form-bloc__title">{t('product.form.multi-product.title')}</div>
          {!isLectureMode && <div className="form-bloc__shortcut">
            <button type="button" className="button-reset u-txt-with-icon"
                    onClick={() => dispatch(setOpenChoiceProductChildEvent({show: true}))}>
              <img src={iconAdd} alt=""/>
              {t('product.form.multi-product.add')}
            </button>
          </div>}
          {productChildList.length > 0 &&
            productChildList.map(productChild => {
              return <div className="form-bloc__form flex-container" key={uuidV4()}>
                <div className="col-md-12">
                  <div className="form-resume">
                    <InputView classes={'large-input'} label={t('')} defaultValue={productChild.label} readOnly={true} />
                    <div className="form-resume__actions">
                      <NavLink to={`/${t('url.product.edit')}/${productChild.id}`} className="form-resume__expend" target="_blank">
                        <img src={iconEdit} alt="" />
                      </NavLink>
                      <button type="button" className="button-reset" onClick={() => !isLectureMode && dispatch(removeProductChild(productChild.id))} disabled={isLectureMode}>
                        <img src={removeIcon} alt="" className="remove-icon" style={{maxWidth: '25px'}}/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            })
          }
        </div>
        {!isLectureMode && <FooterBlock disabled={false} onCancelUrl={t('url.product.dashboard')}/>}
      </form>
    </>
  )
}

export default MultiProductBlock
