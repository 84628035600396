import {ReferentielItemInterface} from '../../../domain/Referentiel/ReferentielItemInterface'

function getLabelByValue(value: string, referentialItems: ReferentielItemInterface[]): string | undefined {
  const status = referentialItems.find(item => item.value === value);
  return status ? status.label : value;
}

export {
  getLabelByValue
};
