import { FilterSessionInvestorInterface } from "../../../../domain/Distribution/SessionInvestorList"

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterSessionInvestorInterface|null
  sessionId: string
}

class ListRequest implements ListRequestInterface{
  constructor(
    public currentPage: number,
    public itemPerPage: number,
    public filter: FilterSessionInvestorInterface|null,
    public sessionId: string,
  ) {
    this.sessionId = sessionId
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
