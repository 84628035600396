import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import TableHead from "../../Table/TableHead";
import {ProductCommissionSharesInterface} from "../../../../../domain/Product/CommissionShares";
import {v4 as uuidV4} from "uuid";
import ProductCommissionSharesPresenter from "../../../../../presenter/Product/ProductCommissionSharesPresenter";
import Pagination from "../../Pagination/Pagination";
import '../../../../../assets/styles/components/_datalist.scss'
import InputDate from "../../Elements/InputDate";
import {ProductInterface} from "../../../../../domain/Product/Product";
import ProductGateway from "../../../../../gateway/Product/ProductGateway";
import { ListRequest } from "../../../../../useCase/ProductShares/List/ListRequest";
import ListUseCase from "../../../../../useCase/ProductShares/List/ListUserCase";
import {useAppSelector} from "../../../../store/hook";
import {FieldErrors} from "react-hook-form";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

export interface ICommissionSharesDatalist {
  product?: ProductInterface,
  register: any,
  control: any,
  errors: FieldErrors,
  isLectureMode?: boolean
}

interface IFormInput {
  initialShares?: string,
  initialSharesFromDate?: string
}

const DEFAULT_ITEMS_PER_PAGE = 10

const CommissionSharesDatalist: React.FC<ICommissionSharesDatalist> = ({
  product,
  register,
  control,
  errors,
  isLectureMode
}) => {
  const {t} = useTranslation()
  const [editNumberOfShares, setEditNumberOfShares] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const reloadProduct = useAppSelector((state) => state.product.reloadProduct)

  const controller = new AbortController()

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setEditNumberOfShares(!!product?.shares?.initialSharesFromDate)
  }, [product])

  useEffect(() => {
    if (!product?.id) {
      return;
    }

    const listRequest = new ListRequest(product.id, currentPage, DEFAULT_ITEMS_PER_PAGE)
    const reports = new ListUseCase(new ProductGateway()).execute(
      listRequest,
      controller.signal,
      sortOrder.sortLabel ?? '', sortOrder.sortOrder
    ).then(response => {
      return response
    });

    const presenter = new ProductCommissionSharesPresenter(reports);

    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
    })
  }, [currentPage, sortOrder, reloadProduct])

  return (<>
    {(viewModel !== null &&
      <>
        <div className={`datalist`}>
          <div className="datalist__header flex gap-2">
            <div className="form-control">
              <label htmlFor="lastname"
                     className={`form-control__label mandatory-field`}>{t('product.form.commission.shares.initial-accrual')}</label>
              <div className="form-control__input">
                <input type="number"
                       id="shares.initialShares"
                       className={errors?.shares?.initialShares?.message ? 'field-in-error' : ''}
                       readOnly={editNumberOfShares || isLectureMode}
                       {...register('shares.initialShares', { required: t('error.required-field').toString() || '' })}
                />
                {errors?.shares?.initialShares?.message &&
                  <p className="input-error-msg">{errors?.shares?.initialShares?.message}</p>}
              </div>
            </div>
            <InputDate
              classes="col-md-3 mandatory-field"
              id="shares.initialSharesFromDate"
              name="shares.initialSharesFromDate"
              label={t('product.form.commission.shares.from-date')}
              register={register}
              control={control}
              readOnly={editNumberOfShares || isLectureMode}
              required={true}
              errorMessage={t('error.required-field')}
              errors={errors}
            />
          </div>
          <table className="datalist__datas">
            <thead>
              {
                viewModel.heading &&
                <TableHead typeFilter={"FRONT"}
                 heading={viewModel.heading}
                 sortOrder={sortOrder}
                 setSortOrder={setSortOrder}
                 viewModel={viewModel}
                 setViewModel={setViewModel}
                 currentPage={currentPage}
                />
              }
            </thead>
            <tbody>
            {
              viewModel.data !== undefined &&
              viewModel.data.map((item: ProductCommissionSharesInterface) => (
                <tr key={uuidV4()}>
                  <td>{item.year}</td>
                  <td>{t(`date.month.${item.month}`) || ''}</td>
                  <td>{item.numberOfShares}</td>
                  <td>{item.accumulationOfShares}</td>
                </tr>
              ))
            }
            {
              viewModel.data === undefined || viewModel.data.length === 0 &&
              <tr>
                <td colSpan={6}>{t('common.data-is-empty')}</td>
              </tr>
            }
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={viewModel.pagination.itemsPerPage}
          numberOfItems={viewModel.pagination.numberOfItems}
          callback={paginate}
        />
      </>
    )}
  </>);
}

export default CommissionSharesDatalist
