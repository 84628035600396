import React, {FunctionComponent, useEffect, useState} from 'react'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'
import DocumentGateway from '../../../../../../../gateway/Prospect/Document/DocumentGateway'
import {DocumentInterface} from '../../../../../../../domain/Document/DocumentInterface'
import {useAppSelector} from '../../../../../../store/hook'
import DocumentHistoryBlock from '../../../../Block/DocumentHistoryBlock'

type Props = {
  customer: CustomerInterface
}

const DocumentListHistoryBlock: FunctionComponent<Props> = ({
  customer,
}) => {
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const [documentHistoryArray, setDocumentHistoryArray] = useState<DocumentInterface[]>([])

  useEffect(() => {
    if (customer && customer.id) {
      const documentGateway = new DocumentGateway(customer.id)

      documentGateway.getHistory().then((response) => {
        if (response) {
          setDocumentHistoryArray(response)
        }
      })
    }
  }, [openUploadForm])

  return (
    <div className="col-md-12">
      {customer && customer.id && customer.recoveryCode && documentHistoryArray && (documentHistoryArray.length > 0) &&
        <div className="form-bloc">
          <DocumentHistoryBlock documents={documentHistoryArray} gateway={new DocumentGateway(customer.id)}/>
        </div>
      }
    </div>
  )
}

export default DocumentListHistoryBlock
