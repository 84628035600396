import {Presenter} from '../Presenter'
import {ProductListInterface} from '../../domain/Product/ProductList'
import {CommissioningSessionListInterface} from "../../domain/Commissioning/CommissioningSessionList";
import {CommissioningSessionInterface} from "../../domain/Commissioning/CommissioningList";
import {CommissioningSessionItemInterface} from "../../domain/Commissioning/CommissioningSessionItemInterface";

class CommissioningSessionListPresenter extends Presenter {

  private useCase: { getSession: any }

  constructor(getSession: any) {
    super({
      viewModel: {
        title: 'commissioning-session.filter-block-label',
        heading: [
          {name: 'product.heading.Action'},
          {name: 'ID produit', sort: 'product_acronyme'},
          {name: 'Date de souscription', sort: 'created_at'},
          {name: 'N° de la souscription', sort: 'transaction_code'},
          {name: 'Etat commissionnement', sort: 'commission_status'},
          {name: 'Type de mouvement', sort: 'transaction_type'},
          {name: 'Nature propriete', sort: 'property_type'},
          {name: 'N° investisseur', sort: 'account_code'},
          {name: 'nom investisseur', sort: ''},
          {name: 'ID partenaire souscripteur', sort: ''},
          {name: 'Nom partenaire souscripteur', sort: ''},
          {name: 'Nombre de part', sort: 'share_count'},
          {name: 'Valeur de la part', sort: 'share_price'},
          {name: 'Quote part indiqué en %', sort: 'dismemberment_key'},
          {name: 'Montant investissement', sort: 'total_amount'},
          {name: 'Montant de base', sort: 'base_commission_amount'},
          {name: 'ID Partenaire N1', sort: ''},
          {name: 'Nom partenaire', sort: ''},
          {name: 'Type de barème', sort: ''},
          {name: 'TauxN1', sort: ''},
          {name: 'Montant N1', sort: ''},
          {name: 'Montant TVA 1', sort: ''},
          {name: 'Montant TTI 1', sort: ''},
          {name: 'ID Partenaire N2', sort: ''},
          {name: 'Nom partenaire', sort: ''},
          {name: 'Type de barème', sort: ''},
          {name: 'TauxN2', sort: ''},
          {name: 'Montant N2', sort: ''},
          {name: 'Montant TVA 2', sort: ''},
          {name: 'Montant TTI 2', sort: ''},
          {name: 'ID Partenaire N3', sort: ''},
          {name: 'Nom partenaire', sort: ''},
          {name: 'Type de barème', sort: ''},
          {name: 'TauxN3', sort: ''},
          {name: 'Montant N3', sort: ''},
          {name: 'Montant TVA 3', sort: ''},
          {name: 'Montant TTI 3', sort: ''},
          {name: 'ID Partenaire N4', sort: ''},
          {name: 'Nom partenaire', sort: ''},
          {name: 'Type de barème', sort: ''}, // New column
          {name: 'TauxN4', sort: ''},
          {name: 'Montant N4', sort: ''},
          {name: 'Montant TVA 4', sort: ''},
          {name: 'Montant TTI 4', sort: ''},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getSession,
    }
  }

  async load() {
    try {
      this.setCommissioningSession(await this.useCase.getSession);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  setCommissioningSession(data: {
    data: CommissioningSessionItemInterface[]|null,
    session: CommissioningSessionInterface[]|null,
    nb_element: number
  }) {
    this.update({
      data: data.data,
      session: data.session,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': data.nb_element,
      }
    });
  }
}

export default CommissioningSessionListPresenter
