import {t} from 'i18next'
import {isValidBIC, isValidIBAN} from 'ibantools'

export const validateEmail = (email: string): string | null => {
  const emailRegex = /^([A-Z0-9._%+-]+)(\+[.A-Z0-9-]+)?@([A-Z0-9-.]{2,})(\.[A-Z0-9-]{2,8})$/i
  const doubleDot = /([.+-]+){2,}/
  if (!emailRegex.test(email) || doubleDot.test(email)) {
    return t('common.errors.email')
  }
  return null
}

export const validatePhone = (phone: string): string | null => {
  const phoneRegex = /^\+[\d]{1,3}\s?[1-9](?:[ .]?\d{2}){4}$|^0[1-9](?:[ .]?\d{2}){4}$/
  if (!phoneRegex.test(phone)) {
    return t('common.errors.tel')
  }
  return null
}

export const validateIBAN = (iban: string): string | null => {
  if (!isValidIBAN(iban.replace(/\s/g, ""))) {
    return t('common.errors.iban')
  }
  return null
}

export const validateBIC = (bic: string): string | null => {
  if (!isValidBIC(bic)) {
    return t('common.errors.bic')
  }
  return null
}

export const validators: Record<string, (value: string) => string | null> = {
  email: validateEmail,
  tel: validatePhone,
  iban: validateIBAN,
  bic: validateBIC
}
