import {
  ICommissionCheckListDetail
} from "../infrastructure/presentation/component/Card/Comissioning/CommissionCheckList";

export const COMMISSION_CHECKLIST_FIXTURES: ICommissionCheckListDetail[] = [
  {
    formName: 'verification',
    title: 'Vérification',
    status: false,
    exportUrl: null,
    children: [
      {
        formName: 'partner-not-rib',
        title: 'Partenaires sans RIB',
        status: false,
        exportUrl: null,
      },
      {
        formName: 'partner-rib-good',
        title: 'Cohérence des RIB saisis des partenaires',
        status: false,
        exportUrl: null,
      },
      {
        formName: 'partner-not-scale',
        title: 'Partenaires sans barême',
        status: false,
        exportUrl: null,
      },
      {
        formName: 'partner-not-active',
        title: 'Partenaire ayant un statut autre qu’ ”actif”',
        status: false,
        exportUrl: null,
      },
      {
        formName: 'partner-not-commissioned',
        title: 'Partenaires non commissionnés',
        status: false,
        exportUrl: null,
      }
    ],
  },
];

export const COMMISSION_CHECKLIST_MAPPING_EXPORTS: { [key: string]: { type: string, fileName: string } } = {
  'partner-not-rib':  {
    type: 'not-rib',
    fileName: 'extract-partner-without-rib.csv'
  } ,
  'partner-rib-good': {
    type: 'all-rib',
    fileName: 'extract-partner-with-rib.csv'
  },
  'partner-not-scale': {
    type: 'not-scale',
    fileName: 'extract-partner-without-scales.csv'
  },
  'partner-not-active': {
    type: 'partner-not-active',
    fileName: 'extract-partner-with-not-active.csv'
  },
  'partner-not-commissioned': {
    type: 'partner-not-commissioned',
    fileName: 'extract-partner-not-commissioned.csv'
  }
}

export const COMMISSION_CHECKLIST_ALLOW = [
  'partner-not-rib',
  'partner-rib-good',
  'partner-not-scale',
  'partner-not-active',
  'partner-not-commissioned'
]

