import React, {
  useEffect,
  useState,
  MouseEvent,
  TouchEvent,
  FunctionComponent,
  ReactNode,
} from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'

import envVariable
  from '../../../../infrastructure/presentation/util/envVariable'
import Diligencio from '../../component/Svg/Diligencio'
import LogoDiligencio from '../../component/Svg/logoDiligencio'
import Gooey from '../../component/Gooey/Gooey'
import Dashboard from '../Svg/Dashboard'
import Subscription from '../Svg/Subscription'
import Partners from '../Svg/Partners'
import Products from '../Svg/Products'
import Movements from '../Svg/Movements'
import Investors from '../Svg/Investors'
import Exports from '../Svg/Exports'
import Settings from '../Svg/Settings'
import Ged from '../Svg/Ged'
import novaxia from '../../../../assets/images/company/novaxia.png'
import euryale from '../../../../assets/images/company/euryale.png'
import './bubble.scss'
import Commissionings from '../Svg/Commissionings'
import { useNavigate } from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {
  setBubbleAnimation,
  setBubbleAnimationByDefault,
} from '../../../store/component/me'
import {
  saveAnimationInLocalStorage
} from '../../util/SavePreferencesInLocalStorage'
import Recurrence from "../Svg/Recurrence";

const Bubble: FunctionComponent = () : any => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [startAnimation, setStartAnimation] = useState<boolean>(false)
  const [endAnimation, setEndAnimation] = useState<boolean>(false)
  const [startAnimationFinished, setStartAnimationFinished] = useState<boolean>(false)
  const [animationDuration] = useState<number>(4000)

  const [checked, setChecked] = useState<boolean>(false)

  const preferences = localStorage.getItem("preferences")
  const rules = useAppSelector((state) => state.me.me?.rules)

  const items = [
    {
      href: `/`,
      title: t('main-navigation.dashboard'),
      icon: <Dashboard />,
      dataSpeed: '-5',
    },
    {
      href: `/${t('url.pre-subscription.dashboard')}`,
      title: t('main-navigation.pre-subscription'),
      icon: <Subscription />,
      dataSpeed: '-2',
      rule: 'subscription'
    },
    {
      href: `/${t('url.recurrence.dashboard')}`,
      title: t('main-navigation.recurrence'),
      icon: <Recurrence />,
      dataSpeed: '-3',
      rule: 'recurrence'
    },
    {
      href: `/${t('url.partner.dashboard')}`,
      title: t('main-navigation.partner'),
      icon: <Partners />,
      dataSpeed: '5',
      rule: 'partner'
    },
    {
      href: `/${t('url.product.dashboard')}`,
      title: t('main-navigation.products'),
      icon: <Products />,
      dataSpeed: '-7',
      rule: 'product'
    },
    {
      href: `/${t('url.movements.dashboard')}`,
      title: t('main-navigation.movements'),
      icon: <Movements />,
      dataSpeed: '4',
      rule: 'transaction'
    },
    {
      href: `/${t('url.customer.dashboard')}`,
      title: t('main-navigation.customer'),
      icon: <Investors />,
      dataSpeed: '-3',
      rule: 'customer'
    },
    {
      href: '#',
      title: 'Exports',
      icon: <Exports />,
      dataSpeed: '-5',
    },
    {
      href: `/${t('url.settings.general')}`,
      title: t('main-navigation.settings'),
      icon: <Settings />,
      dataSpeed: '3',
      rule: 'setting'
    },
    {
      href: '#',
      title: t('main-navigation.ged'),
      icon: <Ged />,
      dataSpeed: '7',
    },
    {
      href: `/${t('url.commissioning.dashboard')}`,
      title: t('main-navigation.commissioning'),
      icon: <Commissionings />,
      dataSpeed: '-4',
      rule: 'commissioning'
    },
  ];

  useEffect(() => {
    if(preferences) {
      const preferencesParse = JSON.parse(preferences)
      setChecked(preferencesParse.animation)
    }
    setStartAnimation(true)
  }, [])

  useEffect(() => {
    const timeoutAnimation = setTimeout(() => {
      setStartAnimationFinished(true)
    }, animationDuration)
    return () => clearTimeout(timeoutAnimation)
  }, [])

  const saveLocalStorage = (e: any) => {
    setChecked(e.target.checked)
    saveAnimationInLocalStorage(preferences, e.target.checked)
    dispatch(setBubbleAnimationByDefault(e.target.checked))
  }

  const handleMoveMouse = (e: MouseEvent) => {

    const pageX = e.pageX
    const pageY = e.pageY
    const items = document.querySelectorAll('.menu__item')

    items.forEach((item: any) => {
      const speed: number = Number(item.getAttribute('data-speed')) || 0

      const x = (window.innerWidth - pageX * speed)
      const y = (window.innerHeight - pageY * speed)

      item.style.marginLeft = `${x / 100}px`
      item.style.marginTop = `${y / 100}px`
    })
  }

  const handleMoveTouchMouse = (e: TouchEvent) => {

    const pageX = e.touches[0].pageX
    const pageY = e.touches[0].pageY
    const items = document.querySelectorAll('.menu__item')

    items.forEach((item: any) => {
      const speed: number = Number(item.getAttribute('data-speed')) || 0

      const x = (window.innerWidth - pageX * speed)
      const y = (window.innerHeight - pageY * speed)

      item.style.marginLeft = `${x / 100}px`
      item.style.marginTop = `${y / 100}px`
    })
  }

  const handleClickItem = (link: string) => {
    if (startAnimationFinished) {
      setStartAnimation(false)
      setEndAnimation(true)
      dispatch(setBubbleAnimation(false))
      navigate(link)
    }
  }

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const handleDurationItem = (i: number) => {
    if (endAnimation) {
      return .4 + (.2 * i) // duration bubbles for the end animation
    }
    return .6 + (.4 * i) // duration bubbles for the start animation
  }

  return createPortal(
    <Container className='container'
               endAnimation={endAnimation}
               onMouseMove={(e) => (startAnimationFinished && !endAnimation) && handleMoveMouse(e)}
               onTouchMove={(e) => (startAnimationFinished && !endAnimation) && handleMoveTouchMouse(e)}
    >
      <NavStyled className='menu' endAnimation={endAnimation}>
        <BubbleMain
          className={`menu__item-dlg ${startAnimation && 'menu__item-dlg--is-active'}`}
          endAnimation={endAnimation}
        >
          <div>
            <i><LogoDiligencio /></i>
            <Diligencio />
          </div>

          {envVariable('REACT_APP_ENV') === 'novaxia' &&
            <img src={novaxia} alt='Novaxia' />}
          {envVariable('REACT_APP_ENV') === 'euryale' &&
            <img src={euryale} alt='Euryale' />}
        </BubbleMain>

        {items && items.map((item: { href: string, title: string, icon: ReactNode, dataSpeed: string, rule?: string }, i: number) => {
          if (item?.rule && rules) {
            if (!(item.rule in rules)) {
              return;
            }

            type ObjectKey = keyof typeof rules;
            const key = item.rule as ObjectKey;

            if (!rules[key]?.visible) {
              return ;
            }
          }

          return (
            <MenuItem key={i}
                      index={1}
                      onClick={() => handleClickItem(item.href)}
                      startAnimation={startAnimation}
                      startAnimationFinished={startAnimationFinished}
                      endAnimation={endAnimation}
                      duration={handleDurationItem(i)}
                      rotation={getRandomInt(-360, 360)}
                      randomHeight={getRandomInt(-60, 60)}
                      className='menu__item'
                      data-speed={item.dataSpeed}>
              <i>{item.icon}</i>
              <span>{item.title}</span>
            </MenuItem>
          )
        })}
      </NavStyled>
      <ContainerText endAnimation={endAnimation}>
        <label htmlFor={"animationByDefault"} className="form-control__label">{t('common.bubble.checkbox-skip-intro')}</label>
        <input type={'checkbox'} id="animationByDefault" onClick={(e) => saveLocalStorage(e)} checked={checked} />
      </ContainerText>

      <div className='gooey-wrapper'>
        <Gooey /> {/* Svg Filter */}
      </div>
    </Container>,
    document.getElementById('portal')!
  )
}

const moveIconsLeft = (randomHeight: number, rotation: number) => keyframes`
  from {
    transform: translate3D(0, 0, 0) rotate(0deg);
  }
  to {
    transform: translate3D(-100vw, ${randomHeight}vh, 0) rotate(${rotation}deg);
  }
`

const DLGAppears = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.9);
  }
`

const DLGOrIconsDisappears = keyframes`
  0% {
    transform: scale(1.9);
  }

  10% {
    transform: scale(2.0);
  }
  100% {
    transform: scale(0);
  }
`

const ContainerText = styled.div<{endAnimation: boolean}>`
  display: flex;
  align-items: center;
  position: absolute;
  left: 2%;
  bottom: 2%;
  opacity: ${({ endAnimation }) => endAnimation ? 0 : 1};
`

const NavStyled = styled.nav<{ endAnimation: boolean }>`
  filter: ${({ endAnimation }) => !endAnimation && 'url(\'#gooey\')'};
`

const BubbleMain = styled.div<{ endAnimation: boolean }>`
  animation: ${({ endAnimation }) => !endAnimation ? DLGAppears : DLGOrIconsDisappears} 1.5s forwards ${({ endAnimation }) => endAnimation && `${2.1 - 0.8}s`} // delay must be corresponded with last items duration subtracted with 4 items duration - see in handleDuration()
  cubic-bezier(0.935, 0.000, 0.340, 1.330);
  transform: scale(1.9);

  i {
    // opacity: ${({ endAnimation }) => endAnimation ? 0 : 1};
    // transform: ${({ endAnimation }) => endAnimation ? 'translate3D(-100vw,-20vh,0) rotate(0deg)' : 'translate3D(0,0,0) rotate(360deg)'};;
    transition: transform 2s linear 1.3s, opacity .1s linear 2s;
  }
`

const Container = styled.section<{endAnimation: boolean}>`
  background-color: ${({ endAnimation }) => endAnimation ? "rgba(255,255,255,0%)" : "rgba(255,255,255,100%)"};
  transition: background-color 1.5s ease-in-out 200ms;
  
`

const MenuItem = styled.a<{
  index: number,
  startAnimation: boolean,
  startAnimationFinished: boolean,
  endAnimation: boolean,
  duration: number,
  rotation: number,
  randomHeight: number
}>`
  overflow: ${({ endAnimation }) => !endAnimation && 'hidden'};
  opacity: ${({
                startAnimation,
                startAnimationFinished,
              }) => startAnimation || startAnimationFinished ? 1 : 0};
  transition-property: opacity, transform, margin-left, margin-top;
  transition-duration: ${({ duration }) => duration}s, ${({ duration }) => duration}s, 300ms, 300ms;
  transition-timing-function: linear, cubic-bezier(0.935, 0.000, 0.340, 1.330), linear, linear;
  transition-delay: ${({
                         startAnimation,
                         startAnimationFinished,
                       }) => (startAnimation && !startAnimationFinished) && '1s,'} // opacity only on first animation
  1.4s, 0s, 0s;
  animation: ${({ endAnimation }) => endAnimation && DLGOrIconsDisappears} 0s forwards 2s cubic-bezier(0.935, 0.000, 0.340, 1.330);
  cursor: pointer;

  &::before {
    display: ${({
                  endAnimation,
                  startAnimationFinished,
                }) => (endAnimation || !startAnimationFinished) && 'none'}
  }

  span {
    opacity: ${(props) => props.endAnimation && '0'}
  }

  i {
    animation: ${({
                    endAnimation,
                    randomHeight,
                    rotation,
                  }) => endAnimation && moveIconsLeft(randomHeight, rotation)};
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: ${({ index }) => `${400 + index * 400}ms`};
    animation-fill-mode: forwards;

    svg > path {
      fill: ${({ endAnimation }) => endAnimation ? '#04668C' : 'white'};
      transition: fill ${(props: { duration: number }) => props.duration}s linear;
    }
  }
`

export default Bubble
