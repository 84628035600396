import React, { FunctionComponent, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import ButtonDownloadStyled from '../../StyledComponents/ButtonDownloadStyled'
import Folder from '../../../../../assets/images/icons/Folder'

export type TEditionDownloadItem =  {
  type: string
  title: string
  icon?: JSX.Element|string
}

type TProps = {
  sessionId: string
  title: string
  register: any
  control: any
  editionArray: TEditionDownloadItem[]
  parentPath: string
  classes?: string
  onClick: ((item: TEditionDownloadItem) => void)
}

const EditionAndDownload: FunctionComponent<TProps> = ({
                                                         sessionId,
                                                         editionArray,
                                                         classes,
                                                         title,
                                                         onClick
                                                       }) => {

  const { t } = useTranslation()
  const [click, setClick] = useState<boolean>(false)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [state, setState] = useState<'error' | 'success' | ''>("")

  const handleClickDownload = (item: TEditionDownloadItem) => {
    setLoadingExport(true)
    onClick(item)
    setLoadingExport(false)
  }

  const getIcon = (icon?: JSX.Element|string) : JSX.Element => {
    if (!icon) {
      return <Folder width={14} height={30} />
    }
    if ('string' === typeof icon) {
      return <img src={icon} style={{ height: 14, width: 30 }}/>
    }
    return icon
  }

  return (
    <div className={classes}>
      <div className='form-bloc form-bloc--action'>
        <div className='form-bloc__title'>{title}</div>
        <div className='form-bloc__form flex-container'>
          {editionArray && editionArray.map((action: TEditionDownloadItem) => {
            return (
              <div key={uuidV4()} className={`col-md-4 relative`}>
                <ButtonDownloadStyled onClick={() => handleClickDownload(action)}
                                      clicked={click}
                                      state={state}
                                      label={t(`${action.title}`)}
                                      btnName={action.title || ""}
                                      icon={getIcon(action.icon)}
                                      disabled={isLoadingExport}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default EditionAndDownload