import {ListRequestInterface} from './ListRequest'
import RecurrenceSessionMandateGateway from '../../../gateway/Recurrence/RecurrenceSessionMandateGateway'
import {RecurrenceSessionMandateInterface} from '../../../domain/Recurrence/RecurrenceSessionMandateInterface'


export default class ListUseCase
{
  public recurrenceSessionMandateGateway

  constructor(recurrenceSessionMandateGateway: RecurrenceSessionMandateGateway) {
    this.recurrenceSessionMandateGateway = recurrenceSessionMandateGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: RecurrenceSessionMandateInterface[]|null, numberOfItems: number}|null> {
    return await this.recurrenceSessionMandateGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, ListRequest.sessionId, signal, sort, order).then(response => response)
  }
}
