import dayjs from 'dayjs'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import { AddressInterface } from '../../../../../../domain/Address/Address'
import {
  GeneralAddressInterface,
  GENERAL_ADDRESS_STATUS_ACTIVE,
  GENERAL_ADDRESS_STATUS_INACTIVE,
} from '../../../../../../domain/Address/GeneralAddress'
import { ProspectInterface } from '../../../../../../domain/Prospect/Prospect'
import { CUSTOMER_ADDRESS_STATUS } from '../../../../../../fixtures/Customer'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import { setOpenAccountAddressFormEvent } from '../../../../../store/component/event'
import {
  addCurrentFiscalAddress,
  editCurrentFiscalAddress,
  reloadProspect,
} from '../../../../../store/component/prospect'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { usePrompt } from '../../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import { toastError, toastSuccess, toastWarning } from '../../../../util/Toast'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'
import AddressBlock from '../../../Block/AddressBlock'
import ConfirmationModal from '../../../Modal/ConfirmationModal'

interface IFormInput {
  sameFiscalAddress: boolean
  prospectAddress: GeneralAddressInterface
  prospectFiscalAddress: GeneralAddressInterface
}

type Props = {
  prospect: ProspectInterface
  isLectureMode?: boolean
  handler?: any
}

const Address: FunctionComponent<Props> = ({ prospect, isLectureMode, handler }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const firstUpdate = useRef(true)

  const countryByDefault = useAppSelector(({ referential }) => referential.countryByDefault)
  const referential = useAppSelector(({ referential }) => referential.referential)
  const prospectCurrentAddress = useAppSelector(state => state.prospect.prospectCurrentAddress)
  const prospectCurrentFiscalAddress = useAppSelector(state => state.prospect.prospectCurrentFiscalAddress)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const defaultFirstname = prospect?.persons?.[0].firstname || null
  const defaultLastname = prospect?.persons?.[0].lastname || null
  const defaultTitle = prospect?.prospectType === 'moral' ? '' : prospect?.persons?.[0]?.title || null
  const defaultSocialReason = prospect?.companyName || null

  const { register, control, watch, handleSubmit, setValue, getValues } = useForm<IFormInput>({
    defaultValues: {
      prospectAddress: {
        address: {
          id: prospectCurrentAddress?.address?.id || null,
          firstname: prospectCurrentAddress?.address?.firstname || defaultFirstname,
          lastname: prospectCurrentAddress?.address?.lastname || defaultLastname,
          title: prospectCurrentAddress?.address?.title || defaultTitle,
          socialReason: prospectCurrentAddress?.address?.socialReason || defaultSocialReason,
          address: prospectCurrentAddress?.address?.address || '',
          address2: prospectCurrentAddress?.address?.address2 || '',
          address3: prospectCurrentAddress?.address?.address3 || '',
          city: prospectCurrentAddress?.address?.city || null,
          country: prospectCurrentAddress?.address?.country || null,
          postcode: prospectCurrentAddress?.address?.postcode || '',
          npai: !!prospectCurrentAddress?.address?.npaiAt,
          npaiAt: prospectCurrentAddress?.address?.npaiAt || '',
          updatedAt: prospectCurrentAddress?.address?.updatedAt || '',
        },
        status: prospectCurrentAddress?.status || GENERAL_ADDRESS_STATUS_ACTIVE,
      },
      prospectFiscalAddress: {
        address: {
          id: prospectCurrentFiscalAddress?.address?.id || null,
          firstname: prospectCurrentFiscalAddress?.address?.firstname || defaultFirstname,
          lastname: prospectCurrentFiscalAddress?.address?.lastname || defaultLastname,
          title: prospectCurrentFiscalAddress?.address?.title || defaultTitle,
          socialReason: prospectCurrentFiscalAddress?.address?.socialReason || defaultSocialReason,
          address: prospectCurrentFiscalAddress?.address?.address || '',
          address2: prospectCurrentFiscalAddress?.address?.address2 || '',
          address3: prospectCurrentFiscalAddress?.address?.address3 || '',
          city: prospectCurrentFiscalAddress?.address?.city || null,
          country: prospectCurrentFiscalAddress?.address?.country || null,
          postcode: prospectCurrentFiscalAddress?.address?.postcode || '',
          npai: !!prospectCurrentFiscalAddress?.address?.npaiAt,
          npaiAt: prospectCurrentFiscalAddress?.address?.npaiAt || '',
          updatedAt: prospectCurrentFiscalAddress?.address?.updatedAt || '',
        },
        status: prospectCurrentFiscalAddress?.status || GENERAL_ADDRESS_STATUS_ACTIVE,
      },
      sameFiscalAddress: prospect.sameFiscalAddress,
    },
  })

  const watchSameFiscalAddress = watch('sameFiscalAddress')
  const watchAddressNpai = watch('prospectAddress.address.npai')
  const watchAddressFiscalNpai = watch('prospectFiscalAddress.address.npai')
  const watchPostCode = watch('prospectAddress.address.postcode')
  const watchCity = watch('prospectAddress.address.city')
  const watchCountry = watch('prospectAddress.address.country')
  const watchAddressFiscalCountry = watch('prospectFiscalAddress.address.country')
  const watchCurrentAddressStatus = watch('prospectAddress.status')
  const watchCurrentAddressFiscalStatus = watch('prospectFiscalAddress.status')

  const measuredRef = useCallback(node => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [])

  const statusAddress = useMemo(() => {
    return !!(watchCurrentAddressStatus && watchCurrentAddressStatus === CUSTOMER_ADDRESS_STATUS.ACTIVE)
  }, [watchCurrentAddressStatus])

  const statusAddressFiscal = useMemo(() => {
    return !!(watchCurrentAddressFiscalStatus && watchCurrentAddressFiscalStatus === CUSTOMER_ADDRESS_STATUS.ACTIVE)
  }, [watchCurrentAddressFiscalStatus])

  const stateBtnAddNewFiscalAddress = useMemo(() => {
    return isLectureMode || (!watchSameFiscalAddress && statusAddressFiscal && !!prospectCurrentFiscalAddress)
  }, [isLectureMode, watchSameFiscalAddress, statusAddressFiscal, prospectCurrentFiscalAddress])

  useEffect(() => {
    if (prospectCurrentAddress) {
      setValue('prospectAddress.address.id', prospectCurrentAddress?.address?.id || null)
      setValue('prospectAddress.address.firstname', prospectCurrentAddress?.address?.firstname || '')
      setValue('prospectAddress.address.lastname', prospectCurrentAddress?.address?.lastname || '')
      setValue('prospectAddress.address.socialReason', prospectCurrentAddress?.address?.socialReason || '')
      setValue('prospectAddress.address.title', prospectCurrentAddress?.address?.title || '')
      setValue('prospectAddress.address.address', prospectCurrentAddress?.address?.address || '')
      setValue('prospectAddress.address.address2', prospectCurrentAddress?.address?.address2 || '')
      setValue('prospectAddress.address.address3', prospectCurrentAddress?.address?.address3 || '')
      setValue('prospectAddress.address.city', prospectCurrentAddress?.address?.city || null)
      setValue('prospectAddress.address.country', prospectCurrentAddress?.address?.country || null)
      setValue('prospectAddress.address.postcode', prospectCurrentAddress?.address?.postcode || '')
      setValue('prospectAddress.address.updatedAt', dayjs().format('DD/MM/YYYY'))
      setValue('prospectAddress.address.npai', !!prospectCurrentAddress?.address?.npaiAt)
      setValue('prospectAddress.status', prospectCurrentAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE)
      if (!prospectCurrentFiscalAddress) {
        setValue('sameFiscalAddress', true)
      }
    }
    if (prospectCurrentFiscalAddress) {
      setValue('prospectFiscalAddress.address.id', prospectCurrentFiscalAddress?.address?.id || null)
      setValue('prospectFiscalAddress.address.firstname', prospectCurrentFiscalAddress?.address?.firstname || '')
      setValue('prospectFiscalAddress.address.lastname', prospectCurrentFiscalAddress?.address?.lastname || '')
      setValue('prospectFiscalAddress.address.socialReason', prospectCurrentFiscalAddress?.address?.socialReason || '')
      setValue('prospectFiscalAddress.address.title', prospectCurrentFiscalAddress?.address?.title || '')
      setValue('prospectFiscalAddress.address.address', prospectCurrentFiscalAddress?.address?.address || '')
      setValue('prospectFiscalAddress.address.address2', prospectCurrentFiscalAddress?.address?.address2 || '')
      setValue('prospectFiscalAddress.address.address3', prospectCurrentFiscalAddress?.address?.address3 || '')
      setValue('prospectFiscalAddress.address.city', prospectCurrentFiscalAddress?.address?.city || null)
      setValue('prospectFiscalAddress.address.country', prospectCurrentFiscalAddress?.address?.country || null)
      setValue('prospectFiscalAddress.address.postcode', prospectCurrentFiscalAddress?.address?.postcode || '')
      setValue('prospectFiscalAddress.address.updatedAt', dayjs().format('DD/MM/YYYY'))
      setValue('prospectFiscalAddress.address.npai', !!prospectCurrentFiscalAddress?.address?.npaiAt)
      setValue('prospectFiscalAddress.status', prospectCurrentFiscalAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE)
    }
  }, [prospectCurrentAddress, prospectCurrentFiscalAddress])

  useEffect(() => {
    if (watchSameFiscalAddress) {
      setValue('prospectFiscalAddress.address.firstname', prospectCurrentAddress?.address?.firstname || '')
      setValue('prospectFiscalAddress.address.lastname', prospectCurrentAddress?.address?.lastname || '')
      setValue('prospectFiscalAddress.address.socialReason', prospectCurrentAddress?.address?.socialReason || '')
      setValue('prospectFiscalAddress.address.title', prospectCurrentAddress?.address?.title || '')
      setValue('prospectFiscalAddress.address.address', prospectCurrentAddress?.address?.address || '')
      setValue('prospectFiscalAddress.address.address2', prospectCurrentAddress?.address?.address2 || '')
      setValue('prospectFiscalAddress.address.address3', prospectCurrentAddress?.address?.address3 || '')
      setValue('prospectFiscalAddress.address.city', prospectCurrentAddress?.address?.city || null)
      setValue('prospectFiscalAddress.address.country', prospectCurrentAddress?.address?.country || null)
      setValue('prospectFiscalAddress.address.postcode', prospectCurrentAddress?.address?.postcode || '')
      setValue('prospectFiscalAddress.address.updatedAt', dayjs().format('DD/MM/YYYY'))
      setValue('prospectFiscalAddress.address.npai', watchAddressNpai ?? !!prospectCurrentAddress?.address?.npaiAt)
      setValue('prospectFiscalAddress.status', prospectCurrentFiscalAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE)

      if (prospectCurrentFiscalAddress && prospectCurrentAddress?.address) {
        dispatch(editCurrentFiscalAddress(prospectCurrentAddress))
      } else if (prospectCurrentAddress) {
        dispatch(addCurrentFiscalAddress(prospectCurrentAddress))
      }
    }
  }, [watchSameFiscalAddress, prospectCurrentAddress])

  useEffect(() => {
    const anyAddressFiscalWithEmptyCountry = prospect?.prospectAddresses
      ?.filter(a => a.type === 'fiscal')
      .some((a: GeneralAddressInterface) => a.address?.country === null)
    if (watchAddressFiscalCountry && anyAddressFiscalWithEmptyCountry) {
      setValue('prospectFiscalAddress.address.city', null)
      setValue('prospectFiscalAddress.address.postcode', null)
    }
  }, [watchAddressFiscalCountry])

  useEffect(() => {
    const anyAddressWithEmptyCountry = prospect?.prospectAddresses
      ?.filter(a => a.type !== 'fiscal' && a.main)
      .some((a: GeneralAddressInterface) => a.address?.country === null)
    if (watchCountry && anyAddressWithEmptyCountry) {
      setValue('prospectAddress.address.city', null)
      setValue('prospectAddress.address.postcode', null)
    }
  }, [watchCountry])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (watchAddressNpai) {
      setValue('prospectAddress.address.npaiAt', dayjs().format('DD/MM/YYYY'))
      setValue('prospectAddress.address.id', prospectCurrentAddress?.address?.id || null)
    } else {
      setValue('prospectAddress.address.npaiAt', null)
      setValue('prospectAddress.address.id', prospectCurrentAddress?.address?.id || null)
    }
    if (watchAddressFiscalNpai || (watchAddressNpai && watchSameFiscalAddress)) {
      setValue('prospectFiscalAddress.address.npaiAt', dayjs().format('DD/MM/YYYY'))
      setValue('prospectFiscalAddress.address.npai', true)
      setValue('prospectFiscalAddress.address.id', prospectCurrentFiscalAddress?.address?.id || null)
    } else {
      setValue('prospectFiscalAddress.address.npaiAt', null)
      setValue('prospectFiscalAddress.address.npai', true)
      setValue('prospectFiscalAddress.address.id', prospectCurrentFiscalAddress?.address?.id || null)
    }
  }, [watchAddressNpai, watchAddressFiscalNpai])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (!!prospectCurrentAddress && watchCurrentAddressStatus !== GENERAL_ADDRESS_STATUS_ACTIVE) {
      toastError(t('common.address.status-active-required', { type: 'postale' }))
      return
    }

    if (!!prospectCurrentFiscalAddress && watchCurrentAddressFiscalStatus !== GENERAL_ADDRESS_STATUS_ACTIVE) {
      toastError(t('common.address.status-active-required', { type: 'fiscale' }))
      return
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }
  const onConfirm = (data: IFormInput) => {
    if (data.prospectAddress.address?.npai) {
      toastWarning(t('common.address.pnd-required', { type: 'postale' }))
    }
    if (data?.sameFiscalAddress && data.prospectFiscalAddress.address?.npai) {
      toastWarning(t('common.address.pnd-required', { type: 'fiscale' }))
    }
    const prospectCopy = { ...prospect }
    let oldAddresses: GeneralAddressInterface[] = []

    if (prospect.prospectAddresses) {
      oldAddresses = prospect.prospectAddresses.map(el => {
        if (el.type === 'postal') {
          if (el?.address?.id !== prospectCurrentAddress?.address?.id) {
            el.main = false
            el.status = GENERAL_ADDRESS_STATUS_INACTIVE
          }
        }

        if (el.type === 'fiscal') {
          if (el?.address?.id !== prospectCurrentFiscalAddress?.address?.id) {
            el.main = false
            el.status = GENERAL_ADDRESS_STATUS_INACTIVE
          }
        }

        return el
      })
    }

    const postalAddressIsValid = data.prospectAddress.address ? addressIsValid(data.prospectAddress.address) : false
    const fiscalAddressIsValid = data.prospectFiscalAddress.address
      ? addressIsValid(data.prospectFiscalAddress.address)
      : false

    const newPostalAddress = !data.prospectAddress.address?.id && postalAddressIsValid
    const newFiscalAddress = !data.prospectFiscalAddress.address?.id && fiscalAddressIsValid

    if (data.sameFiscalAddress && newPostalAddress) {
      prospectCopy.prospectAddresses = [
        {
          ...data.prospectAddress,
          type: 'postal',
          main: true,
        },
        {
          ...data.prospectAddress,
          type: 'fiscal',
          main: true,
        },
      ]
    } else if (!data.sameFiscalAddress) {
      if (newPostalAddress && newFiscalAddress) {
        prospectCopy.prospectAddresses = [
          {
            ...data.prospectAddress,
            type: 'postal',
            main: true,
          },
          {
            ...data.prospectFiscalAddress,
            type: 'fiscal',
            main: true,
          },
        ]
      } else if (!newPostalAddress && newFiscalAddress) {
        prospectCopy.prospectAddresses = [{ ...data.prospectFiscalAddress, type: 'fiscal', main: true }]
      } else if (newPostalAddress && !newFiscalAddress) {
        prospectCopy.prospectAddresses = [{ ...data.prospectAddress, type: 'postal', main: true }]
      }
    } else {
      prospectCopy.prospectAddresses = []
    }

    if (oldAddresses.length && prospectCopy.prospectAddresses?.length) {
      // Remove duplicate prospect address
      const oldAddressIds = new Set(oldAddresses.map(item => item.address?.id || null))
      prospectCopy.prospectAddresses = [
        ...prospectCopy.prospectAddresses.filter(item => !oldAddressIds.has(item.address?.id || null)),
        ...oldAddresses,
      ]
    } else if (oldAddresses.length) {
      prospectCopy.prospectAddresses = [...oldAddresses]
    }

    prospectCopy.sameFiscalAddress = data.sameFiscalAddress

    new UpdateUseCase(new ProspectGateway())
      .execute(prospectCopy)
      .then(response => {
        if (null !== response) {
          if (handler) {
            handler(prospectCopy)
          }
          dispatch(reloadProspect())
          setIsLoading(false)
          toastSuccess(t('account.notify.update-success'))
        } else {
          toastError(t('account.notify.update-error'))
        }
      })
      .catch(() => {
        setIsLoading(false)
        toastError(t('account.notify.update-error'))
      })
  }

  const addressIsValid = (address: AddressInterface): boolean => {
    const { firstname, lastname, city, country, postcode } = address
    if (address.address && firstname && lastname && city && country && postcode) {
      return true
    } else return false
  }

  const { isDirty } = useFormState({
    control,
  })
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm))

  const renderFooter = () => {
    if (isLectureMode) return null
    return (
      <footer className={`form-bloc__footer`}>
        <button type='submit' className='button button--ink-2' disabled={isLoading}>
          {t('common.save')}
        </button>
        <button
          type='button'
          className='button button--ink-2 button--ink-2--outline'
          onClick={() => {
            navigate(`/${t('url.prospect.dashboard')}`)
          }}
        >
          {t('common.cancel')}
        </button>
      </footer>
    )
  }

  return referential && !isLoading ? (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className='flex-container'>
        <div className='col-md-12'>
          <div className='flex-container'>
            <div className='col-md-12'>
              <AlertInfo text={t('account.form.address.alert-unique-addr-by-num-client')} />
            </div>
          </div>
        </div>
        <div className='flex col-md-12'>
          <div className={`col-md-6 ${isLectureMode ? 'block-address-lecture-mode' : 'block-address'}`}>
            <div className='form-bloc__title'>{t('account.form.address.title')}</div>
            {!isLectureMode && (
              <div className='col-md-12'>
                <div className='form-bloc__shortcut u-txt-right'>
                  <button
                    type='button'
                    className='button-reset u-txt-with-icon'
                    onClick={() =>
                      dispatch(
                        setOpenAccountAddressFormEvent({
                          show: true,
                          typeAddress: 'postal',
                          defaultCountry: countryByDefault[0],
                          defaultUserInfo: {
                            firstname: prospect?.persons?.[0].firstname || null,
                            lastname: prospect?.persons?.[0].lastname || null,
                            title: prospect?.persons?.[0].title || null,
                            type: prospect?.prospectType,
                            socialReason: prospect?.companyName || null,
                          },
                        })
                      )
                    }
                    disabled={isLectureMode || (statusAddress && !!prospectCurrentAddress)}
                  >
                    <img src={iconAdd} alt='' />
                    {t('account.form.address.add')}
                  </button>
                </div>
              </div>
            )}
            {prospectCurrentAddress && (
              <AddressBlock
                register={register}
                control={control}
                getValues={getValues}
                setValue={setValue}
                typeClient={prospect.prospectType}
                watchPostCode={watchPostCode}
                watchCity={watchCity}
                watchCountry={watchCountry}
                watchActive={watchCurrentAddressStatus}
                isCheckboxStatus={true}
                parentPath={'prospectAddress'}
                typeAddress={'postal'}
                currentAddress={prospectCurrentAddress?.address}
              />
            )}
          </div>

          {!watchSameFiscalAddress && (
            <div className={`col-md-6 ${isLectureMode ? 'block-address-lecture-mode' : 'block-address'}`}>
              <div className='form-bloc__title'>{t('account.form.address.titleFiscality')}</div>
              {!isLectureMode && prospectCurrentAddress && (
                <div className='form-bloc__shortcut u-txt-right'>
                  <button
                    type='button'
                    className='button-reset u-txt-with-icon'
                    onClick={() =>
                      dispatch(
                        setOpenAccountAddressFormEvent({
                          show: true,
                          typeAddress: 'fiscal',
                          defaultCountry: countryByDefault[0],
                          defaultUserInfo: {
                            firstname: prospect?.persons?.[0].firstname || null,
                            lastname: prospect?.persons?.[0].lastname || null,
                            title: prospect?.persons?.[0].title || null,
                            type: prospect?.prospectType,
                            socialReason: prospect?.companyName || null,
                          },
                        })
                      )
                    }
                    disabled={stateBtnAddNewFiscalAddress}
                  >
                    <img src={iconAdd} alt='' />
                    {t('account.form.address.add-fiscal')}
                  </button>
                </div>
              )}
              {prospectCurrentFiscalAddress && (
                <AddressBlock
                  register={register}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  typeClient={prospect.prospectType}
                  watchPostCode={watchPostCode}
                  watchCity={watchCity}
                  watchCountry={watchCountry}
                  watchActive={watchCurrentAddressFiscalStatus}
                  isCheckboxStatus={true}
                  parentPath={'prospectFiscalAddress'}
                  typeAddress={'fiscal'}
                  currentAddress={prospectCurrentFiscalAddress?.address}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {renderFooter()}
    </form>
  ) : null
}

export default Address
