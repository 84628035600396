import { FilterInvestorSheetInterface } from '../../../domain/Distribution'

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterInvestorSheetInterface|null
  accountId: string
}

class ListRequest implements ListRequestInterface{
  constructor(
    public currentPage: number,
    public itemPerPage: number,
    public filter: FilterInvestorSheetInterface|null,
    public accountId: string,
  ) {
    this.accountId = accountId
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
