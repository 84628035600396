import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';

type TProps = {
  isLectureMode?: boolean | undefined
  disabled?: boolean
  canClose?: boolean
  onCancelUrl?: string
}

const Footer: FunctionComponent<TProps> = ({isLectureMode, disabled=false, onCancelUrl = null}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  if (isLectureMode) return null
  return <footer className={`form-bloc__footer col-md-12`}>
    <button type="submit"
            className="button button--ink-2"
            disabled={disabled}
    >
      {t('common.save')}
    </button>
    {onCancelUrl && 
      <button type="button" className="button button--ink-2 button--ink-2--outline"
        onClick={() => {navigate(`/${onCancelUrl}`)}}>
        {t('common.cancel')}
      </button>
      ||
      <button type="submit" className="button button--ink-2 button--ink-2--outline">
        {t('common.cancel')}
      </button>
    }
  </footer>
}

export default Footer;