import React, {FunctionComponent, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Modal from 'react-modal'
import {useForm, SubmitHandler} from 'react-hook-form'

import '../../../../assets/styles/components/_bloc-information-shortcut.scss'
import '../../../../assets/styles/components/_modal.scss'
import imgConfirm from '../../../../assets/images/icons/confirm.svg'
import ControlledDatePicker from '../Elements/ControlledDatePicker'
import InputMask from 'react-input-mask'
import Input from '../Elements/Input'

type Props = {
  onConfirm: (data: FormValues) => void;
  onClose: () => void;
  mandateId: string;
  optionStyles?: Modal.Styles;
};

type FormValues = {
  paymentAt: string;
  externalReference: string;
  paymentReference: string;
  paymentAmount: number;
  mandateId: string;
};

const SessionMandatePaymentValidatedModal: FunctionComponent<Props> = ({
  onConfirm,
  onClose,
  mandateId,
  optionStyles
}) => {
  Modal.setAppElement('#root')

  const {t} = useTranslation()
  const {register, handleSubmit, formState: {errors}, control} = useForm<FormValues>()

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    onConfirm({...data, mandateId})
    onClose()
  }

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 1000,
    },
  }

  const modalStyles = useMemo(() => {
    if (optionStyles) {
      return {
        ...styles,
        ...optionStyles,
      }
    }
    return styles
  }, [optionStyles, styles])

  return (
    <Modal isOpen={true} style={modalStyles}>
      <div className="content">
        <div className="content__image">
          <img src={imgConfirm} alt=""/>
        </div>
        <p className="content__text u-pbb">
          {t('recurrence.session-mandate.modal.title-payment-validated')}
        </p>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-bloc__form flex-container">
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="sessionCriteria.year" className="form-control__label mandatory-field">
                  {t('recurrence.session-mandate.modal.payment-at')}
                </label>
                <div className="form-control__input">
                  <ControlledDatePicker
                    control={control}
                    name={`paymentAt`}
                    locale="fr"
                    dateFormat="dd/MM/yyyy hh:ii:ss"
                    minDate={new Date(2022, 1, 1)}
                    required={true}
                    customInput={
                      <InputMask
                        mask="99/99/9999 99:99:99"
                        maskPlaceholder="__/__/____ __:__:__"
                        alwaysShowMask={true}
                        required={true}
                      />
                    }
                    errorMessage={errors.paymentAt?.message}
                  />
                </div>
              </div>
            </div>
            <Input register={register}
                   classes={'col-md-6'}
                   label={t('recurrence.session-mandate.modal.external-reference')}
                   type={'text'}
                   name={'externalReference'}
                   id={'externalReference'}
                   required={true}
                   errorMessage={errors.externalReference?.message}
            />
            <Input register={register}
                   classes={'col-md-6'}
                   label={t('recurrence.session-mandate.modal.payment-reference')}
                   type={'text'}
                   name={'paymentReference'}
                   id={'paymentReference'}
                   required={true}
                   errorMessage={errors.paymentReference?.message}
            />
            <Input register={register}
                   classes={'col-md-6'}
                   label={t('recurrence.session-mandate.modal.payment-amount')}
                   type={'number'}
                   name={'paymentAmount'}
                   id={'paymentAmount'}
                   required={true}
                   errorMessage={errors.paymentAmount?.message}
            />
          </div>
          <div className="content__buttons">
            <button
              type="submit"
              className="button button--ink-2 u-mrm"
            >
              {t('common.save')}
            </button>
            <button
              type="button"
              className="button button--ink-2 button--ink-2--outline"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default SessionMandatePaymentValidatedModal
