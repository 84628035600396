import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Refused: FunctionComponent<Props> = ({color}) => {
    return (
      <svg fill={color} height="21px" width="21px" version="1.1"
           xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve">
        <g>
          <path d="M12,0C5.37,0,0,5.37,0,12s5.37,12,12,12s12-5.37,12-12S18.63,0,12,0z M18.38,16.62l-1.77,1.77L12,13.77l-4.62,4.62l-1.77-1.77L10.23,12L5.62,7.38l1.77-1.77L12,10.23l4.62-4.62l1.77,1.77L13.77,12L18.38,16.62z"/>
        </g>
      </svg>
    )
}

export default Refused
