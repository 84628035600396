import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'

import '../../../../../assets/styles/page/_product.scss'
import SettingForm from "../../../component/Form/Recurrence/Setting"
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import Spinner from "../../../component/Spinner/Spinner";
import ErrorPage from "../../Error/ErrorPage";
import {TError} from "../../../../../domain/Error/Error";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import TabRecurrenceAdd from "../../../component/Tab/Recurrence/Add/TabRecurrenceAdd";
import { RecurrenceSessionInterface } from '../../../../../domain/Recurrence/RecurrenceSession';
import RecurrenceSessionGateway from '../../../../../gateway/Recurrence/RecurrenceSessionGateway';
import { setRecurrenceSessionActions } from '../../../../store/component/recurrence';
import Session from '../../../component/Form/Recurrence/Session'

interface IRecurrenceLayoutProps {
  page: string
  mode: "add" | "edit" | "read"
}

const RecurrenceLayout: React.FC<IRecurrenceLayoutProps> = ({
  page,
  mode
}) => {
  const {t} = useTranslation()
  const { sessionType, uuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { refreshRecurrenceSession } = useAppSelector((state) => state.recurrence)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const isSettingTab = MatchUrl.find(location.pathname, 'parametrage')
  const recurrenceRules = useAppSelector((state) => state.me.me?.rules.recurrence.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const [recurrences, setRecurrences] = useState<RecurrenceSessionInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<TError|null>(null)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  useEffect(() => {
    if(recurrenceRules) {
      if(!recurrenceRules?.update && !isLectureMode) navigate(`/${t('url.recurrence.read-setting')}/${sessionType}/${uuid}`)
    }
  }, [recurrenceRules])

  useEffect(() => {
    setIsLoading(true)
    if(uuid) {
      fetchSession(uuid)
      // Load commission action status first render.
      new RecurrenceSessionGateway()
        .getActions(uuid)
        .then(actions => dispatch(setRecurrenceSessionActions(actions)))
    } else {
      setIsLoading(false)
    }
  }, [uuid, refreshRecurrenceSession])

  const fetchSession = (uuid: string) => {
    new RecurrenceSessionGateway()
      .get(uuid)
      .then((res) => {
        const copyResponse = {...res} as RecurrenceSessionInterface

        setRecurrences({...copyResponse})
        setIsLoading(false)
      }).catch((e) => {
      setError(e)
      setIsLoading(false)
    })
  }

  const handleChoosePage = () => {
    if (mode === "add") {
      return <SettingForm isLectureMode={isLectureMode}/>
    }
    if (mode === "edit" && recurrences) {
      if(isSettingTab) {
        return <SettingForm recurrences={recurrences} isLectureMode={isLectureMode}/>
      } else {
        return <Session isLectureMode={isLectureMode}/>
      }
    }
    if (mode === "read" && recurrences) {
      if(isSettingTab) {
        return <SettingForm recurrences={recurrences} isLectureMode={isLectureMode}/>
      } else {
        return <Session isLectureMode={isLectureMode}/>
      }
    }
  }

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage
          title={`${t(`recurrence.session.${uuid ? "edit" : "add"}.title`)} ${(uuid && recurrences?.reference ) ? recurrences?.reference : ""}` }
        />
        <TabRecurrenceAdd mode={mode} uuid={uuid}/>
        {(!isLoading && referential) ? handleChoosePage() : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default RecurrenceLayout
