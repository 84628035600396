import ProductGatewayInterface from '../../../domain/Product/ProductGatewayInterface'
import {ListRequestInterface} from './ListRequest'
import {ProductCommissionSharesInterface} from "../../../domain/Product/CommissionShares"

export default class ListUseCase
{
  public productGateway

  constructor(ProductGateway: ProductGatewayInterface) {
    this.productGateway = ProductGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: ProductCommissionSharesInterface[]|null, numberOfItems: number}|null> {
    return await this.productGateway.getListNumberOfShares(ListRequest.id, ListRequest.currentPage, ListRequest.itemPerPage, signal, sort, order).then(response => response)
  }
}
