import envVariable from "../presentation/util/envVariable"
import encoder from "../presentation/util/Encoder"

interface OAuthInterface {
  token_type: string
  expires_in: number
  access_token: string
  refresh_token: string
  expires_at: number
}

export default class Authentication {
  static urlToken = envVariable('REACT_APP_OAUTH_ACCESS_TOKEN_URL')
  static callbackUrl = envVariable('REACT_APP_OAUTH_CALLBACK_URL')
  static clientId = envVariable('REACT_APP_OAUTH_CLIENT_ID')
  static localStorageTokenName = envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME')
  static codeVerifier = localStorage.getItem('code_verifier') ?? ''
  static urlAuthorisationServer = envVariable('REACT_APP_OAUTH_AUTH_URL')

  static getOAuthData(): OAuthInterface | null {
    const oauth = localStorage.getItem(Authentication.localStorageTokenName)
    return oauth ? JSON.parse(oauth) : null
  }

  static getToken(): string {
    const oauth = Authentication.getOAuthData()
    return oauth ? `${oauth.token_type} ${oauth.access_token}` : ''
  }

  static async authorize(): Promise<boolean> {
    try {
      const codeVerifier = Authentication.generateCodeVerifier()
      const codeChallenge = await encoder.generateCodeChallengeFromVerifier(codeVerifier)

      localStorage.setItem('code_challenge', codeChallenge)

      const urlToRedirect = `${Authentication.urlAuthorisationServer}?response_type=code&state=&client_id=${Authentication.clientId}&scope=&redirect_uri=${Authentication.callbackUrl}&code_challenge=${codeChallenge}&code_challenge_method=S256`
      window.location.href = urlToRedirect
      return false
    } catch (error) {
      console.error('Authorization error:', error)
      this.logout()
      return false
    }
  }

  static async login(code: string | null): Promise<boolean> {
    if (!code) return false

    const options = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: Authentication.clientId,
      redirect_uri: Authentication.callbackUrl,
      code,
      code_verifier: Authentication.codeVerifier,
    })

    try {
      const response = await Authentication.fetchToken(options)
      if (response?.access_token) {
        Authentication.storeOAuthData(response)
        return true
      }
      return false
    } catch (error) {
      console.error('Login error:', error)
      return false
    }
  }

  static async isLogged(): Promise<boolean> {
    const oauth = Authentication.getOAuthData()
    if (oauth?.access_token) {
      if (Date.now() > oauth.expires_at) {
        return await Authentication.refreshToken()
      }
      return true
    }
    return false
  }

  static async refreshToken(): Promise<boolean> {
    const oauth = Authentication.getOAuthData()
    if (!oauth?.refresh_token) return false

    const options = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: Authentication.clientId,
      redirect_uri: Authentication.callbackUrl,
      client_secret: Authentication.codeVerifier,
      refresh_token: oauth.refresh_token,
    })

    try {
      const response = await Authentication.fetchToken(options)
      if (response?.access_token) {
        Authentication.storeOAuthData(response)
        return true
      } else {
        Authentication.handleLogout()
        return false
      }
    } catch (error) {
      console.error('Refresh token error:', error)
      Authentication.handleLogout()
      return false
    }
  }

  static logout(): boolean {
    Authentication.handleLogout()
    return true
  }

  private static generateCodeVerifier(): string {
    const array = new Uint32Array(28)
    window.crypto.getRandomValues(array)
    const codeVerifier = Array.from(array, encoder.dec2hex).join('')
    localStorage.setItem('code_verifier', codeVerifier)
    return codeVerifier
  }

  private static storeOAuthData(response: any): void {
    response.expires_at = Date.now() + response.expires_in * 1000
    localStorage.setItem(Authentication.localStorageTokenName, JSON.stringify(response))
  }

  private static async fetchToken(options: URLSearchParams): Promise<any> {
    const response = await fetch(Authentication.urlToken, {
      method: 'POST',
      body: options,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded charset=utf-8',
      },
    })
    return response.json()
  }

  private static handleLogout(): void {
    localStorage.removeItem(Authentication.localStorageTokenName)
    window.location.replace(envVariable('REACT_APP_OAUTH_LOGOUT_URL'))
  }
}
