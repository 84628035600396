import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Validate: FunctionComponent<Props> = ({color}) => {
    return (
      <svg fill={color} height="21px" width="21px" version="1.1"
           xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve">
        <g>
        <path d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M9.5,18.3l-5.6-5.7l1.8-1.8l3.9,3.9l8.4-8.4l1.8,1.8
        L9.5,18.3z"/>
        </g>
      </svg>
    )
}

export default Validate
