import {Prospect, ProspectInterface} from '../../../domain/Prospect/Prospect'
import InitializePerson from './InitializePerson'

export default class InitializeProspect {
  initializeProspect(): ProspectInterface {
    return new Prospect(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      null,
      null,
      [],
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }

  initializeProspectMoral(): ProspectInterface {
    return new Prospect(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [(new InitializePerson).initializePersonMoral()],
      [],
      [],
      [],
      [],
      [],
      [],
      null,
      null,
      [],
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
