import RecurrenceSessionGatewayInterface from '../../../domain/Recurrence/RecurrenceSessionGatewayInterface';
import { RecurrenceSessionListInterface } from '../../../domain/Recurrence/RecurrenceSessionList';
import {ListRequestInterface} from './ListRequest'


export default class ListUseCase
{
  public recurrenceSessionGateway

  constructor(recurrenceSessionGateway: RecurrenceSessionGatewayInterface) {
    this.recurrenceSessionGateway = recurrenceSessionGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: RecurrenceSessionListInterface[]|null, nb_element: number}|null> {
    return await this.recurrenceSessionGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order).then(response => response)
  }
}
