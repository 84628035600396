import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { addLegalRepresentatives, updateLegalRepresentatives } from '../../../../store/component/prospect';
import blockScrollBody from '../../../util/BlockScroll';
import getClassForOverlay from '../../../util/Sidebar';
import { setOpenProspectLegalRepresentativesForm } from '../../../../store/component/event';
import { ReferentielInterface, } from '../../../../../domain/Referentiel/ReferentielInterface';
import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';
import { Person } from '../../../../../domain/Prospect/Person';
import { City } from '../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import CityDatalist from '../../Datalist/City/CityDatalist';
import CountryDatalist from '../../Datalist/Country/CountryDatalist';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { toastSuccess } from '../../../util/Toast';
import { usePrompt } from '../../../util/Navigation';
import { ProspectLegalRepresentatives } from '../../../../../domain/Prospect/ProspectLegalRepresentatives';
import SelectCustom from '../../Elements/Select';
import InputDate from '../../Elements/InputDate';
import CityAndPostcode from '../../Datalist/CityAndPostcode/CityAndPostcode';
import {Address} from "../../../../../domain/Address/Address";
import Input from '../../Elements/Input'
import CheckboxToggle from '../../Elements/CheckboxToggle'

interface IFormInput {
  id: string
  personId: string
  title: string|null
  status: string|null
  lastName: string|null
  maidenName: string|null
  firstName: string|null
  birthdate: string|null
  birthCountry: Country|null
  birthCity: City|null
  nationality: Country|null
  addressId: string
  addressFirstLine: string|null
  addressSecondLine: string|null
  addressThirdLine: string|null
  zipcode: string|null
  city: City|null
  country: Country|null
  ppe: boolean|null
  ppeCategory: string|null
}

type Props = {
  referential: ReferentielInterface
}

const STATUS_ACTIVE = 'active';

const AddLegalRepresentatives: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const openProspectLegalRepresentativesForm = useAppSelector((state) => state.event.openProspectLegalRepresentativesForm)

  useEffect(() => {
    blockScrollBody(openProspectLegalRepresentativesForm.show)

    if (openProspectLegalRepresentativesForm.legalRepresentatives) {
      setValue('id', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.id || '')
      setValue('personId', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.id || '')
      setValue('title', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.title || null)
      setValue('status', openProspectLegalRepresentativesForm.legalRepresentatives.status)
      setValue('lastName', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.lastname || null)
      setValue('maidenName', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.maidenName || null)
      setValue('firstName', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.firstname || null)
      setValue('birthdate', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.birthdate || null)
      // setValue('birthCountry', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.birthCountry || null)
      setValue('birthCity', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.birthCity || null)
      // setValue('nationality', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.nationality || null)
      setValue('addressId', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.id || '')
      setValue('addressFirstLine', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.address || null)
      setValue('addressSecondLine', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.address2 || null)
      setValue('addressThirdLine', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.address3 || null)
      setValue('zipcode', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.postcode || null)
      setValue('city', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.city || null)
      setValue('country', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.country || null)
      setValue('ppe', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.ppe || null)
      setValue('ppeCategory', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.ppeCategory || null)

    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('title', null)
      setValue('status', STATUS_ACTIVE)
      setValue('lastName', null)
      setValue('maidenName', null)
      setValue('firstName', null)
      setValue('birthdate', null)
      // setValue('birthCountry', null)
      setValue('birthCity', null)
      // setValue('nationality', null)
      setValue('addressId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('addressFirstLine', null)
      setValue('addressSecondLine', null)
      setValue('addressThirdLine', null)
      setValue('zipcode', null)
      setValue('city', null)
      setValue('country', null)
      setValue('ppe', null)
      setValue('ppeCategory', null)
    }
  }, [openProspectLegalRepresentativesForm])

  useEffect(() => {
    if (countryByDefault && openProspectLegalRepresentativesForm) {
      setValue('birthCountry', openProspectLegalRepresentativesForm.legalRepresentatives?.person?.birthCountry || countryByDefault[0]);
      setValue('nationality',  openProspectLegalRepresentativesForm.legalRepresentatives?.person?.nationality || countryByDefault[0]);
      setValue('country',  openProspectLegalRepresentativesForm.legalRepresentatives?.person?.address?.country || countryByDefault[0]);
    }
  }, [countryByDefault, openProspectLegalRepresentativesForm])

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const watchCivility = watch('title');
  const watchPpe = watch('ppe');

  useEffect(() => {
    if (!watchPpe) {
      setValue('ppeCategory', '');
    }
  }, [watchPpe])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const legalRepresentatives = new ProspectLegalRepresentatives(
      data.id,
      data.status,
      openProspectLegalRepresentativesForm?.legalRepresentatives?.code ?? null,
      new Person(
        data.personId,
        null, null, null, null, null,
        data.title,
        data.firstName,
        data.lastName,
        data.maidenName,
        data.birthdate,
        data.birthCity, null, //TODO ADD
        data.birthCountry,
        data.nationality,
        data.ppe,
        data.ppeCategory,
        null, null,
        null, null,
        null, null,
        null, null,
        null, null,
        null, null, null,
          new Address(
              data.addressId,
              null,
              null,
              null,
              data.title || '',
              data.firstName  || '',
              data.lastName  || '',
              data.maidenName  || '',
              data.addressFirstLine  || '',
              data.addressSecondLine  || '',
              data.addressThirdLine  || '',
              data.zipcode  || '',
              data.city  || null,
              data.country  || null,
              false,
              null,
            null
          ),
        null, null,
    openProspectLegalRepresentativesForm?.legalRepresentatives?.person?.code || null
      )
    )

    if (openProspectLegalRepresentativesForm.legalRepresentatives) {
      dispatch(updateLegalRepresentatives(legalRepresentatives))
    } else {
      dispatch(addLegalRepresentatives(legalRepresentatives))
    }

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    setValue('addressId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('prospect.notify.add-legal-representatives-success'))
    handleClose()
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenProspectLegalRepresentativesForm({show: false, legalRepresentatives: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openProspectLegalRepresentativesForm.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openProspectLegalRepresentativesForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
          <div className="sidebar__content">
            <div className="title">{t('prospect.form.legal-representatives.add')}</div>
              <div className="form-bloc__form flex-container">
                <SelectCustom
                  classes="col-md-12"
                  id="status"
                  name="status"
                  options={referential.prospect.legal_representative_status}
                  register={register}
                  label={t('prospect.form.legal-representatives.status')}
                  noChoiceOption={true}
                />
                <SelectCustom
                  classes="col-md-12"
                  id="title"
                  name="title"
                  options={referential.global.title_physic}
                  register={register}
                  label={t('prospect.form.legal-representatives.civility')}
                  noChoiceOption={true}
                />
                <Input id={`lastName`}
                       name={`lastName`}
                       label={t('prospect.form.legal-representatives.lastname')}
                       register={register}
                       classes={`col-md-6`}
                       required={true}
                />
                {watchCivility === 'mrs' &&
                  <Input id={`maidenName`}
                         name={`maidenName`}
                         label={t('prospect.form.legal-representatives.maiden-name')}
                         register={register}
                         classes={`col-md-12`}
                  />
                }
                <Input id={`firstName`}
                       name={`firstName`}
                       label={t('prospect.form.legal-representatives.firstname')}
                       register={register}
                       classes={`col-md-6`}
                       required={true}
                />
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="birthdate"
                           className="form-control__label">{t('prospect.form.legal-representatives.date-of-birth')}</label>
                    <div className="form-control__input">
                      <InputDate id="birthdate"
                                 name="birthdate"
                                 register={register}
                                 control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="birthCountry"
                           className="form-control__label">{t('prospect.form.legal-representatives.country-of-birth')}</label>
                     <CountryDatalist control={control} name="birthCountry" defaultValue={getValues('birthCountry')}/>
                  </div>
                </div>
                <div className="col-md-12">
                  <CityDatalist control={control}
                                setValue={setValue}
                                name="birthCity"
                                defaultValue={getValues('birthCity')}
                                labelContent={t('prospect.form.legal-representatives.city-of-birth')}
                                country={getValues('birthCountry')}
                                classes="flex"
                                mode="col"
                  />
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="nationality"
                           className="form-control__label">{t('prospect.form.legal-representatives.nationality')}</label>
                    <div className="form-control__input">
                      <CountryDatalist control={control} name="nationality" defaultValue={getValues('nationality')} />
                    </div>
                  </div>
                </div>
                <Input id={`addressFirstLine`}
                       name={`addressFirstLine`}
                       label={t('prospect.form.legal-representatives.address-first-line')}
                       register={register}
                       classes={`col-md-12`}
                />
                <Input id={`addressSecondLine`}
                       name={`addressSecondLine`}
                       label={t('prospect.form.legal-representatives.address-second-line')}
                       register={register}
                       classes={`col-md-12`}
                />
                <Input id={`addressThirdLine`}
                       name={`addressThirdLine`}
                       label={t('prospect.form.legal-representatives.address-third-line')}
                       register={register}
                       classes={`col-md-12`}
                />
                <CityAndPostcode control={control}
                                 nameCity="city"
                                 namePostcode="zipcode"
                                 country={getValues('country')}
                                 register={register}
                                 setValue={setValue}
                                 getValues={getValues}
                                 mode="col"
                                 classes="col-md-12 u-px0 u-mb0"
                />
                <div className="col-md-12">
                    <div className="form-control">
                        <label htmlFor="country"
                               className="form-control__label">{t('prospect.form.legal-representatives.country')}</label>
                        <div className="form-control__input">
                            <CountryDatalist control={control} name="country" defaultValue={getValues('country')}/>
                        </div>
                    </div>
                </div>
                <CheckboxToggle id={`ppe`}
                                name={`ppe`}
                                classes="col-md-6"
                                register={register}
                                label={t('partner.form.beneficial-beneficiaries.ppe')}
                />
                {watchPpe &&
                  <div className="col-md-6">
                    <div className="form-control--label-max-content">
                        <SelectCustom classes="" id="ppeCategory" name="ppeCategory" options={referential.prospect.ppe_category} register={register} label={t('account.form.manager.managerPpeCategory')} />
                    </div>
                  </div>
                }
              </div>
            </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddLegalRepresentatives
