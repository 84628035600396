import {CommunicationInterface} from './Communication'
import {FiscalityInterface} from './Fiscality'
import {City} from "../Referentiel/City/City";
import {Country} from "../Referentiel/Country/Country";
import {DocumentInterface} from "../Document/Document";
import {AddressInterface} from "../Address/Address";

interface PersonInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  type: string|null
  gerant: boolean|null
  title: string|null
  firstname: string|null
  lastname: string|null
  maidenName: string|null
  birthdate: string|null
  birthCity: City|null
  birthCityForeign: string|null
  birthCountry: Country|null
  nationality: Country|null
  ppe: boolean|null
  ppeCategory: string|null
  legalCapacity: string|null
  familySituation: string|null
  matrimonialRegime: string|null
  email: string|null
  phone: string|null
  extranet: boolean|null
  extranetCreatedAt: string|null
  optinMarketing: CommunicationInterface|null
  optinDividend: CommunicationInterface|null
  optinFiscality: CommunicationInterface|null
  optinGeneralAssembly: CommunicationInterface|null
  optinInvitation: CommunicationInterface|null
  optinAll: CommunicationInterface|null
  address: AddressInterface|null
  fiscality: FiscalityInterface|null
  documents: DocumentInterface[]|null
  code: string|null
}

class Person implements PersonInterface{
  constructor(
    public id: string|null = null,
    public createdAt: string|null = null,
    public updatedAt: string|null = null,
    public deletedAt: string|null = null,
    public type: string|null = null,
    public gerant: boolean|null = null,
    public title: string|null = null,
    public firstname: string|null = null,
    public lastname: string|null = null,
    public maidenName: string|null = null,
    public birthdate: string|null = null,
    public birthCity: City|null = null,
    public birthCityForeign: string|null = null,
    public birthCountry: Country|null = null,
    public nationality: Country|null = null,
    public ppe: boolean|null = null,
    public ppeCategory: string|null = null,
    public legalCapacity: string|null = null,
    public familySituation: string|null = null,
    public matrimonialRegime: string|null = null,
    public email: string|null = null,
    public phone: string|null = null,
    public extranet: boolean|null = null,
    public extranetCreatedAt: string|null = null,
    public optinMarketing: CommunicationInterface|null = null,
    public optinDividend: CommunicationInterface|null = null,
    public optinFiscality: CommunicationInterface|null = null,
    public optinGeneralAssembly: CommunicationInterface|null = null,
    public optinInvitation: CommunicationInterface|null = null,
    public optinAll: CommunicationInterface|null = null,
    public address: AddressInterface|null = null,
    public fiscality: FiscalityInterface|null = null,
    public documents: DocumentInterface[]|null = null,
    public code: string|null = null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.type = type
    this.gerant = gerant
    this.title = title
    this.firstname = firstname
    this.lastname = lastname
    this.maidenName = maidenName
    this.birthdate = birthdate
    this.birthCity = birthCity
    this.birthCityForeign = birthCityForeign
    this.birthCountry = birthCountry
    this.nationality = nationality
    this.ppe = ppe
    this.ppeCategory = ppeCategory
    this.legalCapacity = legalCapacity
    this.familySituation = familySituation
    this.matrimonialRegime = matrimonialRegime
    this.email = email
    this.phone = phone
    this.extranet = extranet
    this.extranetCreatedAt = extranetCreatedAt
    this.optinMarketing = optinMarketing
    this.optinDividend = optinDividend
    this.optinFiscality = optinFiscality
    this.optinGeneralAssembly = optinGeneralAssembly
    this.optinInvitation = optinInvitation
    this.optinAll = optinAll
    this.address = address
    this.fiscality = fiscality
    this.documents = documents
    this.code = code
  }
}

export {
  Person
}
export type { PersonInterface }
