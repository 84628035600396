import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

type Props = {
  disabled: boolean
  onCancelUrl?: string
}
const FooterBlock: FunctionComponent<Props> = ({disabled, onCancelUrl = null}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2" disabled={disabled}>{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline"
        onClick={() => {onCancelUrl && navigate(`/${onCancelUrl}`)}}
      >{t('common.cancel')}</button>
    </footer>
  )
}
export default FooterBlock
