import {ICommissionCheckListDetail} from "../../presentation/component/Card/Comissioning/CommissionCheckList";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CommissioningSessionInterface} from "../../../domain/Commissioning/CommissioningList";
import {ActionListInterface} from "../../../domain/Commissioning/CommissioningSessionAction";

interface ICommissioning {
  refreshCommission: string;
  isSelectedAllControl: boolean;
  controlLists: ICommissionCheckListDetail[],
  actions: ActionListInterface | null
}

const initialState: ICommissioning = {
  refreshCommission: '',
  isSelectedAllControl: false,
  controlLists: [],
  actions: null
}

export const commissionSlice = createSlice({
  name: 'commission',
  initialState: initialState,
  reducers: {
    setRefreshCommission: (state, action: PayloadAction<string>) => {
      state.refreshCommission = action.payload
    },
    setIsSelectedAllControl: (state, action: PayloadAction<boolean>) => {
      state.isSelectedAllControl = action.payload
    },
    setCommissionControlLists: (state, action: PayloadAction<ICommissionCheckListDetail[]>) => {
      state.controlLists = action.payload
    },
    setCommissionActions: (state, action: PayloadAction<ActionListInterface | null>) => {
      state.actions = action.payload;
    }
  }
})

export const {
  setRefreshCommission,
  setIsSelectedAllControl,
  setCommissionControlLists,
  setCommissionActions
} = commissionSlice.actions
export default commissionSlice.reducer
