import React, {FunctionComponent, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'

import InputDate from '../../../../Elements/InputDate'
import CountryDatalist from '../../../../Datalist/Country/CountryDatalist'
import CityDatalist from '../../../../Datalist/City/CityDatalist'
import CheckboxToggle from '../../../../Elements/CheckboxToggle'
import SelectCustom from '../../../../Elements/Select'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import Input from '../../../../Elements/Input'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'

type Props = {
  customer: CustomerInterface
  referential: ReferentielInterface
  isLectureMode: boolean | undefined
  setFormErrors: React.Dispatch<React.SetStateAction<string[]>>
  indexPerson: number
  handler?: (customer: CustomerInterface) => void
}

const PersonForm: FunctionComponent<Props> = ({customer, referential, isLectureMode, setFormErrors, indexPerson, handler}) => {
  const { t } = useTranslation()
  const { register, control, watch, getValues, setValue } = useFormContext()

  const watchPerson = watch('persons')
  const watchPersonFirstname = watchPerson?.[indexPerson]?.firstname
  const watchPersonLastname = watchPerson?.[indexPerson]?.lastname
  const watchPersonBirthdate = watchPerson?.[indexPerson]?.birthDate
  const watchPersonBirthCity = watchPerson?.[indexPerson]?.birthCity
  const watchPersonNationality = watchPerson?.[indexPerson]?.nationality
  const watchPersonPpe = watchPerson?.[indexPerson]?.ppe

  const checkValidity = useCallback(() => {
    setFormErrors((prevErrors) => {
      const newErrors = [...prevErrors]
      const errorKeys = {
        lastname: 'account.form.general-information.personLastname',
        firstname: 'account.form.general-information.personFirstname',
        birthDate: 'account.form.general-information.personBirthdate',
        nationality: 'account.form.general-information.personNationality',
        birthCity: 'account.form.general-information.personBirthCity'
      }

      Object.keys(errorKeys).forEach((key) => {
        const errorKey = errorKeys[key as keyof typeof errorKeys]
        const hasError = newErrors.includes(errorKey)
        const watchValue = watchPerson?.[indexPerson]?.[key]

        if (!watchValue && !hasError) {
          newErrors.push(errorKey)
        } else if (watchValue && hasError) {
          const index = newErrors.indexOf(errorKey)
          if (index > -1) {
            newErrors.splice(index, 1)
          }
        }
      })

      return newErrors
    })
  }, [watchPersonFirstname, watchPersonLastname, watchPersonBirthdate, watchPersonBirthCity, watchPersonNationality, indexPerson, setFormErrors])

  useEffect(() => {
    checkValidity()
  }, [checkValidity])

  useEffect(() => {
    if (!watchPersonPpe) {
      setValue(`persons.${indexPerson}.ppeCategory`, '')
    }
  }, [watchPersonPpe])

  const watchPersonLegalCapacity = watchPerson?.[indexPerson]?.legalCapacity

  useEffect(() => {
    if(customer && customer.persons) {
      customer.persons[indexPerson].legalCapacity = getValues(`persons.${indexPerson}.legalCapacity`)
      if (handler) {
        handler(customer)
      }
    }
  }, [watchPersonLegalCapacity])

  return (
    <div className="col-md-6">
      <div className="form-bloc__form flex-container">
        <div className="col-md-12">
          {t('account.form.person')} {indexPerson + 1}
        </div>
        <SelectCustom classes="col-md-6" id={`persons.${indexPerson}.title`}
                      name={`persons.${indexPerson}.title`}
                      label={t('account.form.general-information.personTitle')}
                      options={referential.global.title_physic}
                      register={register}
        />
        <div className="col-md-12">
          <Input id={`persons.${indexPerson}.lastname`}
                 name={`persons.${indexPerson}.lastname`}
                 label={t('account.form.general-information.personLastname')}
                 register={register}
                 inputClasses={!watchPersonLastname ? 'field-in-error' : ''}
                 required={true}
          />
        </div>
        <div className="col-md-12">
          <Input id={`persons.${indexPerson}.firstname`}
                 name={`persons.${indexPerson}.firstname`}
                 label={t('account.form.general-information.personFirstname')}
                 register={register}
                 inputClasses={!watchPersonFirstname ? 'field-in-error' : ''}
                 required={true}
          />
        </div>
        <div className="col-md-12">
          <Input type="text"
                 id={`persons.${indexPerson}.maidenName`}
                 name={`persons.${indexPerson}.maidenName`}
                 label={t('account.form.general-information.personMaidenname')}
                 register={register}
          />
        </div>
        <div className="col-md-6">
          <div className="form-control">
            <label htmlFor="personBirthdate"
                   className="form-control__label mendatory-field">{t('account.form.general-information.personBirthdate')}</label>
            <div className="form-control__input">
              <InputDate id="personBirthdate"
                         name={`persons.${indexPerson}.birthDate`}
                         classes={!watchPersonBirthdate ? 'field-in-error' : ''}
                         control={control}
                         register={register}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-control">
            <label htmlFor="personNationality"
                   className="form-control__label mendatory-field">{t('account.form.general-information.personNationality')}</label>
            <div className="form-control__input">
              <CountryDatalist control={control} isOnError={!watchPersonNationality}
                               name={`persons.${indexPerson}.nationality`}
                               defaultValue={getValues(`persons.${indexPerson}.nationality`)} disabled={isLectureMode}/>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-control">
            <label htmlFor="personBirthCountry"
                   className="form-control__label">{t('account.form.general-information.personBirthCountry')}</label>
            <div className="form-control__input">
              <CountryDatalist control={control} name={`persons.${indexPerson}.birthCountry`}
                               defaultValue={getValues(`persons.${indexPerson}.birthCountry`)}
                               disabled={isLectureMode}/>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <CityDatalist control={control}
                        setValue={setValue}
                        labelContent={t('account.form.general-information.personBirthCity')}
                        name={`persons.${indexPerson}.birthCity`}
                        defaultValue={getValues(`persons.${indexPerson}.birthCity`)}
                        country={watchPerson?.[indexPerson].birthCountry}
                        classes={!watchPersonBirthCity ? 'field-in-error' : ''}
                        mode="row"
                        disabled={isLectureMode}
          />
        </div>
        <SelectCustom classes="col-md-6" id={`persons.${indexPerson}.familySituation`}
                      name={`persons.${indexPerson}.familySituation`}
                      label={t('account.form.general-information.personFamilySituation')}
                      options={referential.global.family}
                      register={register}
        />
        <SelectCustom classes="col-md-6" id={`persons.${indexPerson}.matrimonialRegime`}
                      name={`persons.${indexPerson}.matrimonialRegime`}
                      label={t('account.form.general-information.personMatrimonialRegime')}
                      options={referential.global.matrimonial_regime}
                      register={register}
        />
        <SelectCustom classes="col-md-12" id={`persons.${indexPerson}.legalCapacity`}
                      name={`persons.${indexPerson}.legalCapacity`}
                      label={t('account.form.general-information.personLegalCapacity')}
                      options={referential.prospect.legal_capacity}
                      register={register}
        />
        <div className="col-md-6">
          <CheckboxToggle id={"personPpe"} name={`persons.${indexPerson}.ppe`} register={register}
                          label={t('account.form.general-information.personPpe')}/>
        </div>
        <div className="col-md-6">
          {watchPersonPpe &&
            <SelectCustom classes="" containerClasses="" id="personPpeCategory"
                          name={`persons.${indexPerson}.ppeCategory`}
                          options={referential.customer.ppe_category} register={register}
                          label={t('account.form.manager.managerPpeCategory')}/>
          }
        </div>
      </div>
    </div>
  )
}

export default PersonForm
