import React, {FunctionComponent, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/product'
import {ListRequest} from '../../../../useCase/CommissioningSession/List/ListRequest'
import ListUseCase from '../../../../useCase/CommissioningSession/List/ListUseCase'
import Pagination from '../Pagination/Pagination'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import MultiSelectCustom from "../Elements/MultiSelect";
import {
  ReferentielInterface
} from '../../../../domain/Referentiel/ReferentielInterface'
import TableHead from "../Table/TableHead";
import {CallerType} from "../../../../domain/Caller/Caller";
import CommissioningSessionListPresenter from "../../../../presenter/Commissioning/CommissioningSessionListpresenter";
import {CommissioningSessionItemInterface} from "../../../../domain/Commissioning/CommissioningSessionItemInterface";
import {setOpenDatalistFilterCommissioningSession} from "../../../store/component/event";
import CommissioningSessionGateway from "../../../../gateway/Commissioning/CommissioningSessionGateway";
import {
  CommissioningSessionListInterface,
  FilterCommissioningSessionInterface
} from "../../../../domain/Commissioning/CommissioningSessionList";
import {CommissioningSessionInterface} from "../../../../domain/Commissioning/CommissioningList";
import ListFilter from "../../../../useCase/CommissioningSession/List/ListFilter";
import {CommissioningSessionFilters} from "../../../../domain/Commissioning/CommissioningSessionFilters";
import InputDate from "../Elements/InputDate";
import NumberFormat from "../../../../domain/Utils/NumberFormat";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../Modal/ConfirmationModal";
import {handleCloseToast, toastError, toastProcess, toastSuccess} from "../../util/Toast";
import {ActionList, ActionListInterface} from "../../../../domain/Commissioning/CommissioningSessionAction";
import dayjs from "dayjs";
import {setCommissionActions} from "../../../store/component/commission";
import {ACTIONS} from "../Form/Commissioning/ActionsForm";
import downloadBlobFile from '../../util/DownloadBlobFile'

interface ViewModelInterface {
  title: string
  heading: []
  data: CommissioningSessionItemInterface[] | null
  session: CommissioningSessionInterface | null
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  sessionId: string
  callerType?: CallerType,
}

const CommissioningSessionDatalist: FunctionComponent<Props> = ({sessionId, callerType}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const commissioningRules = useAppSelector((state) => state.me.me?.rules.commissioning.actions)
  const referential: ReferentielInterface | null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterCommissioningSession = useAppSelector((state) => state.event.openDatalistFilterCommissioningSession)
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [checkedMovementIds, setCheckedMovementIds] = useState<string[]>([]);
  const [uncheckedMovementIds, setUncheckedMovementIds] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(true);
  const [filters, setFilters] = useState<CommissioningSessionFilters|null>(null);
  const commissionActions = useAppSelector((state) => state.commission.actions);

  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const {register, handleSubmit, setValue, watch, control} = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if (!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterCommissioningSession.filters, sessionId)
      const session = new ListUseCase(new CommissioningSessionGateway())
        .execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder, callerType)
        .then(response => {
          return response
        });

      const presenter = new CommissioningSessionListPresenter(session);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })

      setValue('products', openDatalistFilterCommissioningSession.filters.products)
      setValue('investors', openDatalistFilterCommissioningSession.filters.investors)
      setValue('partners', openDatalistFilterCommissioningSession.filters.partners)
      setValue('levels', openDatalistFilterCommissioningSession.filters.levels)
      setValue('scales', openDatalistFilterCommissioningSession.filters.scales)
      setValue('date', openDatalistFilterCommissioningSession.filters.date)

      const filters = new ListFilter(new CommissioningSessionGateway())
        .execute(sessionId)
        .then(response => setFilters(response))
    }

  }, [currentPage, openDatalistFilterCommissioningSession.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterCommissioningSession.filters])

  useEffect(() => {
    if (viewModel?.data) {
      setCheckedItems([
        ...checkedItems,
        ...getMovementCheckedIds.filter(id => !checkedItems.includes(id))
      ])
    }
  }, [viewModel])

  useEffect(() => {
    if (getMovementIds.filter(id => checkedItems.includes(id)).length === getMovementIds.length) {
      setIsCheckedAll(true)
    } else {
      setIsCheckedAll(false)
    }
  }, [checkedItems])

  const getMovementIds = useMemo(() => {
    if (viewModel?.data) {
      return [...Array.from(new Set(viewModel.data.map(item => item.movementId)))]
    }

    return [];
  }, [viewModel])

  const getMovementCheckedIds = useMemo(() => {
    if (viewModel?.data) {
      return [...Array.from(new Set(viewModel.data.filter(item => item.isChecked).map(item => item.movementId)))]
    }

    return [];
  }, [viewModel])

  const onSubmit: SubmitHandler<FilterCommissioningSessionInterface> = data => {
    setClearMultiSelectValues(false)
    dispatch(setOpenDatalistFilterCommissioningSession({
      show: false,
      count: openDatalistFilterCommissioningSession.count,
      filters: {
        products: data.products,
        investors: data.investors,
        partners: data.partners,
        levels: data.levels,
        scales: data.scales,
        date: data.date
      }
    }))
  }

  const paginate = (pageNumber: number) => {
    controller.abort();
    setCurrentPage(pageNumber)
  }

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterCommissioningSession({
        show: true,
        count: openDatalistFilterCommissioningSession.count,
        filters: openDatalistFilterCommissioningSession.filters
      }))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(true)
    dispatch(setOpenDatalistFilterCommissioningSession({
      show: false,
      count: 0,
      filters: {
        products: '',
        investors: '',
        partners: '',
        levels: '',
        scales: '',
        date: '',
      }
    }))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new CommissioningSessionGateway().getExportAsBlob(sessionId, openDatalistFilterCommissioningSession.filters).then(response => {
      if (response) {
        downloadBlobFile(response, t('export.commissionings-sessions'))
        setLoadingExport(false)
      }
    })
  }

  const toggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setIsCheckedAll(checked)

    if (checked) {
      setCheckedItems([
        ...getMovementIds
      ])

      setCheckedMovementIds([
        ...checkedMovementIds,
        ...getMovementIds.filter(id => !getMovementCheckedIds.includes(id) && !checkedMovementIds.includes(id))
      ])

      setUncheckedMovementIds([
        ...uncheckedMovementIds.filter(id => !getMovementIds.includes(id))
      ])
    } else {
      if (viewModel?.data) {
        setCheckedItems([])
        setCheckedMovementIds([
          ...checkedMovementIds.filter(id => !getMovementIds.includes(id))
        ])
        setUncheckedMovementIds([
          ...uncheckedMovementIds,
          ...getMovementIds.filter(id => getMovementCheckedIds.includes(id) && !uncheckedMovementIds.includes(id))
        ])
      }
    }
  }

  const handleConfirmSelect = (checked: boolean, item: CommissioningSessionItemInterface) => {
    if (!checked) {
      confirmAlert({
        customUI: ({onClose}) => {
          return (<ConfirmationModal
            message={t('commissioning.confirm.unchecked-movement')}
            onConfirm={() => handleSelect(checked, item)}
            onClose={onClose}
            optionStyles={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                transform: 'translate(-50%, -50%)',
                width: '30%'
              },
            }}
          />);
        },
      });

      return;
    }

    handleSelect(checked, item);
  }

  const handleSelect = (checked: boolean, item: CommissioningSessionItemInterface) => {
    if (checked && !checkedItems.includes(item.movementId)) {
      if (checkedItems)

      setCheckedItems([
        ...checkedItems,
        item.movementId
      ])

      if (uncheckedMovementIds.includes(item.movementId)) {
        setUncheckedMovementIds([
          ...uncheckedMovementIds.filter(id => id !== item.movementId)
        ])
      }

      if (!getMovementCheckedIds.includes(item.movementId)) {
        setCheckedMovementIds([
          ...checkedMovementIds,
          item.movementId
        ])
      }
    }

    if (!checked) {
      if (checkedItems.includes(item.movementId)) {
        setCheckedItems([
          ...checkedItems.filter(id => id !== item.movementId)
        ])
      }

      if (checkedMovementIds.includes(item.movementId)) {
        setCheckedMovementIds([
          ...checkedMovementIds.filter(id => id !== item.movementId)
        ])
      }

      if (getMovementCheckedIds.includes(item.movementId)) {
        setUncheckedMovementIds([
          ...uncheckedMovementIds,
          item.movementId
        ])
      }
    }
  }

  const handleConfirmValidateAction = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (<ConfirmationModal onConfirm={() => handleClickValidateAction()} onClose={ onClose }/>);
      },
    });
  }

  const handleClickValidateAction = () => {
    const toastId = toastProcess('commissioning.notify.movement-checked-process');
    new CommissioningSessionGateway().updateCommissionSessionMovementChecked(
      sessionId,
      checkedMovementIds,
      uncheckedMovementIds
    ).then(response => {
      if (response) {
        toastSuccess(t('commissioning.notify.movement-checked-success'))
        triggerVerifyAction()
        return;
      }

      toastError(t('commissioning.notify.movement-checked-error'))
    })
    .catch(error => {
      toastError(t('commissioning.notify.movement-checked-error'))
    })
    .finally(() => {
      handleCloseToast(toastId)
    })
  }

  const triggerVerifyAction = () => {
    if (commissionActions?.verify?.date) {
      return;
    }

    const toastId = toastProcess(t('commissioning.notify.calculate-process'))
    const date = dayjs(new Date()).format('DD/MM/YYYY')
    const action = ACTIONS.VERIFY;
    const reason = null;

    new CommissioningSessionGateway().setAction(sessionId, action, date, reason).then((response: ActionList | any) => {
      if (response) {
        dispatch(setCommissionActions(response))
        toastSuccess(t('commissioning.notify.update-success'))
        return;
      }
      toastError(t('commissioning.notify.update-error'))
    }).catch((e) => {
      toastError(t('commissioning.notify.update-error'))
    }).finally(() => {
      handleCloseToast(toastId)
    })
  }

  return (<>
      {(viewModel !== null &&
          <>
              <div className={`datalist`}>
                  <div className="datalist__title">{t(viewModel.title)}</div>
                  <div className="col-md-12 datalist__filter commissioning-filter">
                      <form onSubmit={handleSubmit(onSubmit)} className="flex-container">
                          <div className="col-md-4">
                              <MultiSelectCustom
                                  id="products"
                                  name="products"
                                  classes=""
                                  control={control}
                                  label={t("commissioning-session.filters.by-product-label")}
                                  placeholder={t("commissioning-session.filters.by-product-label")}
                                  options={filters?.products || []}
                                  customOnChange={(options) => {
                                    setValue('products', options)
                                  }}
                                  defaultValue={undefined}
                                  clearValues={clearMultiSelectValues}
                              />
                          </div>
                          <div className="col-md-4">
                              <MultiSelectCustom
                                  id="partners"
                                  name="partners"
                                  classes=""
                                  control={control}
                                  label={t("commissioning-session.filters.by-partner-label")}
                                  placeholder={t("commissioning-session.filters.by-partner")}
                                  options={filters?.partners || []}
                                  customOnChange={(options) => {
                                    setValue('partners', options)
                                  }}
                                  defaultValue={undefined}
                                  clearValues={clearMultiSelectValues}
                              />
                          </div>
                          <div className="col-md-4">
                              <MultiSelectCustom
                                  id="levels"
                                  name="levels"
                                  classes=""
                                  control={control}
                                  label={t("commissioning-session.filters.by-level-label")}
                                  placeholder={t("commissioning-session.filters.by-level")}
                                  options={filters?.levels || []}
                                  customOnChange={(options) => {
                                    setValue('level', options)
                                  }}
                                  defaultValue={undefined}
                                  clearValues={clearMultiSelectValues}
                              />
                          </div>
                          <div className="col-md-4">
                              <MultiSelectCustom
                                  id="scales"
                                  name="scale"
                                  classes=""
                                  control={control}
                                  label={t("commissioning-session.filters.by-scale-label")}
                                  placeholder={t("commissioning-session.filters.by-scale")}
                                  options={filters?.scales || []}
                                  customOnChange={(options) => {
                                    setValue('scales', options)
                                  }}
                                  defaultValue={undefined}
                                  clearValues={clearMultiSelectValues}
                              />
                          </div>
                          <div className="col-md-4">
                              <MultiSelectCustom
                                  id="investors"
                                  name="investors"
                                  classes=""
                                  control={control}
                                  label={t("commissioning-session.filters.by-investor-label")}
                                  placeholder={t("commissioning-session.filters.by-investor")}
                                  options={filters?.investors || []}
                                  customOnChange={(options) => {
                                    setValue('investors', options)
                                  }}
                                  defaultValue={undefined}
                                  clearValues={clearMultiSelectValues}
                              />
                          </div>
                          <div className="col-md-4">
                              <div className="form-control form-control--center">
                                  <label className="form-control__label">
                                    {t("commissioning-session.filters.by-date-label")}
                                  </label>
                                  <InputDate register={register}
                                             control={control}
                                             type={'text'}
                                             name={'date'}
                                             id={'date'}
                                  />
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="datalist__header session-filters flex items-center justify-end">
                                  <div className="filter__actions">
                                      <button type="submit"
                                              className="button button--submit">{t('search.submit')}</button>
                                      <button type="button" className="button button--white"
                                              onClick={() => resetFilters()}>{t('search.cancel')}</button>
                                      <button type="button" className="button button--white"
                                              onClick={() => handleClickExport()}>{t('common.export-xls')}</button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
                  <div className="table-fix-head">
                      <table className="datalist__datas" id="commissionnement-session">
                          <thead>
                          <tr>
                              <th colSpan={17}>
                                {
                                  commissionActions && commissionActions?.control?.date && (
                                    <button
                                      className="button button--submit"
                                      onClick={handleConfirmValidateAction}
                                    >
                                      {t('commissioning.validate-selection')}
                                    </button>
                                  )
                                }
                              </th>
                              <th colSpan={7} className={"u-txt-center ceil-variant-light-blue"}>Niveau 1</th>
                              <th colSpan={7} className={"u-txt-center ceil-variant-light-orange"}>Niveau 2</th>
                              <th colSpan={7} className={"u-txt-center ceil-variant-light-blue "}>Niveau 3</th>
                              <th colSpan={7} className={"u-txt-center ceil-variant-light-orange"}>Niveau 4</th>
                          </tr>
                          {viewModel.heading && <TableHead
                              typeFilter={"API"}
                              heading={viewModel.heading}
                              sortOrder={sortOrder}
                              setSortOrder={setSortOrder}
                              viewModel={viewModel}
                              setViewModel={setViewModel}
                              filter={openDatalistFilterCommissioningSession.filters}
                              watchNumberRows={watchNumberRows}
                              currentPage={currentPage}
                              listRequest={ListRequest}
                              listUseCase={ListUseCase}
                              listPresenter={CommissioningSessionListPresenter}
                              gateway={CommissioningSessionGateway}
                              investorId={sessionId}
                              showSelector={!!(commissionActions && commissionActions?.control?.date)}
                              onSelectorChange={toggleSelectAll}
                              selectorValue={isCheckedAll}
                          />}
                          </thead>
                          <tbody>
                          {viewModel.data?.map((item: CommissioningSessionItemInterface) => (
                            <tr key={uuidV4()}>
                              {item !== null && item !== undefined &&
                                  <>
                                      {
                                        commissionActions && commissionActions?.control?.date && (
                                          <td>
                                            <input
                                              type={"checkbox"}
                                              checked={checkedItems.includes(item.movementId)}
                                              onChange={(e) => handleConfirmSelect(e.target.checked, item)}
                                            />
                                          </td>
                                        )
                                      }
                                      <td className="action-col">
                                        {commissioningRules?.read &&
                                            <Link to={`/${t('url.pre-subscription.read')}/${item.transactionId}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                            >
                                                <button type="button" className="button-reset">
                                                    <img src={iconSearch} alt=""/>
                                                </button>
                                            </Link>}
                                        {commissioningRules?.update &&
                                            <Link to={`/${t('url.pre-subscription.edit')}/${item.transactionId}`}>
                                                <button type="button" className="button-reset">
                                                    <img src={iconEdit} alt=""/>
                                                </button>
                                            </Link>}
                                      </td>
                                    {/* Object.entries(item).map((obj) => {
                                      if (obj[0] !== 'id' && obj[0] !== 'subproduct') {
                                        return <td key={uuidV4()}>{obj[1]}</td>
                                      }
                                    }) */}
                                      <td>{item.productAcronyme}</td>
                                      <td>{item.executedAt}</td>
                                      <td>{item.transactionCode}</td>
                                      <td>{item?.state || '-'}</td>
                                      <td>{item.transactionType}</td>
                                      <td>{item.propertyType}</td>
                                      <td>{item.investor.code}</td>
                                      <td>{`${item.investor.firstname} ${item.investor.lastname}` + (null !== item.investor.socialReason ? (' / ' + `${item.investor.socialReason}`) : '')}</td>
                                      <td>{item.partnerCode}</td>
                                      <td>{item.partnerLabel}</td>
                                      <td className="align-right">{item.shareCount}</td>
                                      <td className="align-right">{item.sharePrice}</td>
                                      <td className="align-right">{item.dismembermentKey}</td>
                                      <td className="align-right">{NumberFormat.currencyFormat(item.totalAmount)}</td>
                                      <td className="align-right">{NumberFormat.currencyFormat(item?.baseAmount || 0)}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].code}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].label}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].scaleCategoryLabel}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["1"].rate}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["1"].ht}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["1"].tva}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["1"].ttc}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].code}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].label}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].scaleCategoryLabel}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["2"].rate}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["2"].ht}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["2"].tva}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["2"].ttc}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].code}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].label}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].scaleCategoryLabel}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["3"].rate}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["3"].ht}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["3"].tva}</td>
                                      <td className="ceil-variant-light-blue align-right">{item.partnerLevel["3"].ttc}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].code}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].label}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].scaleCategoryLabel}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["4"].rate}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["4"].ht}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["4"].tva}</td>
                                      <td className="ceil-variant-light-orange align-right">{item.partnerLevel["4"].ttc}</td>
                                  </>
                              }
                            </tr>
                          ))}
                          {viewModel.data?.length === 0 &&
                              <tr>
                                  <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                              </tr>
                          }
                          </tbody>
                      </table>
                  </div>
              </div>
              <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
                          numberOfItems={viewModel.pagination.numberOfItems} callback={paginate}/>

              <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                            name="numberRows"
                            label={t('filters.display-results-by')}
                            options={optionsNbRows}
                            register={register}
                            noChoiceOption
              />
          </>
      )}
    </>
  )
}

export default CommissioningSessionDatalist
