import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenAccountRelationFormEvent} from '../../../../store/component/event'
import {
  addRelation,
  setCurrentRelationSelect,
  updateRelation
} from '../../../../store/component/customer'
import {Relation, RelationInterface} from '../../../../../domain/Prospect/Relation'
import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import ProspectDatalistForAddRelation from '../../Datalist/ProspectDatalistForAddRelation'
import SelectCustom from '../../Elements/Select'
import {ReferentielInterface} from '../../../../../domain/Referentiel/ReferentielInterface'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastSuccess} from "../../../util/Toast";
import {usePrompt} from "../../../util/Navigation";
import Input from '../../Elements/Input'

interface IFormInput {
  relation: RelationInterface
}

type Props = {
  referential: ReferentielInterface
}

const AddRelationForm: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openRelationForm = useAppSelector((state) => state.event.openAccountRelationForm)
  const currentRelationSelect = useAppSelector((state) => state.prospect.currentRelationSelected)
  const [personToLink, setPersonToLink] = useState<Relation|null>(null)

  useEffect(() => {
    if (currentRelationSelect !== null) {
      setPersonToLink(currentRelationSelect)
      setValue('relation.personId', currentRelationSelect.personId)
      setValue('relation.lastName', currentRelationSelect.lastName)
      setValue('relation.firstName', currentRelationSelect.firstName)
    }
  }, [currentRelationSelect])

  useEffect(() => {
    blockScrollBody(openRelationForm.show)

    if (openRelationForm.relation) {
      setValue('relation.id', openRelationForm.relation.id)
      setValue('relation.personId', openRelationForm.relation.personId)
      setValue('relation.lastName', openRelationForm.relation.lastName)
      setValue('relation.firstName', openRelationForm.relation.firstName)
      setValue('relation.relation', openRelationForm.relation.relation)
      setPersonToLink(openRelationForm.relation)
    } else {
      setValue('relation.id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setPersonToLink(null)
    }
  }, [openRelationForm])

  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const relation = new Relation(
      data.relation.id,
      data.relation.personId,
      data.relation.lastName,
      data.relation.firstName,
      data.relation.relation,
    )

    if (openRelationForm.relation) {
      dispatch(updateRelation(relation))
      dispatch(setCurrentRelationSelect(null))
    } else {
      dispatch(addRelation(relation))
      dispatch(setCurrentRelationSelect(null))
    }

    reset()
    setValue('relation.id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    dispatch(setOpenAccountRelationFormEvent({show: false, relation: null}))

    toastSuccess(t('account.notify.add-relation-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenAccountRelationFormEvent({show: false, relation: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openRelationForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openRelationForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--prospect-relation-form">
        <div className="sidebar__content">
          <div className="title">{t('account.form.relation.add')}</div>
          {!personToLink &&
            <div className="flex-container">
              <ProspectDatalistForAddRelation />
            </div>
          }
          {personToLink &&
            <div className="flex-container">
              <Input id={`relation.personId`}
                     name={`relation.personId`}
                     label={t('account.form.relation.personId')}
                     register={register}
                     classes={`col-md-12`}
                     disabled={true}
              />
              <Input id={`relation.lastName`}
                     name={`relation.lastName`}
                     label={t('account.form.relation.lastName')}
                     register={register}
                     classes={`col-md-6`}
                     disabled={true}
              />
              <Input id={`relation.firstName`}
                     name={`relation.firstName`}
                     label={t('account.form.relation.firstName')}
                     register={register}
                     classes={`col-md-6`}
                     disabled={true}
              />
              <SelectCustom classes="col-md-12" label={t('account.form.relation.relation')} options={referential.global.relationship || null}  id="relation.relation"  name="relation.relation" register={register}/>
            </div>
          }
      </div>
        <footer className="sidebar__footer">
        <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
        <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
      </footer>
        </form>
    </div>
    </>
  );
}

export default AddRelationForm
