import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import '../../../../assets/styles/components/_bloc-information-shortcut.scss';
import '../../../../assets/styles/components/_modal.scss';
import imgConfirm from '../../../../assets/images/icons/confirm.svg';

type Props = {
  onConfirm: (data: any) => void;
  onClose: () => void;
  message?: string;
  optionStyles?: Modal.Styles;
};

const ConfirmationModalWithReason: FunctionComponent<Props> = ({ onConfirm, onClose, message, optionStyles }) => {
  Modal.setAppElement('#root');

  const { t } = useTranslation();
  const [userMessage, setUserMessage] = useState(message || '');

  const onClick = () => {
    onConfirm(userMessage);
    onClose();
  };

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 1000,
    },
  };

  const modalStyles = useMemo(() => {
    if (optionStyles) {
      return {
        ...styles,
        ...optionStyles,
      };
    }
    return styles;
  }, [optionStyles]);

  return (
    <>
      <Modal isOpen={true} style={modalStyles}>
        <div className="content">
          <div className="content__image">
            <img src={imgConfirm} alt=""/>
          </div>
          <p className="content__text u-pbb">
            {message ? message : t('common.modal.confirmation-message')}
          </p>
          <div className="form-control__input u-mbm">
            <textarea
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder={t('common.modal.enter-message')}
            />
          </div>
            <div className="content__buttons">
              <button
                type="button"
                className="button button--ink-2 u-mrm"
                onClick={onClick}
              >
                {t('common.save')}
              </button>
              <button
                type="button"
                className="button button--ink-2 button--ink-2--outline"
                onClick={onClose}
              >
                {t('common.cancel')}
              </button>
            </div>
          </div>
      </Modal>
    </>
);
};

export default ConfirmationModalWithReason;
