import React, {FunctionComponent, MouseEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabSettings from "../../../component/Tab/Settings/TabSettings";
import {SubmitHandler, useForm} from "react-hook-form";
import GetUseCase from "../../../../../useCase/Settings/Get/GetUseCase";
import GeneralSettingsGateway
    from "../../../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway";
import UpdateUseCase from "../../../../../useCase/Settings/Update/UpdateUseCase";
import Upload from "../../../component/Sidebar/Settings/Upload";
import {toastError, toastSuccess} from '../../../util/Toast'
import {SettingsInterface} from "../../../../../domain/Settings/GeneralSettings/Settings";
import InitializeSettings from "../../../../../useCase/Settings/Initialization/InitializeSettings";
import AddCityForm from '../../../component/Sidebar/Referentiel/AddCityForm';


interface IFormInput {
    commissionPdfFormat: string|null,
    commissionCsvFormat: string|null
}

const CommissionSettings: FunctionComponent = () => {
    const {t} = useTranslation()

    const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
    const [generalSettings, setGeneralSettings] = useState<SettingsInterface | null>(null)

    useEffect(() => {
        new GetUseCase(new GeneralSettingsGateway()).execute().then(
            response => {
                if (response !== null) {
                    setGeneralSettings(response)
                }
            }
        );

    }, [])


    const {register, handleSubmit, setValue} = useForm<IFormInput>();

    useEffect(() => {
        if (generalSettings) {
            setValue('commissionPdfFormat', generalSettings.commissionPdfFormat || 'default')
            setValue('commissionCsvFormat', generalSettings.commissionCsvFormat || 'default')
        }
    }, [generalSettings])

    const onSubmit: SubmitHandler<IFormInput> = data => {
        let settingsToUpdate;

        if (generalSettings) {
            settingsToUpdate = generalSettings
        } else {
            settingsToUpdate = new InitializeSettings().initializeSettings()
        }

        settingsToUpdate.commissionPdfFormat = data.commissionPdfFormat
        settingsToUpdate.commissionCsvFormat = data.commissionCsvFormat

        new UpdateUseCase(new GeneralSettingsGateway()).execute(settingsToUpdate).then(response => {
                if (null !== response) {
                    toastSuccess(t('settings.notify.update-success'))
                } else {
                    toastError(t('settings.notify.update-error'))
                }
            }
        );
    }

    return (
        <>
            <AddCityForm/>
            {<Upload/>}
            <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
                <HeaderPage title={t('settings.title')}/>
                <TabSettings/>

                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bloc-select flex-container">
                            <label className="col-md-2 form-control__label u-mrxl" htmlFor="roles-select">
                                {t('settings.commission.form.pdfFormat')}
                            </label>
                            <div>
                                <select id="roles-select" {...register('commissionPdfFormat')}>
                                    <option value="default">{t('settings.commission.form.default')}</option>
                                    <option value="altarea">{t('settings.commission.form.modeAltarea')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="bloc-select u-mbm flex-container">
                            <label className="col-md-2 form-control__label u-mrxl" htmlFor="roles-select">
                                {t('settings.commission.form.csvFormat')}
                            </label>
                            <div>
                                <select id="roles-select" {...register('commissionCsvFormat')}>
                                    <option value="default">{t('settings.commission.form.default')}</option>
                                    <option value="altarea">{t('settings.commission.form.modeAltarea')}</option>
                                </select>
                            </div>
                        </div>
                        <footer className={`form-bloc__footer`}>
                            <button type="submit"
                                    className="button button--ink-2">{t('common.save')}</button>
                            <button type="button"
                                    className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
                        </footer>
                    </form>
                </>

            </main>
        </>
    )
}

export default CommissionSettings
