import React, {FunctionComponent} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"

import {useAppSelector} from '../../../store/hook'
import HeaderPage from '../../component/HeaderPage/HeaderPage'
import SessionDatalist from '../../component/Datalist/Distribution/SessionDatalist'

const Dashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage
          title={t('distribution.list-sessions')}
          addLink={`/${t('url.distribution.session.add')}`}
          tooltipLink={`${t('distribution.session.add.title')}`}
        />
        <SessionDatalist />
      </main>
    </>
  )
}

export default Dashboard
