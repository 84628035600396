import React, {FunctionComponent, useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import Form from '../../../component/Form/PreSubscription/Form/Form'
import GetUseCase from '../../../../../useCase/PreSubscription/Get/GetUseCase'
import PreSubscriptionGateway from '../../../../../gateway/PreSubscription/PreSubscriptionGateway'
import {PreSubscriptionInterface} from '../../../../../domain/PreSubscription/PreSubscription'
import InitializePreSubscription from '../../../../../useCase/PreSubscription/Initialization/InitializePreSubscription'
import ChoiceInvestor from '../../../component/Sidebar/Movement/ChoiceInvestor'
import { setOpenDatalistFilterPartner, setOpenUploadForm } from '../../../../store/component/event';
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import Upload from "../../../component/Upload/Upload";
import DocumentGateway from "../../../../../gateway/PreSubscription/Document/DocumentGateway";
import ChoicePartner from "../../../component/Sidebar/Prospect/ChoicePartner";
import DocumentList from "../../../component/Document/DocumentList";
import {TError} from "../../../../../domain/Error/Error";
import ErrorPage from "../../Error/ErrorPage";
import Spinner from "../../../component/Spinner/Spinner";
import envVariable from '../../../util/envVariable';

const AddOrEdit: FunctionComponent = () => {
  const {t} = useTranslation()
  const { uuid } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const subscriptionRules = useAppSelector((state) => state.me.me?.rules.subscription.actions)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const reloadMovement = useAppSelector((state) => state.movement.reloadMovement)
  const [preSubscription, setPresSubscription] = useState<PreSubscriptionInterface|null>(null)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const [error, setError] = useState<TError|null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [type, setType] = useState<string>('')

  useEffect(() => {
    if(subscriptionRules) {
      if(!subscriptionRules?.update && !isLectureMode) navigate(`/${t('url.pre-subscription.read')}/${uuid}`)
    }
  }, [subscriptionRules])

  useEffect(() => {
    dispatch(setOpenDatalistFilterPartner({show: false, count: 0, filters: {
        idPartner: '',
        keywords: '',
        socialReason: '',
        name: '',
        city: '',
        product:  {
          id: '',
          value: '',
          label: ''
        },
        status: [],
        type: [],
        platform: [],
        network: [],
        commissionLevel: ""
      }}))
  }, [])

  useEffect(() => {
    if (uuid !== undefined) {
      new GetUseCase(new PreSubscriptionGateway()).execute(uuid).then(response => {
        if (response !== null) {
          setPresSubscription(response)
          const closedStatus = ["cancelled", "validated", "subscribed"]
          if(response?.status) {
            if (closedStatus.includes(response.status)
              || ("confirmed" === response.status && envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT'))
            ) {
              navigate(`/${t('url.pre-subscription.read')}/${response.id}`)
            }
          }
          setIsLoading(false)
        }
      }).catch((e) => {
        setIsLoading(false)
        setError(e)
      })
    } else {
      setPresSubscription(new InitializePreSubscription().initializePreSubscription)
      setIsLoading(false)
    }
  }, [uuid, reloadMovement])

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      <DocumentList />
      {!isLectureMode && <ChoicePartner/>}
      {!isLectureMode && <ChoiceInvestor/>}
      {preSubscription && <Upload openUploadForm={openUploadForm} gateway={new DocumentGateway(preSubscription.id ?? null, null)} callback={setOpenUploadForm}/>}
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} movement`}>
        <HeaderPage title={t('movement.pre-subscription.title')}/>
        {preSubscription && !isLoading ? <Form preSubscription={preSubscription} isLectureMode={isLectureMode}/> : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default AddOrEdit
