import {FunctionComponent} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

interface Props {
  mode: "add" | "edit" | "read"
  uuid: string | undefined
}

const TabDistribution: FunctionComponent<Props> = ({mode, uuid}) => {
  const {t} = useTranslation()
  const {sessionType} = useParams()

  return (
    <div className={`tab`}>
      <NavLink
        to={`/${t(`url.distribution.session.${mode}-setting`)}/${sessionType}${uuid ? "/" + uuid : ""}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('distribution.session.tab-setting')}
      </NavLink>
      {uuid && (
      <NavLink
        to={`/${t(`url.distribution.session.${mode}-investor-list`)}/${sessionType}${uuid ? "/" + uuid : ""}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('distribution.session.tab-investor')}
      </NavLink>
      )}
    </div>
  )
}

export default TabDistribution
