import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {ProductListInterface, SessionInterface} from '../../../../../../domain/Distribution'
import {TFieldErrors} from '../types'
import ProductItem from './ProductItem'
import FieldErrorMessage from '../../../FieldErrorMessage/FieldErrorMessage'


type TProps = {
  productList: ProductListInterface[]|null
  uuid?: string
  session?: SessionInterface|null
  isLectureMode?: boolean
  fieldErrors: TFieldErrors
}

const ProductList: FunctionComponent<TProps> = ({productList, uuid, session, isLectureMode, fieldErrors}) => {
  const {t} = useTranslation()

  if (!productList) {
    return <></>
  }

  return (
    <div className={uuid ? 'col-md-6' : 'col-md-12'}>
      <div className="form-bloc">
        <div className="flex form-bloc__title justify-between items-center">
          <span>{t('distribution.form.setting.session.products')}</span>
        </div>
        <div className="form-bloc__form flex-container table-fix-head">
          <table className={`datalist__datas`}>
            <thead>
              <tr>
                <th style={{ width: '1px' }}></th>
                <th>{t('distribution.form.product.thead.label')}</th>
                <th>{t('distribution.form.product.thead.periodicity')}</th>
                <th>{t('distribution.form.product.thead.term')}</th>
                <th>{t('distribution.form.product.thead.iban')}</th>
                <th>{t('distribution.form.product.thead.land')}</th>
                <th>{t('distribution.form.product.thead.financial')}</th>
                <th>{t('distribution.form.product.thead.total-brut')}</th>
                <th>{t('distribution.form.product.thead.nb-part')}</th>
                <th>{t('distribution.form.product.thead.land-per-part-per-period')}</th>
                <th>{t('distribution.form.product.thead.financial-per-part-per-period')}</th>
              </tr>
            </thead>
            <tbody>
            {productList.map((product, index) => (
              <ProductItem key={`distribution-products-${product.id}`} product={product} session={session} isLectureMode={isLectureMode} />
            ))}
            </tbody>
            {fieldErrors.sessionProducts && (
              <tfoot>
                <tr>
                  <td colSpan={11}>
                    <FieldErrorMessage message={fieldErrors.sessionProducts} />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  )
}

export default ProductList
