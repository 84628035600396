import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import DocumentGatewayInterface from '../../../domain/Customer/Document/DocumentGatewayInterface'
import {
  DocumentCustomerInterface,
  DocumentCustomerToSend,
  DocumentCustomerToViewInterface
} from '../../../domain/Customer/Document/Document'
import {
  DocumentProspectInterface,
  DocumentProspectToSend,
  DocumentProspectToViewInterface
} from "../../../domain/Prospect/Document/Document";
import {toastError} from "../../../infrastructure/presentation/util/Toast";
import {t} from "i18next";

export default class DocumentGateway implements DocumentGatewayInterface {
  getFiles(personId: string): Promise<DocumentCustomerInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/${personId}/files`, {})
    .then(data => {
      if (data) {
        return data.data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  getFile(personId: string, fileId: number): Promise<DocumentCustomerToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/${personId}/files/${fileId}`, {})
    .then(data => {
      if (data) {
        return data.content
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  save(personId: string, documents: DocumentCustomerToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/customers/${personId}/files`, {
      files: documents
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  getRibFiles(personId: string, ribId: string): Promise<DocumentCustomerInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/rib/${ribId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      }).catch(error => {
        toastError(t('account.notify.ribs-file-not-found-error'));
      })
  }
  getRibFile(personId: string, ribId: string, fileId: number): Promise<DocumentCustomerInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/rib/${ribId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveRib(personId: string, ribId: string, documents: DocumentCustomerToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/rib/${ribId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getUndividedFiles(personId: string, undividedId: string): Promise<DocumentCustomerInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/undivided/${undividedId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getUndividedFile(personId: string, undividedId: string, fileId: number): Promise<DocumentCustomerInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/undivided/${undividedId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveUndivided(personId: string, undividedId: string, documents: DocumentCustomerToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/undivided/${undividedId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getBeneficialOwnerFiles(personId: string, beneficialOwnerId: string): Promise<DocumentCustomerInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/beneficial-owner/${beneficialOwnerId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getBeneficialOwnerFile(personId: string, beneficialOwnerId: string, fileId: number): Promise<DocumentCustomerInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/beneficial-owner/${beneficialOwnerId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveBeneficialOwner(personId: string, beneficialOwnerId: string, documents: DocumentCustomerToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/beneficial-owner/${beneficialOwnerId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getMoralLegalRepresentativeFiles(personId: string, moralLegalRepresentativeId: string): Promise<DocumentProspectInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/moral-legal-representative/${moralLegalRepresentativeId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getMoralLegalRepresentativeFile(personId: string, moralLegalRepresentativeId: string, fileId: number): Promise<DocumentProspectToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/moral-legal-representative/${moralLegalRepresentativeId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveMoralLegalRepresentative(personId: string, moralLegalRepresentativeId: string, documents: DocumentProspectToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/moral-legal-representative/${moralLegalRepresentativeId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
}
