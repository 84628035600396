import { useState, useEffect, useCallback } from 'react'

import { useAppDispatch } from '../../../store/hook'
import { setCountTotal } from '../../../store/component/distribution'
import { ListRequest } from '../../../../useCase/Distribution/Investor/ListRequest'
import ListUseCase from '../../../../useCase/Distribution/Investor/ListUseCase'
import { ViewModelInterface } from '../../../type'
import { SortInterface } from '../../../../domain/Utils/List'
import { FilterInvestorSheetInterface } from '../../../../domain/Distribution'
import DistributionInvestorGateway from '../../../../gateway/Distribution/InvestorGateway'
import InvestorSheetListPresenter from '../../../../presenter/Distribution/InvestorSheetListPresenter'

interface InvestorDataLoaderProps {
  accountId: string
  currentPage: number
  numberRows: number
  filters: FilterInvestorSheetInterface
  sortOrder: SortInterface
}

export const useInvestorSheetDataLoader = ({
                                        accountId,
                                        currentPage,
                                        numberRows,
                                        filters,
                                        sortOrder,
                                      }: InvestorDataLoaderProps) => {
  const dispatch = useAppDispatch()
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const loadData = useCallback(() => {
    setLoading(true)
    const listRequest = new ListRequest(currentPage, numberRows, filters, accountId)

    new ListUseCase(new DistributionInvestorGateway())
      .execute(listRequest, new AbortController().signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder)
      .then((response) => {
        const presenter = new InvestorSheetListPresenter(response)
        presenter.load().then(() => {
          const newViewModel = presenter.immutableViewModel()
          setViewModel(newViewModel)
          dispatch(setCountTotal(newViewModel.pagination.numberOfItems))
        })
      })
      .finally(() => setLoading(false))
  }, [accountId, currentPage, numberRows, filters, sortOrder, dispatch])

  useEffect(() => {
    loadData()
  }, [loadData])

  return { viewModel, setViewModel, loading }
}
