import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {PersonInterface} from '../../../../../../../domain/Customer/Person'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import PersonForm from './PersonForm'

type Props = {
  customer: CustomerInterface
  referential: ReferentielInterface
  isLectureMode: boolean | undefined
  setFormErrors: React.Dispatch<React.SetStateAction<string[]>>
  handler?: (customer: CustomerInterface) => void
}

const GeneralInformationBlock: FunctionComponent<Props> = ({
   customer,
   referential,
   isLectureMode,
   setFormErrors,
   handler,
}) => {
  const {t} = useTranslation()

  return (
    <div className={`form-bloc form--bloc--general-information`}>
      <div className="form-bloc__title">{t('account.form.general-information.title')}</div>
      <div className="flex-container">
        {customer.persons?.map((person: PersonInterface, index: number) => {
          return person.id &&
            <PersonForm customer={customer}
                        referential={referential}
                        isLectureMode={isLectureMode}
                        indexPerson={index}
                        setFormErrors={setFormErrors}
                        key={`person-form-${index}`}
                        handler={handler}
            />
        })}
      </div>
    </div>
  )
}

export default GeneralInformationBlock;
