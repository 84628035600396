import {Presenter} from '../Presenter'
import {ProspectListInterface} from '../../domain/Prospect/ProspectList'
import {CommissioningListInterface} from "../../domain/Commissioning/CommissioningList";

class CustomerListPresenter extends Presenter {

  private useCase: { getCommissionings: any }

  constructor(getCommissionings: any) {
    super({
      viewModel: {
        title: 'commissioning.search-a-commissioning',
        heading: [
          {name: 'commissioning.heading.action'},
          {name: 'commissioning.heading.product'},
          {name: 'commissioning.heading.type', sort: "type"},
          {name: 'commissioning.heading.session-number', sort: 'session.code'},
          {name: 'commissioning.heading.period', sort: "period"},
          {name: 'commissioning.heading.paymentScheduledAt', sort: "payment_scheduled_at"},
          {name: 'commissioning.heading.criteria', sort: "criteria_type"},
          {name: 'commissioning.heading.session-status', sort: "status"}
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getCommissionings,
    }
  }

  async load() {
    try {
      this._setCommissionings(await this.useCase.getCommissionings);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setCommissionings(commissionings: { data: CommissioningListInterface[]|null, nb_element: number }) {
    this.update({
      data: commissionings.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': commissionings.nb_element,
      }
    });
  }
}

export default CustomerListPresenter
