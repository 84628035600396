import React, { FunctionComponent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { SessionInterface } from '../../../../../../domain/Distribution'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import { useAppSelector } from '../../../../../store/hook'
import { getLabelByValue } from '../../../../util/ReferentialI18n'
import ControlledDatePicker from '../../../Elements/ControlledDatePicker'
import Input from '../../../Elements/Input'
import SelectCustom from '../../../Elements/Select'
import { TFieldErrors } from '../types'

type TProps = {
  session?: SessionInterface | null
  isLectureMode?: boolean
  fieldErrors: TFieldErrors
  sessionType: string
}

const Form: FunctionComponent<TProps> = ({ session, isLectureMode, fieldErrors, sessionType }) => {
  const { t } = useTranslation()

  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)

  const { register, control, setValue, watch } = useFormContext()
  const [isLocked] = useState<boolean>(
    ['validated', 'executed', 'invalid', 'cancelled'].includes(session?.status ?? 'draft')
  )

  const selectedMonth = watch('sessionCriteria.month')
  const selectedYear = watch('sessionCriteria.year')

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const sessionTypeReadable = getLabelByValue(sessionType, referential?.distribution.session_type ?? [])
      const monthName = t(
        `common.month.${
          [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
          ][selectedMonth - 1]
        }`
      )
      const labelValue = t('distribution.session.title', {
        sessionType: sessionTypeReadable,
        monthName: monthName,
        year: selectedYear.getFullYear(),
      })
      setValue('sessionCriteria.label', labelValue)
    }
  }, [selectedMonth, selectedYear, setValue, t, sessionType, referential?.distribution.session_type])

  return (
    <div className={'col-md-6'}>
      <div className='form-bloc'>
        <div className='flex form-bloc__title justify-between items-center'>
          <span>{t('distribution.form.setting.session.session-criteria-title')}</span>
        </div>
        <div className='form-bloc__form flex-container'>
          <Input
            classes={'col-md-12'}
            label={t('distribution.form.setting.session.label')}
            type={'text'}
            id={'sessionCriteria.label'}
            name={'sessionCriteria.label'}
            register={register}
          />
          <SelectCustom
            classes={isLectureMode ? 'col-md-6 form-control__readonly' : 'col-md-6'}
            id='sessionCriteria.month'
            name='sessionCriteria.month'
            options={[
              { value: 1, label: t('common.month.january') },
              { value: 2, label: t('common.month.february') },
              { value: 3, label: t('common.month.march') },
              { value: 4, label: t('common.month.april') },
              { value: 5, label: t('common.month.may') },
              { value: 6, label: t('common.month.june') },
              { value: 7, label: t('common.month.july') },
              { value: 8, label: t('common.month.august') },
              { value: 9, label: t('common.month.september') },
              { value: 10, label: t('common.month.october') },
              { value: 11, label: t('common.month.november') },
              { value: 12, label: t('common.month.december') },
            ]}
            register={register}
            label={t('distribution.form.setting.session.months-treatment')}
            noChoiceOption
            required
            errorMessage={fieldErrors.month}
            readOnly={isLectureMode || isLocked}
          />

          <div className='col-md-6'>
            <div className='form-control'>
              <label htmlFor='sessionCriteria.year' className='form-control__label mandatory-field'>
                {t('distribution.form.setting.session.years-treatment')}
              </label>
              <div className='form-control__input'>
                <ControlledDatePicker
                  control={control}
                  name='sessionCriteria.year'
                  dateFormat='yyyy'
                  dateFormatCalendar='yyyy'
                  minDate={new Date(2022, 1, 1)}
                  maxDate={new Date(`31/12/${new Date().getFullYear() + 10}`)}
                  required
                  customInput={<InputMask mask='9999' maskPlaceholder='____' alwaysShowMask required />}
                  showYearPicker
                  errorMessage={fieldErrors.year}
                  readOnly={isLectureMode || isLocked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form
