import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import imgChoice from '../../../../../assets/images/icons/choice-product.svg'
import '../../../../../assets/styles/page/_choice.scss'
import {ChoiceTypeInterface} from "../../../../../domain/ChoiceType/ChoiceType";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import {Link} from "react-router-dom";

const sessionRecurrenceTypes: ChoiceTypeInterface[] = [
  { label: 'subscription', url: 'url.recurrence.session.add-session' }
]


const ChoiceType: FunctionComponent = () => {
  const {t} = useTranslation()
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  return (
    <>
      <main
        className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--sidebar-info`}>
        <div className="sidebar-info__main">
          <HeaderPage title={t('recurrence.choice-type')} />
          <div className="commissioning-choice__wrapper">
            <p>{t('commissioning.add.select-modele')}</p>
            <div className="commissioning-choice--col-2">
              {sessionRecurrenceTypes.map((sessionRecurrenceType) => {
                return (
                  <Link key={t(sessionRecurrenceType.url).toString()}
                        to={`/${t('url.recurrence.session.add-setting')}/${sessionRecurrenceType.label}`}
                        className={"button button--choice button--choice--outline"}
                  >
                    {t('recurrence.session.'+sessionRecurrenceType.label)}
                  </Link>
                )
              })}
              <a href={"#"}
                 className={"button button--choice button--choice--outline button--disabled"}
              >
                {t('recurrence.session.reinvestment')}
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-info">
          <div className="sidebar-info__text">
            {t('recurrence.choice-type-txt')}
          </div>
          <div>
            <img src={imgChoice} alt="" />
          </div>
        </div>
      </main>
    </>
  )
}

export default ChoiceType
