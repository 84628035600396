interface ApiError {
  code: number
  data?: {
    errors: {
      messages: string[]
    }[]
  }
}

const isApiError = (error: unknown): error is ApiError => {
  return typeof error === 'object' && error !== null && 'code' in error && 'data' in error
}

export {
  isApiError
}

export type {
  ApiError
}
