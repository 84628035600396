import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import Input from "../../../../Elements/Input";
import ControlledDatePicker from "../../../../Elements/ControlledDatePicker";
import InputMask from "react-input-mask";

type TProps = {
  register: any
  control: any
  classes: string
  parentPath: string
  title: string
  subscriptionAtError?: string
  readOnly?: boolean
}

const SessionSetting: FunctionComponent<TProps> = ({register, control, classes, parentPath, title, subscriptionAtError, readOnly}) => {
  const {t} = useTranslation()

  return (
    <div className={classes}>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{title}</div>
        <div className="form-bloc__form flex-container">
            <div className="col-md-4">
              <Input register={register}
                     label={t('recurrence.form.setting.session.sessionNb')}
                     type={'text'}
                     name={`${parentPath || ""}code`}
                     id={`${parentPath || ""}code`}
                     readOnly={true}
                     classes="u-mbs"
              />
            </div>

            <div className="col-md-4">
              <Input register={register}
                     label={t('recurrence.form.setting.session.sessionStatut')}
                     type={'text'}
                     name={`${parentPath || ""}status`}
                     id={`${parentPath || ""}status`}
                     readOnly={true}
                     classes="u-mbs"
              />
            </div>

            <div className="col-md-4">
              <div className="u-mbs">
                <div className="form-control">
                    <div className="flex items-end">
                      <label htmlFor={`${parentPath || ""}.subscriptionAt`}
                           className="form-control__label mandatory-field">{t('recurrence.form.setting.session.subscriptionAt')}</label>
                    </div>
                    <div>
                    <div className="form-control__input">
                        <ControlledDatePicker
                            control={control}
                            name={`${parentPath || ""}.subscriptionAt`}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(2022, 1, 1)}
                            showYearDropdown
                            required={true}
                            customInput={
                                <InputMask
                                    mask="99/99/9999"
                                    maskPlaceholder="__/__/____"
                                    alwaysShowMask={true}
                                    required={true}
                                />
                            }
                            errorMessage={subscriptionAtError}
                            readOnly={readOnly}
                        />
                    </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SessionSetting;