import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenPartnerContactFormEvent} from '../../../../store/component/event'
import {addContact, updateContact} from '../../../../store/component/partner'

import {Contact} from '../../../../../domain/Partner/Contact'

import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import {
  ReferentielInterface
} from '../../../../../domain/Referentiel/ReferentielInterface'
import {Person} from '../../../../../domain/Partner/Person'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastSuccess} from "../../../util/Toast";
import {usePrompt} from "../../../util/Navigation";
import Input from '../../Elements/Input'
import SelectCustom from '../../Elements/Select'

interface IFormInput {
  id: string
  role: string
  personId: string
  manager: boolean;
  title: string
  lastName: string
  maidenName: string
  firstName: string
  mobile: string
  email: string
  status: string
}

type Props = {
  referential: ReferentielInterface
}

const ContactForm: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openPartnerContactForm = useAppSelector((state) => state.event.openPartnerContactForm)

  useEffect(() => {
    blockScrollBody(openPartnerContactForm.show)

    if (openPartnerContactForm.contact) {
      setValue('id', openPartnerContactForm.contact.id)
      setValue('role', openPartnerContactForm.contact.role)
      setValue('personId', openPartnerContactForm.contact?.person?.id || '')
      setValue('manager', openPartnerContactForm.contact?.person?.gerant || false)
      setValue('title', openPartnerContactForm.contact?.person?.title || '')
      setValue('lastName', openPartnerContactForm.contact?.person?.lastname || '')
      setValue('maidenName', openPartnerContactForm.contact?.person?.maidenname || '')
      setValue('firstName', openPartnerContactForm.contact?.person?.firstname || '')
      setValue('mobile', openPartnerContactForm.contact?.person?.phone || '')
      setValue('email', openPartnerContactForm.contact?.person?.email || '')
      setValue('status', openPartnerContactForm.contact?.status || '')
    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('role', '')
      setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('manager', false)
      setValue('title', '')
      setValue('lastName', '')
      setValue('maidenName', '')
      setValue('firstName', '')
      setValue('mobile', '')
      setValue('email', '')
      setValue('status', 'active')
    }
  }, [openPartnerContactForm])

  const { register, control, watch, handleSubmit, reset, setValue } = useForm<IFormInput>();
  const watchCivility = watch('title');

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const contact = new Contact(
      data.id,
      data.role,
      data.status,
      new Person(
        data.personId,
        data.title,
        data.firstName,
        data.lastName,
        data.maidenName,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        data.manager,
        data.email,
        data.mobile
      )
    )

    if (openPartnerContactForm.contact) {
      dispatch(updateContact(contact))
    } else {
      dispatch(addContact(contact))
    }
    dispatch(setOpenPartnerContactFormEvent({show: false, contact: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    toastSuccess(t('partner.notify.add-contact-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenPartnerContactFormEvent({show: false, contact: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPartnerContactForm.show)}`} onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openPartnerContactForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-contact-form">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.bloc-contact.add')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-6">
                <div className="form-control form-control--center">
                  <label htmlFor="manager"
                         className="form-control__label">{t('partner.form.bloc-contact.manager')}</label>
                  <div className="checkbox-toggle__wrapper">
                    <input type="checkbox" {...register('manager')} id="manager"
                           className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default" />
                    <label className="checkbox-toggle__button" htmlFor="manager" />
                  </div>
                </div>
              </div>
              <Input id={`id`}
                     name={`id`}
                     label={t('partner.form.bloc-contact.id')}
                     register={register}
                     classes={`col-md-6`}
                     disabled={true}
              />
              <SelectCustom classes="col-md-6"
                            label={t('partner.form.bloc-contact.civility')}
                            options={referential.global.title_physic || null}
                            id="title"
                            name="title"
                            register={register}
              />
              <Input id={`lastName`}
                     name={`lastName`}
                     label={t('partner.form.bloc-contact.lastname')}
                     register={register}
                     classes={`col-md-6`}
              />
              {(watchCivility === 'mme' || watchCivility === 'mrs' || watchCivility === 'mmes') &&
                <Input id={`maidenName`}
                       name={`maidenName`}
                       label={t('partner.form.bloc-contact.maiden-name')}
                       register={register}
                       classes={`col-md-12`}
                />
              }
              <Input id={`firstName`}
                     name={`firstName`}
                     label={t('partner.form.bloc-contact.firstname')}
                     register={register}
                     classes={`col-md-12`}
              />
              <Input id={`mobile`}
                     name={`mobile`}
                     label={t('partner.form.bloc-contact.mobile-phone')}
                     register={register}
                     classes={`col-md-12`}
                     type={'tel'}
                     validator={'tel'}
              />
              <Input id={`email`}
                     name={`email`}
                     label={t('partner.form.bloc-contact.email')}
                     register={register}
                     classes={`col-md-12`}
                     type={'email'}
                     validator={'email'}
              />
              <SelectCustom classes="col-md-12"
                            label={t('account.form.relation.role')}
                            options={referential.partner.role || null}
                            id="role"
                            name="role"
                            register={register}
              />
              <SelectCustom classes="col-md-12"
                            label={t('partner.form.bloc-contact.status')}
                            options={referential.global.active || null}
                            id="status"
                            name="status"
                            register={register}
              />
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default ContactForm
