import React, {FormEvent, FunctionComponent, InputHTMLAttributes, useCallback, useEffect, useState} from 'react'
import InputMask from "react-input-mask";

import {IFormFieldProps} from "./IFormFieldProps"
import {validators} from './Validation'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string;
  name: string;
  classes?: string;
  labelClasses?: string;
  inputClasses?: string;
  enableValidation: boolean;
}

const Iban: FunctionComponent<IProps> = ({
  id,
  name,
  classes,
  label,
  register,
  error,
  required,
  labelClasses,
  inputClasses,
  enableValidation,
  ...rest
}) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setErrorMsg(null)
    validateIban(value)
  }, [enableValidation, value])

  const validateIban = useCallback((iban: string) => {
    if (!enableValidation || iban === '') {
      setErrorMsg(null)
      return true;
    }

    const validate = validators['iban']

    if (validate) {
      const errorMessage = validate(iban)
      if (errorMessage) {
        setErrorMsg(errorMessage)
        return false
      }

      setErrorMsg(null)
      return true
    }
  }, [enableValidation])

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setValue(newValue)
  }

  return (
    <>
      <div className={ classes ? `${ classes }` : '' }>
        { label &&
          <>
            <div className="form-control">
              <div className="flex items-end">
                <label htmlFor={ id } className={labelClasses ? labelClasses + " form-control__label" : "form-control__label"}>{ label }</label>
              </div>
              <div className="form-control__input">
                <InputMask
                           id={id}
                           required={required}
                           {...register(name, { required: required, validate: validateIban })}
                            onChange={handleChange}
                           mask={enableValidation ? "aa** **** **** **** **** **** **** ****" : "**** **** **** **** **** **** **** ****"}
                           maskPlaceholder={""}
                />
                {errorMsg && <FieldErrorMessage message={errorMsg} />}
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default Iban
