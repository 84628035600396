import {PersonInterface} from './Person'

interface MoralLegalRepresentativeInterface {
  id: string
  status: string|null
  ppe: boolean
  shareHolding: string
  person: PersonInterface|null
}

class MoralLegalRepresentative implements MoralLegalRepresentativeInterface {

  public id: string
  public status: string|null
  public ppe: boolean
  public shareHolding: string
  public person: PersonInterface|null

  constructor(
    id: string,
    status: string|null,
    ppe: boolean,
    shareholding: string,
    person: PersonInterface|null
  ) {
    this.id = id
    this.status = status
    this.ppe = ppe
    this.shareHolding = shareholding
    this.person = person
  }
}

export {
  MoralLegalRepresentative
}
export type { MoralLegalRepresentativeInterface }
