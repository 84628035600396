import {
  CommissioningSessionControlInterface,
  CommissioningSessionInterface
} from '../../domain/Commissioning/CommissioningList'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import {ISettingForm} from "../../domain/Commissioning/setting";
import {CallerType} from "../../domain/Caller/Caller";
import {
  CommissioningSessionListInterface,
  FilterCommissioningSessionInterface
} from "../../domain/Commissioning/CommissioningSessionList";
import CommissioningSessionGatewayInterface from "../../domain/Commissioning/CommissioningSessionGatewayInterface";
import {ActionList} from "../../domain/Commissioning/CommissioningSessionAction";

export default class CommissioningSessionGateway implements CommissioningSessionGatewayInterface {
  getCommissioningSession(id: string): Promise<CommissioningSessionInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${id}`, {})
      .then(data => {
        if (data) {
          return data
        }
      })
  }

  postCommissioningSession(session: ISettingForm, type: string): Promise<CommissioningSessionInterface> {

    let params = {}
    if(session.sessionCriteria.multiCriteria) {
      params = {
        type: type,
        criteriaType: "multi",
        start: session.sessionCriteria.dateStart,
        end: session.sessionCriteria.dateEnd,
        regularisationBoolean: session.sessionCriteria.regularization,
        criteria_products: session.sessionCriteria.products.map((it: any) => it.value),
        criteria_partners: session.sessionCriteria.partners.map((it: any) => it.value),
        paymentScheduledAt: session.session.sessionPayment,
        periodicity: session.sessionCriteria.periodicity
      }
    } else {
      params = {
        type: type,
        criteriaType: "unique",
        criteria_movement: session.sessionCriteria?.criteria_movement,
      }
    }

    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/commission/sessions`, params
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  updateCommissioningSession(session: ISettingForm, type: string, uuid: string): Promise<CommissioningSessionInterface> {
    let params = {}

    if(session.sessionCriteria.multiCriteria) {
      params = {
        type: type,
        criteriaType: "multi",
        start: session.sessionCriteria.dateStart,
        end: session.sessionCriteria.dateEnd,
        regularisationBoolean: session.sessionCriteria.regularization,
        criteria_products: session.sessionCriteria.products.map((it: any) => it.value),
        criteria_partners: session.sessionCriteria.partners.map((it: any) => it.value),
        paymentScheduledAt: session.session.sessionPayment,
        periodicity: session.sessionCriteria.periodicity
      }
    } else {
      params = {
        type: type,
        criteriaType: "unique",
        criteria_movement: session.sessionCriteria?.criteria_movement,
      }
    }

    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}`, {...params}).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  getCommissioningSessionMovement(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterCommissioningSessionInterface|null,
    sessionId: string,
    signal?: AbortSignal,
    sort?: string,
    order?: string,
    callerType?: CallerType,
  ): Promise<{data: CommissioningSessionListInterface[]|null, numberOfItems: number, session: CommissioningSessionInterface}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/movements${callerType === "datalist" ? "-datalist" : ""}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      'products': filter?.products || '',
      'investors': filter?.investors || '',
      'partners': filter?.partners || '',
      'levels': filter?.levels || '',
      'scales': filter?.scales || '',
      'date': filter?.date
      },
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getExtract(sessionId: string, type: string, filter: FilterCommissioningSessionInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/extract/${type}`, {
      'products': filter?.products || '',
      'investors': filter?.investors || '',
      'partners': filter?.partners || '',
      'levels': filter?.levels || '',
      'scales': filter?.scales || '',
      'date': filter?.date || '',
    })
      .then(data => {
        return data
      })
  }

  getExtractAsBlob(sessionId: string, type: string, filter: FilterCommissioningSessionInterface|null): Promise<Blob|null> {
    return Caller.executeGetAsBlob(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/extract/${type}`, {
        'products': filter?.products || '',
        'investors': filter?.investors || '',
        'partners': filter?.partners || '',
        'levels': filter?.levels || '',
        'scales': filter?.scales || '',
        'date': filter?.date || '',
      })
      .then(data => {
        return data
      })
  }

  getExport(sessionId: string, filter: FilterCommissioningSessionInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/extract/datas`, {
      'products': filter?.products || '',
      'investors': filter?.investors || '',
      'partners': filter?.partners || '',
      'levels': filter?.levels || '',
      'scales': filter?.scales || '',
      'date': filter?.date || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      })
  }

  getExportAsBlob(sessionId: string, filter: FilterCommissioningSessionInterface|null): Promise<Blob|null> {
    return Caller.executeGetAsBlob(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/extract/datas`, {
      'products': filter?.products || '',
      'investors': filter?.investors || '',
      'partners': filter?.partners || '',
      'levels': filter?.levels || '',
      'scales': filter?.scales || '',
      'date': filter?.date || '',
    })
      .then(data => {
        if (data instanceof Blob) {
          return data
        }

        return null
      })
  }

  getActions(uuid: string): Promise<ActionList | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}/actions`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string|null): Promise<ActionList | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}/actions/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }

  setRecalculate(uuid: string): Promise<{ sessionId: string } | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}/recalculate`, {})
      .then(data => {
        if (data?.sessionId) {
          return data
        }

        return null
      })
  }

  getFilters(sessionId: string): Promise<any | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/filters`, {})
      .then(data => {
        return data
      })
  }

  updateCommissioningSessionControl(controls: string[], uuid: string): Promise<CommissioningSessionControlInterface | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}/controls`, {
      controls: controls
    }).then((data) => {
      return data
    }).catch(response => {
      return null
    })
  }

  getExportCommissioningSessionControl(controlKeyType: string, uuid: string): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}/extract/${controlKeyType}`, {}).then((data) => {
      if (data && data.content) {
        return data.content;
      }
      return null
    }).catch(error => {
      return null
    })
  }

  updateCommissionSessionMovementChecked(sessionId: string, movementCheckedIds: string[], movementUncheckedIds: string[]): Promise<string | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/movements/checked`, {
      movementCheckedIds: movementCheckedIds,
      movementUncheckedIds: movementUncheckedIds
    }).then((data) => {
      if (data?.uuid) {
        return data.uuid;
      }
      return null
    }).catch(error => {
      return null
    })
  }
}
