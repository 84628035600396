import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/movement'
import {FilterMovementInterface, MovementListInterface} from '../../../../domain/Movement/MovementList'
import {ListRequest} from '../../../../useCase/PreSubscription/List/ListRequest'
import ListUseCase from '../../../../useCase/PreSubscription/List/ListUseCase'
import Pagination from '../Pagination/Pagination'
import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'

import '../../../../assets/styles/components/_datalist.scss'
import PreSubscriptionGateway from "../../../../gateway/PreSubscription/PreSubscriptionGateway";
import iconAdd from '../../../../assets/images/icons/add.svg'
import {setOpenDatalistFilterPreSubscription} from '../../../store/component/event'
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import DismembermentInvestorChoiceListPresenter
  from "../../../../presenter/Movement/DismembermentInvestorChoiceListPresenter";
import {FilterPreSubscriptionInterface} from "../../../../domain/PreSubscription/PreSubscriptionList";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  type: string
  callback: any
}

const WarrantSearchDatalist: FunctionComponent<Props> = ({type, callback}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceInvestor = useAppSelector((state) => state.event.openChoiceInvestor)
  const openDatalistFilterPreSubscription = useAppSelector((state) => state.event.openDatalistFilterPreSubscription)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(()=> {
    if(openChoiceInvestor.show) setValue("numberRows", undefined)
  },[openChoiceInvestor])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const filters = {
        ...openDatalistFilterPreSubscription.filters,
        propertyType: type === "np" ? [{label: "np", value: "np"}] : [{label: "usu", value: "usufruct"}],
        statut: "draft"
      }
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, filters)
      const warrantSearchList = new ListUseCase(new PreSubscriptionGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      });
      const presenter = new DismembermentInvestorChoiceListPresenter(warrantSearchList);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })

      setValue('product', openDatalistFilterPreSubscription.filters.product)
      setValue('transactionType', openDatalistFilterPreSubscription.filters.transactionType)
      setValue('status', openDatalistFilterPreSubscription.filters.status)
      setValue('tags', openDatalistFilterPreSubscription.filters.tags)
      setValue('propertyType', openDatalistFilterPreSubscription.filters.propertyType)
      setValue('paymentMode', openDatalistFilterPreSubscription.filters.paymentMode)
      setValue('user', openDatalistFilterPreSubscription.filters.user)
    }

  }, [currentPage, openDatalistFilterPreSubscription.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterPreSubscription.filters])

  useEffect(() => {
    setCurrentPage(1)
    dispatch(setOpenDatalistFilterPreSubscription({
      show: false,
      count: openDatalistFilterPreSubscription.count,
      filters: {
        product: openDatalistFilterPreSubscription.filters.product,
        transactionType: openDatalistFilterPreSubscription.filters.transactionType,
        status: openDatalistFilterPreSubscription.filters.status,
        tags: openDatalistFilterPreSubscription.filters.tags,
        propertyType: openDatalistFilterPreSubscription.filters.propertyType,
        paymentMode: openDatalistFilterPreSubscription.filters.paymentMode,
        user: openDatalistFilterPreSubscription.filters.user,
        name: openDatalistFilterPreSubscription.filters.name,
        keywords: openDatalistFilterPreSubscription.filters.keywords,
      }
    }))
  }, [type])

  const onSubmit: SubmitHandler<FilterPreSubscriptionInterface> = data => {
    dispatch(setOpenDatalistFilterPreSubscription({
      show: false,
      count: openDatalistFilterPreSubscription.count,
      filters: {
        product: data.product,
        transactionType: data.transactionType,
        status: data.status,
        tags: data.tags,
        propertyType: data.propertyType,
        paymentMode: data.paymentMode,
        user: data.user,
        name: data.name,
        keywords: data.keywords,
      }
    }))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  function selectWarrantSearch(warrantSearchId: string) {
    callback(warrantSearchId)
  }

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header">
              <form onSubmit={handleSubmit(onSubmit)} className="filter">
                <div className="filter__input">
                  {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                    <div key={uuidV4()} className="input-no-border">
                      <img src={iconHeadingSearch} alt="" />
                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                    </div>
                  ))}
                </div>
                <div className="filter__actions">
                  <button type="submit" className="button button--submit">{t('search.submit')}</button>
                  <button type="reset" className="button button--white">{t('search.cancel')}</button>
                </div>
              </form>
              {/*<HeaderRight numberOfActivatedFilters={22} />*/}
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="overflow-auto">
              <table className="datalist__datas">
                <thead>
                <tr>
                  {viewModel.heading.map((title: string) => (
                    <th key={uuidV4()}>{t(title)}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {viewModel.data.map((item: MovementListInterface) => (
                  <tr key={uuidV4()}>
                    {item !== null && item !== undefined &&
                    <>
                      <td>
                        <button type="button" className="button-reset" onClick={() => selectWarrantSearch(Object.entries(item)[0][1])}>
                          <img src={iconAdd} alt="ajouter" />
                        </button>
                        <Link to={`/${t('url.pre-subscription.edit')}/${Object.entries(item)[0][1]}`}>
                          <button type="button" className="button-reset">
                            <img src={iconEdit} alt="éditer" />
                          </button>
                        </Link>
                        <Link to={`/${t('url.pre-subscription.read')}/${item.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                        >
                          <button type="button" className="button-reset">
                            <img src={iconSearch} alt="voir"/>
                          </button>
                        </Link>
                      </td>
                      <td>{item.product_label}</td>
                      <td>{item.transaction_type}</td>
                      <td>{item.code}</td>
                      <td>{item.status}</td>
                      <td>{item.property_type}</td>
                      <td>{item.investor_id}</td>
                      <td>{item.investor_name}</td>
                      <td>{item.partner_code}</td>
                      <td>{item.partner_name}</td>
                      <td>{item.payment_method}</td>
                      <td>{item.payment_date}</td>
                      <td></td>
                      <td>{item.engagedAt}</td>
                      <td>
                        {item.lastUpdateUser?.firstname?.toUpperCase().slice(0, 1)}. {item.lastUpdateUser?.lastname?.toUpperCase()}
                      </td>
                    </>

                    }
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination currentPage={currentPage}
                      itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
                      numberOfItems={viewModel.pagination.numberOfItems}
                      callback={paginate}
          />
        </>
      )}
    </>
  )
}

export default WarrantSearchDatalist
