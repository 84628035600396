import React, {FunctionComponent, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Modal from 'react-modal'
import {useForm, SubmitHandler} from 'react-hook-form'

import '../../../../assets/styles/components/_bloc-information-shortcut.scss'
import '../../../../assets/styles/components/_modal.scss'
import imgConfirm from '../../../../assets/images/icons/confirm.svg'
import ControlledDatePicker from '../Elements/ControlledDatePicker'
import InputMask from 'react-input-mask'
import Input from '../Elements/Input'

type Props = {
  onConfirm: (data: FormValues) => void;
  onClose: () => void;
  mandateId: string;
  optionStyles?: Modal.Styles;
};

type FormValues = {
  rejectCount: number,
  rejectReason: string,
  rejectReference: string
  mandateId: string,
};

const SessionMandatePaymentValidatedModal: FunctionComponent<Props> = ({
                                                                         onConfirm,
                                                                         onClose,
                                                                         mandateId,
                                                                         optionStyles
                                                                       }) => {
  Modal.setAppElement('#root')

  const {t} = useTranslation()
  const {register, handleSubmit, formState: {errors}, control} = useForm<FormValues>()

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    onConfirm({...data, mandateId})
    onClose()
  }

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 1000,
    },
  }

  const modalStyles = useMemo(() => {
    if (optionStyles) {
      return {
        ...styles,
        ...optionStyles,
      }
    }
    return styles
  }, [optionStyles, styles])

  return (
    <Modal isOpen={true} style={modalStyles}>
      <div className="content">
        <div className="content__image">
          <img src={imgConfirm} alt=""/>
        </div>
        <p className="content__text u-pbb">
          {t('recurrence.session-mandate.modal.title-payment-rejected')}
        </p>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-bloc__form flex-container">
            <Input register={register}
                   classes={'col-md-6'}
                   label={t('recurrence.session-mandate.modal.reject-count')}
                   type={'number'}
                   name={'rejectCount'}
                   id={'rejectCount'}
                   required={true}
                   errorMessage={errors.rejectCount?.message}
            />
            <Input register={register}
                   classes={'col-md-6'}
                   label={t('recurrence.session-mandate.modal.reject-reference')}
                   type={'text'}
                   name={'rejectReference'}
                   id={'rejectReference'}
                   required={true}
                   errorMessage={errors.rejectReference?.message}
            />
            <Input register={register}
                   classes={'col-md-6'}
                   label={t('recurrence.session-mandate.modal.reject-reason')}
                   type={'text'}
                   name={'rejectReason'}
                   id={'rejectReason'}
                   required={true}
                   errorMessage={errors.rejectReason?.message}
            />
          </div>
          <div className="content__buttons">
            <button
              type="submit"
              className="button button--ink-2 u-mrm"
            >
              {t('common.save')}
            </button>
            <button
              type="button"
              className="button button--ink-2 button--ink-2--outline"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default SessionMandatePaymentValidatedModal
