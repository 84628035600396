import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {setOpenPartnerBeneficialBeneficiariesFormEvent} from '../../../../../store/component/event'
import {BeneficialBeneficiariesInterface} from '../../../../../../domain/Partner/BeneficialBeneficiaries'
import {PartnerInterface} from '../../../../../../domain/Partner/Partner'
import UpdateUseCase from '../../../../../../useCase/Partner/Update/UpdateUseCase'
import PartnerGateway from '../../../../../../gateway/Partner/PartnerGateway'
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconExtend from '../../../../../../assets/images/icons/expend.svg'
import AddUseCase from '../../../../../../useCase/Partner/Add/AddUseCase'
import {InitializePartner} from '../../../../../../useCase/Partner/Initialization/InitializePartner'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {reloadPartner} from "../../../../../store/component/partner";
import FooterBlock from "../../../Block/FooterBlock";

interface IFormInput {
  kycRequestAt: string
  kycReceivedAt: string
  kycDocumentDate: string
  kycWaitingDocument: string
  kBisRequestAt: string
  kBisReceivedAt: string
  kBisDocumentDate: string
  kBisWaitingDocument: string
  statusRequestAt: string
  statusReceivedAt: string
  statusDocumentDate: string
  statusWaitingDocument: string
  ribRequestAt: string
  ribReceivedAt: string
  ribDocumentDate: string
  ribWaitingDocument: string
  cniManagerRequestAt: string
  cniManagerReceivedAt: string
  cniManagerDocumentDate: string
  cniManagerWaitingDocument: string
  otherRequestAt: string
  otherReceivedAt: string
  otherDocumentDate: string
  otherWaitingDocument: string
  blocking: string
  blockingAt: string
  blockingComment: string
}
type Props = {
  referential: ReferentielInterface
  partner?: PartnerInterface
  isLectureMode?: boolean
}

const BeneficialBeneficiaries: FunctionComponent<Props> = ({partner, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const beneficialBeneficiariesArray = useAppSelector((state) => state.partner.beneficialBeneficiariesArray)
  const [beneficialBeneficiariesList, setBeneficialBeneficiariesList] = useState<BeneficialBeneficiariesInterface[]|[]>(beneficialBeneficiariesArray)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, handleSubmit, reset } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    setIsLoading(true);
    reset(data)
    if (partner) {
      partner.beneficialBeneficiariesList = beneficialBeneficiariesList
      new UpdateUseCase(new PartnerGateway()).execute(partner).then(response => {
        if (null !== response) {
          toastSuccess(t('partner.notify.update-success'))
          dispatch(reloadPartner());
        } else {
          toastError(t('partner.notify.update-error'));
        }
      })
    } else {
      partner = (new InitializePartner()).initializePartner()
      partner.beneficialBeneficiariesList = beneficialBeneficiariesList
      new AddUseCase(new PartnerGateway()).execute(partner).then(uuid => {
        if (null !== uuid) {
          toastSuccess(t('partner.notify.add-success'))
          dispatch(reloadPartner());
        } else {
          toastError(t('partner.notify.add-error'));
        }
        setIsLoading(false);
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  useEffect(() => {
    setBeneficialBeneficiariesList(beneficialBeneficiariesArray)
  }, [beneficialBeneficiariesArray])

  useEffect(() => {
    setIsLoading(false)
  }, [partner])

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.beneficial-beneficiaries.title')}</div>
        <div className="form-bloc__shortcut">
          {!isLectureMode && <button type="button" className="button-reset u-txt-with-icon"
                   onClick={() => dispatch(setOpenPartnerBeneficialBeneficiariesFormEvent({
                     show: true,
                     beneficialBeneficiaries: null
                   }))}>
            <img src={iconAdd} alt=""/>
            {t('partner.form.beneficial-beneficiaries.add')}
          </button>}
        </div>
        {beneficialBeneficiariesList.length > 0 &&
          beneficialBeneficiariesList.map(beneficialBeneficiaries => {
            return <div className="form-bloc__form flex-container" key={uuidV4()}>
              <div className="col-md-12">
                <div className="form-resume">
                  <div className="form-control u-mb0">
                    <label htmlFor="partnerNum" className="form-control__label">{t('partner.form.beneficial-beneficiaries.civility')}</label>
                    <div className="form-control__input">
                      <select disabled>
                        <option value="">{t('common.choice-option')}</option>
                        {referential.global.title_physic.map((item, index) => <option key={index} value={item.value} selected={beneficialBeneficiaries.person?.title === item.value}>{item.label}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="form-control u-mb0">
                    <label htmlFor="partnerNum" className="form-control__label">{t('partner.form.beneficial-beneficiaries.lastname')}</label>
                    <div className="form-control__input">
                      <input type="text" disabled={true} value={`${beneficialBeneficiaries.person?.lastname}`} />
                    </div>
                  </div>
                  <div className="form-control u-mb0">
                    <label htmlFor="legalStatus" className="form-control__label">{t('partner.form.beneficial-beneficiaries.firstname')}</label>
                    <div className="form-control__input">
                      <input type="text" disabled={true} value={`${beneficialBeneficiaries.person?.firstname}`} />
                    </div>
                  </div>
                  <div className="form-resume__expend">
                    <img src={iconExtend} alt="" onClick={() => dispatch(setOpenPartnerBeneficialBeneficiariesFormEvent({show: true, beneficialBeneficiaries: beneficialBeneficiaries}))}/>
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
      {!isLectureMode && <FooterBlock disabled={isLoading} onCancelUrl={t('url.partner.dashboard')} />}
    </form>
  )
}

export default BeneficialBeneficiaries
