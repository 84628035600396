import axios from 'axios';
import envVariable from "../../infrastructure/presentation/util/envVariable";
import Authentication from "../../infrastructure/oauth/Authentication";
import {Link, Navigate} from "react-router-dom";

export default class Caller {

  static initApiUrl(): string {
    return `${envVariable('REACT_APP_API_URL')}`;
  }

  static async executeGet(
    path: string,
    params: Record<string, any>,
    signal?: AbortSignal
  ): Promise<any> {
    const searchParams = new URLSearchParams()
    let paramsString = ''

    if (Object.keys(params).length > 0) {
      Object.keys(params).map(key => {
        if (params[key] === null || params[key] === '') {
            return;
        }

        if (typeof params[key] === 'object') {
          params[key].map(function (item: {label: string, value: string}, index: number) {
            if (typeof item === 'object') {
              searchParams.append(`filters[${key}][${index}]`, item?.value || '')
              return;
            }

            searchParams.append(`filters[${key}][${index}]`, item || '')
          })
        } else {
          searchParams.append(`filters[${key}]`, params[key])
        }
      })

      paramsString = searchParams.toString()
      paramsString = `${path.includes('?') ? '&' : '?'}${paramsString}`;
    }

    return axios.get(`${path}${paramsString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      signal
    })
    .then(response => {
      return response.data;
    })
    .then((result) => {
      return result;
    }).catch((error) => {
      if (401 == error.response.status) {
        return Authentication.refreshToken().then(success => {
          if (success) {
            return this.executeGet(path, params);
          }
        })
      }
      throw {
          message: error.message,
          messageApi: error.response.data.error,
          name: error.name,
          code: error.response.status,
          statusText: error.response.statusText,
          responseURL: error.request.responseURL
        }

    })
  }

  static async executeGetAsBlob(
    path: string,
    params: Record<string, any>,
    signal?: AbortSignal
  ): Promise<any> {
    const searchParams = new URLSearchParams()
    let paramsString = ''

    const blobParam = 'raw=1&t=' + Date.now()
    const url = `${path}${path.includes('?') ? '&' : '?'}${blobParam}`;

    if (Object.keys(params).length > 0) {
      Object.keys(params).map(key => {
        if (params[key] !== null && params[key] !== '') {
          if (typeof params[key] === 'object') {
            params[key].map(function (item: {label: string, value: string}, index: number) {
              searchParams.append(`filters[${key}][${index}]`, item?.value || '')
            })
          } else {
            searchParams.append(`filters[${key}]`, params[key])
          }
        }
      })

      paramsString = searchParams.toString()
      paramsString = `${url.includes('?') ? '&' : '?'}${paramsString}`;
    }

    return axios.get(`${url}${paramsString}`, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      signal
    })
    .then(response => {
      return response.data;
    })
    .then((result) => {
      return result;
    }).catch((error) => {
      if (401 == error.response.status) {
        return Authentication.refreshToken().then(success => {
          if (success) {
            return this.executeGetAsBlob(path, params, signal);
          }
        })
      }
      throw {
        message: error.message,
        messageApi: error.response.data.error,
        name: error.name,
        code: error.response.status,
        statusText: error.response.statusText,
        responseURL: error.request.responseURL,
        blob: error.response.data
      }
    })
  }

  static async executePost(
    path: string,
    params: unknown
  ): Promise<any> {
    return axios(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: JSON.stringify(params)
    })
    .then(response => {
      return response.data;
    })
    .then((result) => {
      return result;
    }).catch((error) => {
      if (401 == error.response.status) {
        return Authentication.refreshToken().then(success => {
          if (success) {
            return this.executePost(path, params);
          }
        })
      }
      throw {
        message: error.message,
        messageApi: error.response.data.error,
        name: error.name,
        code: error.response.status,
        statusText: error.response.statusText,
        responseURL: error.request.responseURL,
        data: error.response?.data
      }
    })
  }

  static async executePut(
    path: string,
    params: unknown
  ): Promise<any> {
    return axios(path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: JSON.stringify(params)
    })
    .then(response => {
      return response.data;
    })
    .then((result) => {
      return result;
    }).catch((error) => {
      if (401 == error.response.status) {
        return Authentication.refreshToken().then(success => {
          if (success) {
            return this.executePut(path, params);
          }
        })
      }
      throw {
        message: error.message,
        messageApi: error.response.data.error,
        name: error.name,
        code: error.response.status,
        statusText: error.response.statusText,
        responseURL: error.request.responseURL,
        data: error.response?.data
      }
    })
  }

  static async executeDelete(
    path: string,
    params: unknown
  ): Promise<any> {
    return axios(path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': `${Authentication.getToken()}`
      },
      data: params
    })
    .then(response => {
      if (204 === response.status) {
        return true
      }

      return response.data;
    })
    .then((result) => {
      return result;
    }).catch((error) => {
      if (401 == error.response.status) {
        return Authentication.refreshToken().then(success => {
          if (success) {
            return this.executeDelete(path, params);
          }
        })
      }

      throw {
        message: error.message,
        messageApi: error.response.data.error,
        name: error.name,
        code: error.response.status,
        statusText: error.response.statusText,
        responseURL: error.request.responseURL,
        data: error.response?.data
      }
    })
  }
}
