import Caller from "../../domain/Api/Caller"
import envVariable from "../../infrastructure/presentation/util/envVariable"
import { ProductListInterface, ProductGatewayInterface } from "../../domain/Distribution"
import dayjs from 'dayjs'

export default class ProductGateway implements ProductGatewayInterface {
  async getList(
    month: number,
    year: number,
    type: string,
    signal?: AbortSignal,
  ): Promise<{ data: ProductListInterface[] | null, nb_element: number } | null> {
    try {
      const params = `month=${month}&year=${year}&type=${type}`
      const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/distribution/products?${params}`, {
        },
        signal
      )

      if (!data) {
        return {
          data: [],
          nb_element: 0
        }
      }

      return data
    } catch (e) {
      return {
        data: [],
        nb_element: 0
      }
    }
  }
}
