import Authentication from "./Authentication";
import {FunctionComponent} from "react";

const Logout: FunctionComponent = () => {
  Authentication.logout()

  return null
}

export default Logout
