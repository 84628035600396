import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {CustomerInterface} from "../../../../../../domain/Customer/Customer";
import {useNavigate, useParams} from "react-router-dom";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import ReferentielGateway from "../../../../../../gateway/Referentiel/ReferentielGateway";
import {SubmitHandler, useForm, useFormState} from "react-hook-form";
import InitializeCustomer
  from "../../../../../../useCase/Customer/Initialization/InitializeCustomer";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {BeneficialOwner} from "../../../../../../domain/Customer/BeneficialOwner";
import {
  setOpenAccountBeneficialOwnerForm, setOpenCustomerUploadForm,
  setOpenDocumentList,
  setOpenProspectUploadForm
} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import {useTranslation} from "react-i18next";
import UpdateUseCase from "../../../../../../useCase/Customer/Update/UpdateUseCase";
import CustomerGateway from "../../../../../../gateway/Customer/CustomerGateway";
import AddUseCase from "../../../../../../useCase/Customer/Add/AddUseCase";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {reloadCustomer} from "../../../../../store/component/customer";
import BeneficialOwnerDatalist from "../../../Datalist/BeneficialOwnerDatalist";
import DocumentGateway from "../../../../../../gateway/Customer/Document/DocumentGateway";
import FooterBlock from "../../../Block/FooterBlock";

interface IFormInput {
  id: string|null
}

type Props = {
  customer?: CustomerInterface
  isLectureMode?: boolean
}

const BeneficialOwnerForm: FunctionComponent<Props> = ({customer, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { customerType } = useParams()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false);
  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const beneficialOwnerArray = useAppSelector((state) => state.customer.beneficialOwnerArray)
  const [beneficialOwnerList, setBeneficialOwnerList] = useState<BeneficialOwner[]|[]>(beneficialOwnerArray)
  const beneficialOwnerRef = useRef<string|null>(null)

  useEffect(() => {
    setBeneficialOwnerList(beneficialOwnerArray)
  }, [beneficialOwnerArray])

  useEffect(() => {
    return () => {
      dispatch(setOpenDocumentList({
        show: false,
        documentList: [],
        gateway: null,
        openCallback: null
      }))
    };
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    setSubmitted(true);
    reset(data)
    if (beneficialOwnerList.length == 0) {
      return
    }

    if (undefined === customer) {
      customer = new InitializeCustomer().initializeCustomer()
    }

    if (null == customer.customerType ) {
      customer.customerType = undefined !== customerType ? customerType : 'moral'
    }

    customer.beneficialOwners = beneficialOwnerList

    if (null !== customer.id) {
      new UpdateUseCase(new CustomerGateway()).execute(customer).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-customer-success'));
          dispatch(reloadCustomer());
        } else {
          toastError(t('account.notify.update-customer-error'));
        }
        setSubmitted(false);
      })
      .catch(error => {
        toastError(t('account.notify.update-customer-error'));
      })
      .finally(() => {
        setSubmitted(false);
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  const uploadCallback = (beneficialOwnerId: string|null) => {

    if (customer?.id) {
      dispatch(setOpenCustomerUploadForm(( {
        show: true,
        documents: null,
        personId: customer.id,
        type: 'BE',
        beneficialOwnerId: beneficialOwnerId
      })))
    }
  }

  const openCallback = (fileId: number) => {
    if (customer?.id && beneficialOwnerRef.current) {
      new DocumentGateway().getBeneficialOwnerFile(customer?.id, beneficialOwnerRef.current, fileId).then(response => {
        const file = window.open('')
        if (null !== file && response !== null) {
          file.document.write(
            "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='"+response+"'></iframe></body>"
          )
        }
      })
    }
  }

  const viewFilesCallback = (beneficialOwnerId: string|null) => {
    if (customer?.id && beneficialOwnerId) {
      beneficialOwnerRef.current = beneficialOwnerId
      new DocumentGateway().getBeneficialOwnerFiles(customer?.id, beneficialOwnerId).then((response) => {
        if (response && response.length > 0) {
          dispatch(setOpenDocumentList({
            show: true,
            documentList: response,
            gateway: null,
            openCallback: openCallback
          }))
        }
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`form-bloc`}>
          <div className="form-bloc__title">{t('partner.form.beneficial-beneficiaries.title')}</div>
          <div className="form-bloc__shortcut">
            <button type="button" className="button-reset u-txt-with-icon" onClick={() => dispatch(setOpenAccountBeneficialOwnerForm({show: true, beneficialOwner: null}))}>
              <img src={iconAdd} alt="" />
              {t('partner.form.beneficial-beneficiaries.add')}
            </button>
          </div>
          {beneficialOwnerList?.length > 0 && customer?.id && <BeneficialOwnerDatalist beneficialOwnerList={beneficialOwnerList} uploadCallback={uploadCallback} viewFilesCallback={viewFilesCallback}/>}
        </div>
        {!isLectureMode && <FooterBlock disabled={submitted} onCancelUrl={t('url.customer.dashboard')} />}
      </form>
    </>
  )
}

export default BeneficialOwnerForm
