import RecurrenceMandateGatewayInterface from '../../../domain/Recurrence/RecurrenceMandateGatewayInterface';
import { RecurrenceMandateListInterface } from '../../../domain/Recurrence/RecurrenceMandateList';
import {ListRequestInterface} from './ListRequest'


export default class ListUseCase
{
  public recurrenceMandateGateway

  constructor(recurrenceMandateGateway: RecurrenceMandateGatewayInterface) {
    this.recurrenceMandateGateway = recurrenceMandateGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: RecurrenceMandateListInterface[]|null, numberOfItems: number}|null> {
    return await this.recurrenceMandateGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order).then(response => response)
  }
}
