import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {CustomerInterface} from "../../../../../../domain/Customer/Customer";
import {useNavigate, useParams} from "react-router-dom";
import {SubmitHandler, useForm, useFormState} from "react-hook-form";
import InitializeCustomer
  from "../../../../../../useCase/Customer/Initialization/InitializeCustomer";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {MoralLegalRepresentative} from "../../../../../../domain/Customer/MoralLegalRepresentative";
import {
  setOpenAccountMoralLegalRepresentativeForm, setOpenCustomerUploadForm,
  setOpenDocumentList,
} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import {useTranslation} from "react-i18next";
import UpdateUseCase from "../../../../../../useCase/Customer/Update/UpdateUseCase";
import CustomerGateway from "../../../../../../gateway/Customer/CustomerGateway";
import AddUseCase from "../../../../../../useCase/Customer/Add/AddUseCase";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {reloadCustomer} from "../../../../../store/component/customer";
import MoralLegalRepresentativeDatalist from "../../../Datalist/MoralLegalRepresentativeDatalist";
import DocumentGateway from "../../../../../../gateway/Customer/Document/DocumentGateway";

interface IFormInput {
  id: string|null
}

type Props = {
  customer?: CustomerInterface
  isLectureMode?: boolean
}

const MoralLegalRepresentativeForm: FunctionComponent<Props> = ({customer, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { customerType } = useParams()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false);
  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const moralLegalRepresentativesArray = useAppSelector((state) => state.customer.moralLegalRepresentativesArray)
  const [moralLegalRepresentativeList, setMoralLegalRepresentativeList] = useState<MoralLegalRepresentative[]|[]>(moralLegalRepresentativesArray)
  const moralLegalRepresentativeRef = useRef<string|null>(null)

  useEffect(() => {
    setMoralLegalRepresentativeList(moralLegalRepresentativesArray)
  }, [moralLegalRepresentativesArray])

  useEffect(() => {
    return () => {
      dispatch(setOpenDocumentList({
        show: false,
        documentList: [],
        gateway: null,
        openCallback: null
      }))
    };
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    setSubmitted(true);
    reset(data)
    if (moralLegalRepresentativeList.length == 0) {
      return
    }

    if (undefined === customer) {
      customer = new InitializeCustomer().initializeCustomer()
    }

    if (null == customer.customerType ) {
      customer.customerType = undefined !== customerType ? customerType : 'moral'
    }

    customer.moralLegalRepresentatives = moralLegalRepresentativeList

    if (null !== customer.id) {
      new UpdateUseCase(new CustomerGateway()).execute(customer).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-customer-success'));
          dispatch(reloadCustomer());
        } else {
          toastError(t('account.notify.update-customer-error'));
        }
        setSubmitted(false);
      })
      .catch(error => {
        toastError(t('account.notify.update-customer-error'));
      })
      .finally(() => {
        setSubmitted(false);
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  const uploadCallback = (moralLegalRepresentativeId: string|null) => {

    if (customer?.id) {
      dispatch(setOpenCustomerUploadForm(( {
        show: true,
        documents: null,
        personId: customer.id,
        type: 'RL',
        moralLegalRepresentativeId: moralLegalRepresentativeId
      })))
    }
  }

  const openCallback = (fileId: number) => {
    if (customer?.id && moralLegalRepresentativeRef.current) {
      new DocumentGateway().getMoralLegalRepresentativeFile(customer?.id, moralLegalRepresentativeRef.current, fileId).then(response => {
        const file = window.open('')
        if (null !== file && response !== null) {
          file.document.write(
            "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='"+response+"'></iframe></body>"
          )
        }
      })
    }
  }

  const viewFilesCallback = (moralLegalRepresentativeId: string|null) => {
    if (customer?.id && moralLegalRepresentativeId) {
      moralLegalRepresentativeRef.current = moralLegalRepresentativeId
      new DocumentGateway().getMoralLegalRepresentativeFiles(customer?.id, moralLegalRepresentativeId).then((response) => {
        if (response && response.length > 0) {
          dispatch(setOpenDocumentList({
            show: true,
            documentList: response,
            gateway: null,
            openCallback: openCallback
          }))
        }
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`form-bloc`}>
          <div className="form-bloc__title">{t('partner.form.moral-legal-representative.title')}</div>
          <div className="form-bloc__shortcut">
            <button type="button" className="button-reset u-txt-with-icon" onClick={() => dispatch(setOpenAccountMoralLegalRepresentativeForm({show: true, moralLegalRepresentative: null}))}>
              <img src={iconAdd} alt="" />
              {t('partner.form.moral-legal-representative.add')}
            </button>
          </div>
          {moralLegalRepresentativeList?.length > 0 && customer?.id && <MoralLegalRepresentativeDatalist moralLegalRepresentativeList={moralLegalRepresentativeList} uploadCallback={uploadCallback} viewFilesCallback={viewFilesCallback}/>}
        </div>
        <footer className={`form-bloc__footer`}>
          <button type="submit" className="button button--ink-2" disabled={submitted}>{t('common.save')}</button>
          <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
            navigate(`/${t('url.customer.dashboard')}`)
          }}>{t('common.cancel')}</button>
        </footer>
      </form>
    </>
  )
}

export default MoralLegalRepresentativeForm
