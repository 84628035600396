import {FilterRecurrenceSessionMandateInterface} from '../../../domain/Recurrence/RecurrenceSessionMandateInterface'

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterRecurrenceSessionMandateInterface
  sessionId: string
}

class ListRequest implements ListRequestInterface{
  constructor(
    public currentPage: number,
    public itemPerPage: number,
    public filter: FilterRecurrenceSessionMandateInterface,
    public sessionId: string
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
    this.sessionId = sessionId
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
