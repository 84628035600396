import {FC} from 'react'

interface IFieldErrorMessageProps {
    message: string
}

const FieldErrorMessage: FC<IFieldErrorMessageProps> = ({message}) => {
  return (
    <div className={'u-txt-size-s u-txt-color-error u-mts'}>{message}</div>
  )
};

export default FieldErrorMessage;
