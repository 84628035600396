import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'

import {ProspectInterface} from '../../../../../../domain/Prospect/Prospect'
import {setOpenAccountRelationFormEvent} from '../../../../../store/component/event'
import {reloadProspect, removeRelation} from '../../../../../store/component/prospect'
import {Relation} from '../../../../../../domain/Prospect/Relation'
import InitializeProspect
  from '../../../../../../useCase/Prospect/Initialization/InitializeProspect'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'
import AddUseCase from '../../../../../../useCase/Prospect/Add/AddUseCase'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import '../../../../../../assets/styles/page/_account.scss'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import SelectCustomView from '../../../Elements/SelectView'
import {toastError, toastSuccess} from "../../../../util/Toast";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";

type Props = {
  referential: ReferentielInterface
  prospect?: ProspectInterface
  isLectureMode?: boolean
}

const Particularity: FunctionComponent<Props> = ({prospect, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { prospectType } = useParams()
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false);

  const relationArray = useAppSelector((state) => state.prospect.relationArray)
  const [relationList, setRelationList] = useState<Relation[]|[]>(relationArray)

  useEffect(() => {
    setRelationList(relationArray)
  }, [relationArray])

  function onSubmit() {
    setSubmitted(true)

    if (undefined === prospect) {
      prospect = new InitializeProspect().initializeProspect()
    }

    if (null == prospect.prospectType ) {
      prospect.prospectType = undefined !== prospectType ? prospectType : 'physical'
    }

    prospect.relations = relationArray

    if (null !== prospect.id) {
      new UpdateUseCase(new ProspectGateway()).execute(prospect).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'))
          dispatch(reloadProspect());
        } else {
          toastError(t('account.notify.update-error'));
        }
        setSubmitted(false)
      })
      .catch(error => {
        toastError(t('account.notify.update-error'));
      })
      .finally(() => {
        setSubmitted(false);
      })
    }
  }

  function remove(relation:  Relation) {
    dispatch(removeRelation(relation))
  }

  const bankDetailsBlock = <div className={`form-bloc form--bloc--relation`}>
    <div className="form-bloc__title">{t('account.form.relation.title')}</div>
    {!isLectureMode && <div className="form-bloc__shortcut u-txt-right">
      <button type="button" className="button-reset u-txt-with-icon"
              onClick={() => dispatch(setOpenAccountRelationFormEvent({show: true, relation: null}))}>
        <img src={iconAdd} alt=""/>
        {t('account.form.relation.add')}
      </button>
    </div>}
    <div className="form-bloc__form flex-container">
      <div className="col-md-12">
        <div className="flex-container">
          {relationList.length > 0 &&
            relationList.map(relationItem => {
              return <div className="col-md-12" key={uuidV4()}>
                <div className="summary-relation">
                  <div className="form-control form-control--label-auto">
                      <label className="form-control__label">{t('account.form.relation.personId')}</label>
                      <div className="form-control__input">
                        <input type="text" value={relationItem.personId || ''} disabled />
                      </div>
                    </div>
                  <div className="form-control form-control--label-auto">
                      <label className="form-control__label">{t('account.form.relation.lastName')}</label>
                      <div className="form-control__input">
                        <input type="text" value={relationItem.lastName || ''} disabled />
                      </div>
                    </div>
                  <div className="form-control form-control--label-auto">
                      <label className="form-control__label">{t('account.form.relation.firstName')}</label>
                      <div className="form-control__input">
                        <input type="text" value={relationItem.firstName || ''} disabled />
                      </div>
                    </div>
                  <SelectCustomView classes="" classesFormControl="form-control--label-auto" label={t('account.form.relation.relation')} options={referential.global.relationship || null} register={null} defaultValue={relationItem.relation || ''} readOnly={true}/>
                  <button type="button" className="button button--white"
                          title={t('account.form.relation.edit')}
                          onClick={() => dispatch(setOpenAccountRelationFormEvent({show: true, relation: relationItem}))}>
                    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.6965 15.629L13.5964 3.71391L18.291 8.41463L6.39081 20.3311L0.785127 21.2158L1.6965 15.629ZM0.582269 21.4179L0.581997 21.418L0.582269 21.4179ZM20.6178 6.0847L20.6175 6.08499L20.1733 6.53004L15.4786 1.82907L15.9244 1.38289L15.9247 1.3826C16.1249 1.18198 16.3627 1.02289 16.6242 0.914368C16.8858 0.805845 17.1661 0.75 17.4491 0.75C17.7322 0.75 18.0125 0.805845 18.2741 0.914368C18.5356 1.02289 18.7733 1.18198 18.9736 1.3826L18.9737 1.38274L20.6177 3.02887L20.6178 3.02901C20.8182 3.22952 20.9772 3.46765 21.0857 3.72981C21.1941 3.99198 21.25 4.27302 21.25 4.55685C21.25 4.84069 21.1941 5.12173 21.0857 5.38389C20.9772 5.64606 20.8182 5.88419 20.6178 6.0847Z" stroke="#1D1D1D" strokeWidth="1.5"/>
                    </svg>
                  </button>
                  <button type="button"
                          className="button button--white"
                          title={t('account.form.relation.remove')}
                          onClick={() => remove(relationItem)}
                  >
                    X
                  </button>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  </div>

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="button"
              className="button button--ink-2"
              disabled={submitted}
              onClick={() => onSubmit()}
      >{t('common.save')}</button>
      <button type="button"
              className="button button--ink-2 button--ink-2--outline"
              onClick={() => navigate(`/${t('url.prospect.dashboard')}`)}>
        {t('common.cancel')}
      </button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <div>
      <div className="flex-container" ref={measuredRef}>
        <div className="col-md-12">{bankDetailsBlock}</div>
      </div>
      {renderFooter()}
    </div>
  )
}

export default Particularity
