import dayjs from 'dayjs'

import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import RecurrenceSessionMandateGatewayInterface from '../../domain/Recurrence/RecurrenceSessionMandateGatewayInterface'
import {FilterRecurrenceSessionMandateInterface, RecurrenceSessionMandateInterface} from '../../domain/Recurrence/RecurrenceSessionMandateInterface'

export default class RecurrenceSessionMandateGateway implements RecurrenceSessionMandateGatewayInterface {
  async getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterRecurrenceSessionMandateInterface,
    sessionId: string,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: RecurrenceSessionMandateInterface[] | null, state: string, numberOfItems: number, itemsPerPage: number } | null> {
    try {
      const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/mandates?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        keyword: filter?.keyword || '',
        status: filter?.status || ''
      }, signal)

      if (data) {
        return {
          data: data.data,
          state: data.sessionStatus,
          numberOfItems: data.nb_element,
          itemsPerPage: data.nb_element_per_page
        }
      }

      return null
    } catch (e) {
      return null
    }
  }

  async bulkExclude(
    sessionId: string,
    mandateIds: string[],
    reason: string
  ): Promise<string|null> {
    try {
      const data = await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/bulk/exclude`, {
        reason: reason,
        mandates: mandateIds
      })
      if (data) {
        return 'OK'
      }

      return null
    } catch (e) {
      return null
    }
  }

  async bulkPaymentRefused(
    sessionId: string,
    mandateIds: string[],
    reason: string
  ): Promise<string|null> {
    try {
      const data = await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/bulk/payment-reject`, {
        reason: reason,
        mandates: mandateIds
      })
      if (data) {
        return 'OK'
      }

      return null
    } catch (e) {
      return null
    }
  }

  async bulkPaymentValidated(
    sessionId: string,
    mandateIds: string[]
  ): Promise<string|null> {
    try {
      const data = await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/bulk/payment-validate`, {
        mandates: mandateIds
      })
      if (data) {
        return 'OK'
      }

      return null
    } catch (e) {
      return null
    }
  }

  async paymentValidated(
    sessionId: string,
    mandateId: string,
    paymentAt: string,
    externalReference: string,
    paymentReference: string,
    paymentAmount: number
  ): Promise<string|null> {
    try {
      const data = await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/mandates/${mandateId}`, {
        payment_at: dayjs(paymentAt, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00'),
        payment_status: "payment_validated",
        external_reference: externalReference,
        payment_reference: paymentReference,
        paymentAmount: typeof paymentAmount === 'string' ? parseFloat(String(paymentAmount)) : paymentAmount,
      })
      if (data) {
        return 'OK'
      }

      return null
    } catch (e) {
      return null
    }
  }

  async paymentRejected(
    sessionId: string,
    mandateId: string,
    rejectCount: number,
    rejectReason: string,
    rejectReference: string
  ): Promise<string|null> {
    try {
      const data = await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/recurrences/sessions/${sessionId}/mandates/${mandateId}`, {
        reject_count: rejectCount,
        payment_status: "payment_rejected",
        reject_reason: rejectReason,
        reject_reference: rejectReference,
      })
      if (data) {
        return 'OK'
      }

      return null
    } catch (e) {
      return null
    }
  }
}
