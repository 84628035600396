// RowsPerPageSelector.tsx
import React, { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import SelectCustom from "../Elements/Select"
import { optionsNbRows } from "../../../../fixtures/Referentiel"
import { saveNbRowsInLocalStorage } from "../../util/SavePreferencesInLocalStorage"

interface RowsPerPageSelectorProps {
  onRowsChange: (rows: number) => void
}

const RowsPerPageSelector: FunctionComponent<RowsPerPageSelectorProps> = ({ onRowsChange }) => {
  const {t} = useTranslation()
  const { register, watch, setValue } = useFormContext()
  const watchNumberRows = watch('numberRows')

  const previousRowsRef = React.useRef<number | null>(null)

  useEffect(() => {
    if (!previousRowsRef.current) {
      const preferences = localStorage.getItem("preferences")
      const initialRows = preferences ? JSON.parse(preferences).numberRows : 50
      setValue("numberRows", initialRows)
      previousRowsRef.current = initialRows
      return
    }

    if (watchNumberRows && watchNumberRows !== previousRowsRef.current) {
      saveNbRowsInLocalStorage(localStorage.getItem("preferences"), watchNumberRows)
      onRowsChange(watchNumberRows)
      previousRowsRef.current = watchNumberRows
    }
  }, [watchNumberRows, setValue, onRowsChange])

  return (
    <SelectCustom
      classes="flex justify-end u-mbs"
      id="numberRows"
      name="numberRows"
      label={t('filters.display-results-by')}
      options={optionsNbRows}
      register={register}
      noChoiceOption
    />
  )
}

export default RowsPerPageSelector
