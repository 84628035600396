import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {FilterCustomerInterface} from '../../../domain/Customer/CustomerList'
import {CustomerInterface} from '../../../domain/Customer/Customer'
import {BankInformation, BankInformationInterface} from '../../../domain/Customer/BankInformation'
import {Relation, RelationInterface} from '../../../domain/Customer/Relation'
import {BeneficialOwner} from '../../../domain/Customer/BeneficialOwner'
import {MoralLegalRepresentative} from '../../../domain/Customer/MoralLegalRepresentative'
import {PartnerInterface} from "../../../domain/Partner/Partner";
import {CustomerLegalRepresentatives, CustomerLegalRepresentativesInterface} from "../../../domain/Customer/CustomerLegalRepresentatives";
import {v4 as uuidV4} from "uuid";
import {AddressInterface} from "../../../domain/Address/Address";
import {GeneralAddress, GeneralAddressInterface} from "../../../domain/Address/GeneralAddress";

interface customer {
  reloadCustomer: string
  customer: CustomerInterface|null
  filter: FilterCustomerInterface|null
  bankInformationArray: BankInformationInterface[]
  relationArray: RelationInterface[]
  currentRelationSelected: RelationInterface|null
  productsReferentiel: [],
  beneficialOwnerArray: BeneficialOwner[],
  moralLegalRepresentativesArray: MoralLegalRepresentative[],
  partner: PartnerInterface|null
  legalRepresentativesArray: CustomerLegalRepresentativesInterface[]
  currentPledgeDataList: any|null

  customerCurrentAddress: GeneralAddressInterface|null
  customerCurrentFiscalAddress: GeneralAddressInterface|null
}

const initialState: customer = {
  reloadCustomer: uuidV4(),
  customer: null,
  filter: null,
  bankInformationArray: [],
  relationArray: [],
  currentRelationSelected: null,
  productsReferentiel: [],
  beneficialOwnerArray: [],
  moralLegalRepresentativesArray: [],
  partner: null,
  legalRepresentativesArray: [],
  currentPledgeDataList: null,
  customerCurrentAddress: null,
  customerCurrentFiscalAddress: null
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {
    reloadCustomer: (state) => {
      state.reloadCustomer = uuidV4()
    },
    setProductsReferentiel: (state, action: PayloadAction<[]>) => {
      state.productsReferentiel = action.payload
    },
    addFilter: (state, action: PayloadAction<FilterCustomerInterface|null>) => {
      state.filter = action.payload
    },
    saveCustomer: (state, action: PayloadAction<any>) => {
      state.customer = {...state.customer, ...action.payload}
    },
    setBankInformations: (state, action: PayloadAction<BankInformation[]>) => {
      state.bankInformationArray = action.payload
    },
    addBankInformation: (state, action: PayloadAction<BankInformation>) => {
      state.bankInformationArray.push(action.payload)
    },
    updateBankInformation: (state, action: PayloadAction<BankInformation>) => {
      const index = state.bankInformationArray.findIndex(bankInformationItem => {
        return bankInformationItem.id === action.payload.id
      });
      if (index >=0) {
        state.bankInformationArray[index] = action.payload
      }
    },
    setCurrentRelationSelect: (state, action: PayloadAction<Relation|null>) => {
      state.currentRelationSelected = action.payload
    },
    setRelations: (state, action: PayloadAction<Relation[]>) => {
      state.relationArray = action.payload
    },
    addRelation: (state, action: PayloadAction<Relation>) => {
      state.relationArray.push(action.payload)
    },
    updateRelation: (state, action: PayloadAction<Relation>) => {
      const index = state.relationArray.findIndex(relationItem => {
        return relationItem.id === action.payload.id
      });
      if (index >=0) {
        state.relationArray[index] = action.payload
      }
    },
    removeRelation: (state, action: PayloadAction<Relation>) => {
      state.relationArray = state.relationArray.filter(function (item) {
        return item.personId !== action.payload.personId
      })
    },
    setBeneficialOwner: (state, action:PayloadAction<BeneficialOwner[]>) => {
      state.beneficialOwnerArray = action.payload
    },
    addBeneficialOwner: (state, action:PayloadAction<BeneficialOwner>) => {
      state.beneficialOwnerArray.push(action.payload)
    },
    updateBeneficialOwner: (state, action:PayloadAction<BeneficialOwner>) => {
      const index = state.beneficialOwnerArray.findIndex(owner => {
        return owner.person?.id === action.payload.person?.id
      });
      if (index >=0) {
        state.beneficialOwnerArray[index] = action.payload
      }
    },
    setMoralLegalRepresentative: (state, action:PayloadAction<MoralLegalRepresentative[]>) => {
      state.moralLegalRepresentativesArray = action.payload
    },
    addMoralLegalRepresentative: (state, action:PayloadAction<MoralLegalRepresentative>) => {
      state.moralLegalRepresentativesArray.push(action.payload)
    },
    updateMoralLegalRepresentative: (state, action:PayloadAction<MoralLegalRepresentative>) => {
      const index = state.moralLegalRepresentativesArray.findIndex(owner => {
        return owner.person?.id === action.payload.person?.id
      });
      if (index >=0) {
        state.moralLegalRepresentativesArray[index] = action.payload
      }
    },
    setPartner: (state, action) => {
      state.partner = action.payload
    },
    setLegalRepresentatives: (state, action: PayloadAction<CustomerLegalRepresentatives[]>) => {
      state.legalRepresentativesArray = action.payload
    },
    addLegalRepresentatives: (state, action: PayloadAction<CustomerLegalRepresentativesInterface>) => {
      state.legalRepresentativesArray.push(action.payload)
    },
    updateLegalRepresentatives: (state, action: PayloadAction<CustomerLegalRepresentatives>) => {
      const index = state.legalRepresentativesArray.findIndex(legalRepresentatives => {
        return legalRepresentatives.person?.id === action.payload.id
      });
      if (index >=0) {
        state.legalRepresentativesArray[index] = action.payload
      }
    },
    setPledgeCurrentDataList: (state, action: PayloadAction<any>) => {
      state.currentPledgeDataList = action.payload
    },
    addPledgeInCurrentDataList: (state, action: PayloadAction<any>) => {
      state.currentPledgeDataList = {...state.currentPledgeDataList, data: [action.payload, ...state.currentPledgeDataList.data]}
    },
    updatePledgeCurrentDataList: (state, action: PayloadAction<any>) => {
      const index = state.currentPledgeDataList.data.findIndex((pledge: any) => {
        return pledge?.id === action.payload?.id
      });

      if (index >=0) {
        state.currentPledgeDataList.data[index] = action.payload
      }
    },
    addCurrentAddress: (state, action: PayloadAction<GeneralAddressInterface>) => {
      state.customerCurrentAddress = {...action.payload}
    },
    editCurrentAddress: (state, action: PayloadAction<GeneralAddressInterface>) => {
      const newAddress = new GeneralAddress(
        null,
        action.payload?.address ? {...action.payload.address} : null,
        null,
        null,
        null,
        true,
        "postal",
        action.payload.status
      )
      state.customerCurrentAddress = newAddress
    },
    addCurrentFiscalAddress: (state, action: PayloadAction<GeneralAddressInterface>) => {
      state.customerCurrentFiscalAddress = {...action.payload}
    },
    editCurrentFiscalAddress: (state, action: PayloadAction<GeneralAddressInterface>) => {
      const newAddress = new GeneralAddress(
        null,
        action.payload?.address ? {...action.payload.address} : null,
        null,
        null,
        null,
        true,
        "fiscal",
        action.payload.status
      )
      state.customerCurrentFiscalAddress = newAddress
    },
    clearCurrentAddresses: (state) => {
      state.customerCurrentFiscalAddress = null
      state.customerCurrentAddress = null
    },
  }
})

export const {
  reloadCustomer,
  addFilter,
  //addCustomer,
  saveCustomer,
  setBankInformations,
  addBankInformation,
  updateBankInformation,
  setCurrentRelationSelect,
  setRelations,
  addRelation,
  updateRelation,
  setProductsReferentiel,
  removeRelation,
  setBeneficialOwner,
  addBeneficialOwner,
  updateBeneficialOwner,
  setMoralLegalRepresentative,
  addMoralLegalRepresentative,
  updateMoralLegalRepresentative,
  setPartner,
  setLegalRepresentatives,
  addLegalRepresentatives,
  updateLegalRepresentatives,
  setPledgeCurrentDataList,
  addPledgeInCurrentDataList,
  updatePledgeCurrentDataList,
  addCurrentAddress,
  addCurrentFiscalAddress,
  editCurrentAddress,
  editCurrentFiscalAddress,
  clearCurrentAddresses
} = customerSlice.actions

export default customerSlice.reducer
