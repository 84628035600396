import {Presenter} from '../Presenter'
import {ProspectListInterface} from '../../domain/Prospect/ProspectList'
import {Undivided} from "../../domain/Prospect/Undivided";
import {BeneficialOwner} from "../../domain/Customer/BeneficialOwner";
import {MoralLegalRepresentative} from "../../domain/Prospect/MoralLegalRepresentative";

class CustomerIndivisaireListPresenter extends Presenter {

  private useCase: { getProspects: any }

  constructor(getIndivisaire: any) {
    super({
      viewModel: {
        heading: [
          {name: 'account.heading.Action'},
          {name: 'partner.form.undivideds.civility', sort: "title", labelApi: "title"},
          {name: 'partner.form.undivideds.lastname', sort: "lastname", labelApi: "lastname"},
          {name: 'partner.form.undivideds.firstname', sort: "firstname", labelApi: "firstname"},
        ],
        datas: [],
        filtersShortcut: [
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getProspects: getIndivisaire,
    }
  }

  async load() {
    try {
      this._setProspects(await this.useCase.getProspects);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setProspects(undivided: Undivided[]|BeneficialOwner[]|MoralLegalRepresentative[]|null) {
    this.update({
      data: undivided,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': undivided?.length,
      }
    });
  }
}

export default CustomerIndivisaireListPresenter
