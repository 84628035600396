import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {
  setOpenAccountMoralLegalRepresentativeForm, setOpenDocumentList,
  setOpenProspectUploadForm
} from '../../../../../store/component/event'
import {ProspectInterface} from '../../../../../../domain/Prospect/Prospect'
import InitializeProspect
  from '../../../../../../useCase/Prospect/Initialization/InitializeProspect'
import {MoralLegalRepresentative} from '../../../../../../domain/Prospect/MoralLegalRepresentative'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {reloadProspect} from "../../../../../store/component/prospect";
import MoralLegalRepresentativeDatalist from "../../../Datalist/MoralLegalRepresentativeDatalist";
import DocumentGateway from "../../../../../../gateway/Prospect/Document/DocumentGateway";

interface IFormInput {
  id: string|null
}

type Props = {
  prospect?: ProspectInterface
  isLectureMode?: boolean
}

const MoralLegalRepresentativeForm: FunctionComponent<Props> = ({prospect, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { prospectType } = useParams()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false);
  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const moralLegalRepresentativesArray = useAppSelector((state) => state.prospect.moralLegalRepresentativesArray)
  const [moralLegalRepresentativeList, setMoralLegalRepresentativeList] = useState<MoralLegalRepresentative[]|[]>(moralLegalRepresentativesArray)
  const moralLegalRepresentativeRef = useRef<string|null>(null)

  useEffect(() => {
    setMoralLegalRepresentativeList(moralLegalRepresentativesArray)
  }, [moralLegalRepresentativesArray])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    setSubmitted(true);
    reset(data)
    if (moralLegalRepresentativeList.length == 0) {
      return
    }

    if (undefined === prospect) {
      prospect = new InitializeProspect().initializeProspect()
    }

    if (null == prospect.prospectType ) {
      prospect.prospectType = undefined !== prospectType ? prospectType : 'moral'
    }

    prospect.moralLegalRepresentatives = moralLegalRepresentativeList

    if (null !== prospect.id) {
      new UpdateUseCase(new ProspectGateway()).execute(prospect).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'))
          dispatch(reloadProspect());
        } else {
          toastError(t('account.notify.update-error'));
        }
        setSubmitted(false);
      })
      .catch(error => {
        toastError(t('account.notify.update-error'));
      })
      .finally(() => {
        setSubmitted(false);
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2" disabled={submitted}>{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
        navigate(`/${t('url.prospect.dashboard')}`)
      }}>{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  const uploadCallback = (moralLegalRepresentativeId: string|null) => {
    if (prospect?.id) {
      dispatch(setOpenProspectUploadForm(( {
        show: true,
        documents: null,
        prospectId: prospect.id,
        type: 'RL',
        moralLegalRepresentativeId: moralLegalRepresentativeId
      })))
    }
  }

  const openCallback = (fileId: number) => {
    if (prospect?.id && moralLegalRepresentativeRef.current) {
      new DocumentGateway(prospect.id).getMoralLegalRepresentativeFile(prospect.id, moralLegalRepresentativeRef.current, fileId).then(response => {
        const file = window.open('')
        if (null !== file && response !== null) {
          file.document.write(
            "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='"+response+"'></iframe></body>"
          )
        }
      })
    }
  }

  const viewFilesCallback = (moralLegalRepresentativeId: string|null) => {
    if (prospect?.id && moralLegalRepresentativeId) {
      moralLegalRepresentativeRef.current = moralLegalRepresentativeId
      new DocumentGateway(prospect?.id).getMoralLegalRepresentativeFiles(prospect?.id, moralLegalRepresentativeId).then((response) => {
        if (response && response.length > 0) {
          dispatch(setOpenDocumentList({
            show: true,
            documentList: response,
            gateway: null,
            openCallback: openCallback
          }))
        }
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
        <div className={`form-bloc`}>
          <div className="form-bloc__title">{t('partner.form.moral-legal-representative.title')}</div>
          <div className="form-bloc__shortcut">
            {!isLectureMode && <button type="button" className="button-reset u-txt-with-icon"
               onClick={() => dispatch(setOpenAccountMoralLegalRepresentativeForm({
                 show: true,
                 moralLegalRepresentative: null
               }))}>
              <img src={iconAdd} alt=""/>
              {t('partner.form.moral-legal-representative.add')}
              </button>
            }
          </div>
          {moralLegalRepresentativeList?.length > 0 && prospect?.id && <MoralLegalRepresentativeDatalist moralLegalRepresentativeList={moralLegalRepresentativeList} uploadCallback={uploadCallback} viewFilesCallback={viewFilesCallback}/>}
        </div>
        {renderFooter()}
      </form>
    </>
  )
}

export default MoralLegalRepresentativeForm
