import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'
import {ProspectInterface} from "../../../../../domain/Prospect/Prospect";

type Props = {
  prospect?: ProspectInterface
  isLectureMode?: boolean
}

const TabProspectEditCorporation: FunctionComponent<Props> = ({prospect, isLectureMode}) => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--prospect-add`}>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-general-information`)}/${prospect?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-general-information')}
      </NavLink>
      {prospect?.moralType === 'business' &&
        <NavLink
          to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-beneficial-owner`)}/${prospect?.id}`}
          className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
        >
        {t('account.add.tab-beneficial-owner')}
        </NavLink>
      }
      {prospect?.moralType === 'joint_possession' &&
        <NavLink
          to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-indivisaire`)}/${prospect?.id}`}
          className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
        >
          {t('account.add.tab-indivisaire')}
        </NavLink>
      }
      {prospect?.moralType === 'other' &&
          <NavLink
              to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-moral-legal-representative`)}/${prospect?.id}`}
              className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
          >
            {t('account.add.tab-moral-legal-representative')}
          </NavLink>
      }
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-addresses`)}/${prospect?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-addresses')}
      </NavLink>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-bank-details`)}/${prospect?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-bank-details')}
      </NavLink>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-investment-information`)}/${prospect?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-investment-information')}
      </NavLink>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-particularities`)}/${prospect?.id}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-particularities')}
      </NavLink>
    </div>
  )
}

export default TabProspectEditCorporation
