import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

import {FiscalityInterface} from '../../../../../../domain/Product/Fiscality'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import InitialiazeProduct from '../../../../../../useCase/Product/Initialization/InitialiazeProduct'
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase'
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase'
import {ProductType} from '../../../../../../domain/Product/ProductType'
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {confirmAlert} from "react-confirm-alert";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import FooterBlock from "../../../Block/FooterBlock";

interface IFormInput {
  fiscality: FiscalityInterface
}

type Props = {
  product?: ProductInterface
  isLectureMode?: boolean
}

const Fiscality: FunctionComponent<Props> = ({product, isLectureMode}) => {
  const {t} = useTranslation()
  const { productType } = useParams()
  const productGateway = new ProductGateway()
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(() => {
    if (product?.fiscality) {
      setValue('fiscality', product.fiscality)
    }
  }, [product])

  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }

  const onConfirm = (data: any) =>  {
    reset(data)

    if (undefined === product) {
      product = new InitialiazeProduct().initializeProduct()
    }

    if (
        null == product.productType
    ) {
      product.productType = new ProductType(
          null,
          null,
          null,
          null,
          undefined !== productType ? productType : 'other',
          null,
          null,
          true
      )
    }

    product.fiscality = data.fiscality

    if (null !== product.id) {
      new UpdateUseCase(productGateway).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
        } else {
          toastError(t('product.notify.update-error'));
        }
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="flex-container">
        <div className="col-md-6">
          <div className={`form-bloc form--bloc--fiscality`}>
            <div className="form-bloc__title">{t('product.form.fiscality.title')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="fiscalityIfu" className="form-control__label">{t('product.form.fiscality.ifu')}</label>
                  <div className="form-control__input">
                    <input type="text" id="fiscalityIfu" {...register('fiscality.ifu')} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="fiscalityBenefit" className="form-control__label">{t('product.form.fiscality.benefit')}</label>
                  <div className="form-control__input">
                    <input type="text" id="fiscalityBenefit" {...register('fiscality.benefit')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control form-control--textarea">
                  <label htmlFor="fiscalityComment" className="form-control__label">{t('product.form.fiscality.comment')}</label>
                  <div className="form-control__input">
                    <textarea id="fiscalityComment" {...register('fiscality.comment')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isLectureMode && <FooterBlock disabled={false} onCancelUrl={t('url.product.dashboard')}/>}
    </form>
  )
}

export default Fiscality
