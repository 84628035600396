import React, {FunctionComponent, MouseEvent, useEffect, useMemo} from "react";
import {useAppSelector} from "../../../../../store/hook";
import {useTranslation} from "react-i18next";
import InputDate from "../../../Elements/InputDate";
import expandIcon from "../../../../../../assets/images/icons/arrow-bottom--dark.svg";
import {ScaleAvailable} from "../../../../../../domain/Product/Scale";
import {ScaleItemInterface} from "../../../../../../domain/Product/ScaleItem";
import {Control, UseFormRegister, UseFormSetValue} from "react-hook-form/dist/types/form";
import {IFormConventionAnnexInput} from "./AddConventionAnnex";
import {FieldPath} from "react-hook-form/dist/types/utils";

type Props<T> = {
  scale: ScaleAvailable,
  register: UseFormRegister<T>,
  control: Control<T>,
  nameScale: string,
  setValue: UseFormSetValue<T>,
  scaleSelected: { scaleLabelCategory: string, subScaleLabelCategory: string[] },
  setScaleSelected: React.Dispatch<React.SetStateAction<{
    scaleLabelCategory: string,
    subScaleLabelCategory: string[]
  }>>
}

const ScaleBlock: FunctionComponent<Props<IFormConventionAnnexInput>> =
  ({
     scale,
     register,
     control,
     nameScale,
     setValue,
     scaleSelected,
     setScaleSelected
   }) => {
    const {t} = useTranslation()
    const openPartnerConventionAnnex = useAppSelector((state) => state.event.openPartnerConventionAnnexForm)

    const nameScaleLowerCase = useMemo(() => {
      return nameScale.toLowerCase();
    }, [nameScale])

    useEffect(() => {
      setValue(`scales.${nameScaleLowerCase}.date`, scale.date)
      scale.items.forEach((item) => {
        setValue(`scales.${nameScaleLowerCase}.${item.category.label}`, item)
      })
    }, [nameScale, scale.date, scale.items, setValue])

    const selectScale = (scale: ScaleAvailable, scaleItem: ScaleItemInterface) => {
      if (scaleSelected.scaleLabelCategory === nameScaleLowerCase) {
        if (scaleSelected.subScaleLabelCategory.includes(scaleItem.id)) {
          const newSubScaleArray = scaleSelected.subScaleLabelCategory.filter((sub) => sub !== scaleItem.id)
          setScaleSelected((current: { scaleLabelCategory: string, subScaleLabelCategory: string[] }) => (
            {
              ...current,
              scaleLabelCategory: (newSubScaleArray.length > 0 ? nameScaleLowerCase : ''),
              subScaleLabelCategory: newSubScaleArray
            }
          ))
        } else {
          setScaleSelected((current: { scaleLabelCategory: string, subScaleLabelCategory: string[] }) => (
            {
              ...current,
              scaleLabelCategory: nameScaleLowerCase,
              subScaleLabelCategory: [...scaleSelected.subScaleLabelCategory, scaleItem.id]
            }
          ))
        }
      } else {
        setScaleSelected({
          ...scaleSelected,
          scaleLabelCategory: nameScaleLowerCase,
          subScaleLabelCategory: [scaleItem.id]
        })
      }
    }

    function isRequiredField(id: string) {
      return nameScale.includes("dérogatoire") && scaleSelected.subScaleLabelCategory.includes(id);
    }

    function handleCollapse(event: MouseEvent<HTMLElement>) {
      event.currentTarget.classList.toggle('collapse__title--active')
    }

    return (
      <div className={`form-bloc-scale form--bloc--general-scale`}>
        <div className="form-bloc__title">{scale.label}</div>
        <div className="form-bloc__form flex-container">
          <div className="col-md-4">
            <div className="form-control">
              <label htmlFor={`scales.${nameScale}.date`}
                     className={`form-control__label ${scaleSelected.scaleLabelCategory.toLowerCase() === nameScale.toLowerCase() ? "mandatory-scroll-field" : ""}`}>{t('product.form.scale.date')}
              </label>
              <div className="form-control__input">
                <InputDate id={`scales.${nameScaleLowerCase}.date`}
                           name={`scales.${nameScaleLowerCase}.date`}
                           register={register}
                           control={control}
                           required={scaleSelected.scaleLabelCategory.toLowerCase() === nameScaleLowerCase}
                           defaultValue={scale.date}
                           disabled={!nameScaleLowerCase.includes("dérogatoire")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-bloc__form flex-container">
          {scale.items?.map((item) => {
            const pathScaleRhf = `scales.${nameScaleLowerCase}.${item.category.label}`

            return (
              <div className="col-md-12" key={item.id}>
                <button type="button"
                        className={`collapse__title ${openPartnerConventionAnnex?.conventionAnnex?.scaleItems.find((scaleItem) => scaleItem.id === item.id) ? 'collapse__title--active' : ''}`}
                        onClick={handleCollapse}
                >
                  {item.category.label}
                  <img src={expandIcon} alt=""/>
                </button>
                <div className="collapse__content-full flex-container">
                  <div className="col-md-12 u-mts">
                    <div className="form-control">
                      <label htmlFor={`${pathScaleRhf}.value`}
                             className={`form-control__label ${isRequiredField(item.id) ? 'mandatory-scroll-field' : ''}`}
                      >
                        {t('product.form.scale.value')}
                      </label>
                      <div className="form-control__input">
                        <input type="number"
                               step="0.01"
                               id={`${pathScaleRhf}.value`}
                               defaultValue={item.value}
                               required={isRequiredField(item.id)}
                               {...register(`${pathScaleRhf}.value` as FieldPath<IFormConventionAnnexInput>)}
                               disabled={!nameScaleLowerCase.includes("dérogatoire")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-control">
                      <label htmlFor={`${pathScaleRhf}.approbationAt`}
                             className={`form-control__label ${isRequiredField(item.id) ? 'mandatory-scroll-field' : ''}`}
                      >
                        {t('product.form.scale.approval')}
                      </label>
                      <div className="form-control__input">
                        <InputDate id={`${pathScaleRhf}.approbationAt`}
                                   name={`${pathScaleRhf}.approbationAt`}
                                   register={register}
                                   control={control}
                                   required={isRequiredField(item.id)}
                                   defaultValue={item.approbationAt}
                                   disabled={!nameScaleLowerCase.includes("dérogatoire")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-control">
                      <label htmlFor={`${pathScaleRhf}.applicationAt`}
                             className="form-control__label"
                      >
                        {t('product.form.scale.application')}
                      </label>
                      <div className="form-control__input">
                        <InputDate id={`${pathScaleRhf}.applicationAt`}
                                   name={`${pathScaleRhf}.applicationAt`}
                                   register={register}
                                   control={control}
                                   defaultValue={item.applicationAt}
                                   disabled={!nameScaleLowerCase.includes("dérogatoire")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-control">
                      <label htmlFor={`${pathScaleRhf}.period.start`}
                             className={`form-control__label ${isRequiredField(item.id) ? 'mandatory-scroll-field' : ''}`}
                      >
                        {t('product.form.scale.periodStart')}
                      </label>
                      <div className="form-control__input">
                        <InputDate id={`${pathScaleRhf}.period.start`}
                                   name={`${pathScaleRhf}.period.start`}
                                   register={register}
                                   control={control}
                                   required={isRequiredField(item.id)}
                                   defaultValue={item.period?.start}
                                   disabled={!nameScaleLowerCase.includes("dérogatoire")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-control">
                      <label htmlFor={`${pathScaleRhf}.period.end`}
                             className="form-control__label"
                      >
                        {t('product.form.scale.periodEnd')}
                      </label>
                      <div className="form-control__input">
                        <InputDate id={`${pathScaleRhf}.period.end`}
                                   name={`${pathScaleRhf}.period.end`}
                                   register={register}
                                   control={control}
                                   defaultValue={item.period?.end}
                                   disabled={!nameScaleLowerCase.includes("dérogatoire")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-control form-control--center">
                      <input type="checkbox"
                             onChange={() => selectScale(scale, item)}
                             checked={scaleSelected.subScaleLabelCategory.includes(item.id)}
                      />
                      <label
                        className="form-control__label"
                        htmlFor={`${pathScaleRhf}.checked`}
                      >
                        Ce barème s'applique au partenaire
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

export default ScaleBlock