import React, {FunctionComponent, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm, SubmitHandler} from 'react-hook-form'
import {Link} from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setCountTotal} from '../../../../store/component/recurrence'
import {setOpenDatalistFilterRecurrenceMandate, setOpenEditRecurrenceMandate} from '../../../../store/component/event'
import {FilterRecurrenceMandateInterface} from '../../../../../domain/Recurrence/RecurrenceMandateList'
import RecurrenceMandateListPresenter from '../../../../../presenter/Recurrence/Mandate/RecurrenceMandateListPresenter'
import {ListRequest} from '../../../../../useCase/Recurrence/Mandate/ListRequest'
import ListUseCase from '../../../../../useCase/Recurrence/Mandate/ListUseCase'
import Pagination from '../../Pagination/Pagination'
import RecurrenceMandateGateway from '../../../../../gateway/Recurrence/RecurrenceMandateGateway'
import HeaderRight from '../Element/HeaderRight'

import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../../assets/images/icons/datalist-edit.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import {optionsNbRows} from "../../../../../fixtures/Referentiel";
import SelectCustom from "../../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../../util/SavePreferencesInLocalStorage";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import TableHead from "../../Table/TableHead";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import downloadBlobFile from '../../../util/DownloadBlobFile'
import { RecurrenceMandateInterface } from '../../../../../domain/Recurrence/RecurrenceMandate'
import {getLabelByValue} from '../../../util/ReferentialI18n'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

const RecurrenceMandateDatalist: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterRecurrenceMandate = useAppSelector((state) => state.event.openDatalistFilterRecurrenceMandate)
  const recurrenceRules = useAppSelector((state) => state.me.me?.rules.recurrence.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const { control, register, handleSubmit, setValue, watch, getValues } = useForm()

  const watchNumberRows = watch('numberRows')

  const periodicityTypes = useMemo(() => {
    const periodicityTypes: { [key: string]: string } = {}

    if (referential?.recurrence?.session_periodicity) {
      referential.recurrence.session_periodicity.map(item => {
        periodicityTypes[`${item.value}`] = item.label;
      })
    }

    return periodicityTypes
  }, [referential?.recurrence?.session_periodicity])

  useEffect(() => {
    dispatch(setOpenDatalistFilterRecurrenceMandate( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        status: [],
        periodicity: [],
        start: '',
        end: '',
        keyword: '',
        code: '',
      }
    }))
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterRecurrenceMandate.filters)
      const recurrences = new ListUseCase(new RecurrenceMandateGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      });
      const presenter = new RecurrenceMandateListPresenter(recurrences);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
      setValue('product', openDatalistFilterRecurrenceMandate.filters.product)
      setValue('status', openDatalistFilterRecurrenceMandate.filters.status)
      setValue('periodicity', openDatalistFilterRecurrenceMandate.filters.periodicity)
      setValue('keyword', openDatalistFilterRecurrenceMandate.filters.keyword)
      setValue('start', openDatalistFilterRecurrenceMandate.filters.start)
      setValue('end', openDatalistFilterRecurrenceMandate.filters.end)
    }
  }, [currentPage, openDatalistFilterRecurrenceMandate.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterRecurrenceMandate.filters])

  const onSubmit: SubmitHandler<FilterRecurrenceMandateInterface> = data => {
    dispatch(setOpenDatalistFilterRecurrenceMandate({
        show: false,
        count: openDatalistFilterRecurrenceMandate.count,
        filters: {
          product: data.keyword,
          status: data.status,
          periodicity: data.periodicity,
          start: data.start,
          end: data.end,
          keyword: data.keyword
        }
      }
    ))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterRecurrenceMandate({show: true, count: openDatalistFilterRecurrenceMandate.count, filters: openDatalistFilterRecurrenceMandate.filters}))
    }
  }

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterRecurrenceMandate( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        status: [],
        periodicity: [],
        start: '',
        end: '',
        keyword: '',
        code: '',
      }
    }))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new RecurrenceMandateGateway().getExportAsBlob(openDatalistFilterRecurrenceMandate.filters).then(response => {
      if (response) {
        downloadBlobFile(response, t('export.recurrence-mandates'))
      }
    }).finally(() => {
      setLoadingExport(false)
    })
  }

  const handleClickEdit = (mandateId: string | null, readOnly: boolean | null) => {
    if (!mandateId) return

    dispatch(setOpenEditRecurrenceMandate({
      show: true,
      mandateId: mandateId,
      readOnly: readOnly ?? false
    }))
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form onSubmit={handleSubmit(onSubmit)} className="filter">
            <div className="filter__input">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
            </div>
            <div className="filter__actions">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
            </div>
          </form>
          <HeaderRight numberOfActivatedFilters={openDatalistFilterRecurrenceMandate.count}
                       handleClickFilter={handleClickFilter}
                       handleClickExport={handleClickExport}
                       isLoadingExport={isLoadingExport}
                       allowExport={recurrenceRules?.export}
          />
        </div>
        <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                      name="numberRows"
                      label={t('filters.display-results-by')}
                      options={optionsNbRows}
                      register={register}
                      noChoiceOption
        />
        <div className="table-fix-head">
        <table className="datalist__datas">
          <thead>
          {viewModel.heading && <TableHead typeFilter={"API"}
                                           heading={viewModel.heading}
                                           sortOrder={sortOrder}
                                           setSortOrder={setSortOrder}
                                           viewModel={viewModel}
                                           setViewModel={setViewModel}
                                           filter={openDatalistFilterRecurrenceMandate.filters}
                                           watchNumberRows={watchNumberRows}
                                           currentPage={currentPage}
                                           listRequest={ListRequest}
                                           listUseCase={ListUseCase}
                                           listPresenter={RecurrenceMandateListPresenter}
                                           gateway={RecurrenceMandateGateway}
          />}
          </thead>
          <tbody>
          {viewModel?.data.map((item: RecurrenceMandateInterface) => (
            <tr key={uuidV4()}>
              {item !== null && item !== undefined &&
                <>
                  <td>
                      {recurrenceRules?.read && 
                        <button type="button" className="button-reset" onClick={() => handleClickEdit(item.id, true)}>
                          <img src={iconSearch} alt=""/>
                        </button>
                      }
                      {
                        recurrenceRules?.update &&
                        <button type="button" className="button-reset" onClick={() => handleClickEdit(item.id, false)}>
                            <img src={iconEdit} alt=""/>
                        </button>
                      }
                    </td>
                  <td>{item.createdAt}</td>
                  <td>{item.validatedAt}</td>
                  <td>
                    <div className="u-mys">
                      {item.product?.id && <div
                        className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`}>
                        <div className="badge__container">
                          <div
                            className="badge__text">{item.product.label}</div>
                        </div>
                      </div>
                      }
                    </div>
                  </td>
                  <td>{item.investor?.code}</td>
                  <td>{item.investor?.firstname?.toUpperCase().slice(0, 1)}. {item.investor?.lastname?.toUpperCase()}</td>
                  <td>
                    <div className={`badge etat etat--${item.status} badge--shadow u-mrs`}>
                      <div className="badge__text etat-small">{getLabelByValue(item.status ?? '', referential?.recurrence.mandate_status ?? [])}</div>
                    </div>
                  </td>
                  <td>{getLabelByValue(item.periodicity ?? '', referential?.recurrence.session_periodicity ?? [])}</td>
                  <td>{item.start}</td>
                  <td>{item.end}</td>
                  <td className={`u-txt-right`}>{item.recurrenceMode === 'price' ? '-' : item.recurrenceShare}</td>
                  <td className={`u-txt-right`}>{item.recurrenceMode === 'price' ? item.recurrencePrice : '-'}</td>
                </>
              }
            </tr>
          ))}
          {viewModel?.data.length === 0 &&
            <tr>
              <td colSpan={ viewModel?.heading.length }>{ t('common.data-is-empty') }</td>
            </tr>
          }
          </tbody>
        </table>
        </div>
      </div>
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default RecurrenceMandateDatalist
