import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'

import InputDate from '../../../../Elements/InputDate'
import SelectCustom from '../../../../Elements/Select'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import {IFormInputGeneralInformationPhysicalPerson} from '../type'
import Checkbox from '../../../../Elements/Checkbox'

type Props = {
  referential: ReferentielInterface
  indexPerson: number
}

const KycForm: FunctionComponent<Props> = ({referential, indexPerson}) => {
  const { t } = useTranslation()
  const { register, control } = useFormContext()

  const getKycRegister = (name: string, index: number)  => {
    if (index === 1) {
      return `${name}Second` as keyof IFormInputGeneralInformationPhysicalPerson
    }

    return name as keyof IFormInputGeneralInformationPhysicalPerson
  }

  return (
    <div className="col-md-6">
      <div className="flex-container">
        <div className="col-md-12">
          {t('account.form.person')} {indexPerson + 1}
        </div>
        <SelectCustom classes="col-md-8" id={getKycRegister("kycCategoryValue", indexPerson)}
                      name={getKycRegister("kycCategoryValue", indexPerson)}
                      label={t('account.form.kyc.kycCategoryValue')}
                      options={referential.prospect.kyc_category_value}
                      register={register}
        />
        <div className="col-md-4">
          <div className="form-control">
            <label htmlFor="kycCategoryDate"
                   className="form-control__label">{t('account.form.kyc.kycCategoryDate')}</label>
            <div className="form-control__input">
              <InputDate
                id={getKycRegister("kycCategoryDate", indexPerson)}
                name={getKycRegister("kycCategoryDate", indexPerson)}
                control={control}
                register={register}
              />
            </div>
          </div>
        </div>
        <SelectCustom classes="col-md-8" id={getKycRegister("kycLabft", indexPerson)}
                      name={getKycRegister("kycLabft", indexPerson)}
                      label={t('account.form.kyc.kycLabft')}
                      options={referential.prospect.kyc_labft}
                      register={register}
        />
        <div className="col-md-4">
          <div className="form-control">
            <label htmlFor={getKycRegister("kycLabftDate", indexPerson)}
                   className="form-control__label">{t('account.form.kyc.kycLabftDate')}</label>
            <div className="form-control__input">
              <InputDate id={getKycRegister("kycLabftDate", indexPerson)}
                         name={getKycRegister("kycLabftDate", indexPerson)}
                         control={control}
                         register={register}
              />
            </div>
          </div>
        </div>
        <SelectCustom classes="col-md-8" id={getKycRegister("kycProfession", indexPerson)}
                      name={getKycRegister("kycProfession", indexPerson)}
                      label={t('account.form.kyc.kycProfession')}
                      options={referential.global.profession}
                      register={register}
        />
        <div className="col-md-4">
          <Checkbox id={getKycRegister("kycFatca", indexPerson)} name={getKycRegister("kycFatca", indexPerson)}
                    register={register} label={t('account.form.kyc.kycFatca')}
                    align={'normal'}/>
        </div>
        <SelectCustom classes="col-md-8" id={getKycRegister("groupRelation", indexPerson)}
                      name={getKycRegister("groupRelation", indexPerson)}
                      label={t('account.form.kyc.groupRelation')}
                      options={referential.prospect.relation}
                      register={register}
        />
      </div>
    </div>
  )
}

export default KycForm
