import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabRecurrence from "../../../component/Tab/Recurrence/TabRecurrence";
import {useNavigate} from "react-router-dom";
import RecurrenceMandateDatalist from "../../../component/Datalist/Recurrence/RecurrenceMandateDatalist";
import RecurrenceMandateDatalistFilter from "../../../component/Sidebar/Recurrence/Mandate/RecurrenceMandateDatalistFilter";
import Edit from '../../../component/Sidebar/Recurrence/Mandate/Edit'


const RecurrenceMandateList: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const recurrenceRules = useAppSelector((state) => state.me.me?.rules.recurrence)

  if(!recurrenceRules?.visible) navigate('/')

  return (
    <>
      <RecurrenceMandateDatalistFilter/>
      <Edit />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
      <HeaderPage
          title={t('recurrence.title')}
        />
        <TabRecurrence/>
        <RecurrenceMandateDatalist/>
      </main>
    </>
  )
}

export default RecurrenceMandateList
