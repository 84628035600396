import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'

import InputDate from '../../../../Elements/InputDate'
import { ReferentielInterface } from '../../../../../../../domain/Referentiel/ReferentielInterface'
import SelectCustom from '../../../../Elements/Select'

type Props = {
  referential: ReferentielInterface
  setFormErrors: React.Dispatch<React.SetStateAction<string[]>>
}

const StateBlock: FunctionComponent<Props> = ({referential, setFormErrors}) => {
  const { t } = useTranslation()
  const { register, control, watch } = useFormContext()

  const watchStatus = watch('status')

  useEffect(() => {
    checkValidity()
  }, [watchStatus ])

  const checkValidity = () => {
    setFormErrors((prevErrors) => {
      const errorKey = 'account.form.status.status'
      const hasError = prevErrors.includes(errorKey)

      if (!watchStatus && !hasError) {
        return [...prevErrors, errorKey]
      } else if (watchStatus && hasError) {
        return prevErrors.filter(error => error !== errorKey)
      }
      return prevErrors
    })
  }

  return (
    <div className={`form-bloc form--bloc--status`}>
      <div className="form-bloc__title">{t('account.form.status.title')}</div>
      <div className="form-bloc__form flex-container">
        <div className="col-md-3">
          <div className="form-control">
            <label htmlFor="statusCreatedAt"
                   className="form-control__label">{t('account.form.status.statusCreatedAt')}</label>
            <div className="form-control__input">
              <InputDate id="statusCreatedAt"
                         name="statusCreatedAt"
                         register={register}
                         control={control}
                         disabled={true}
              />
            </div>
          </div>
        </div>
        <SelectCustom classes="col-md-3" id={`status`}
                      name={`status`}
                      label={t('account.form.status.status')}
                      options={referential.prospect.status}
                      register={register}
        />
        <div className="col-md-3">
          <div className="form-control">
            <label htmlFor="exitedAt" className="form-control__label">{t('account.form.status.exitedAt')}</label>
            <div className="form-control__input">
              <InputDate id="exitedAt"
                         name="exitedAt"
                         register={register}
                         control={control}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-control">
            <label htmlFor="blockedAt" className="form-control__label">{t('account.form.status.blockedAt')}</label>
            <div className="form-control__input">
              <InputDate id="blockedAt"
                         name="blockedAt"
                         register={register}
                         control={control}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StateBlock
