import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import DocumentGatewayInterface from "../../../domain/Document/DocumentGatewayInterface";
import {
  DocumentListInterface,
  DocumentToSend,
  DocumentToViewInterface
} from "../../../domain/Document/Document";
import {DocumentInterface} from "../../../domain/Document/DocumentInterface";
import {
  DocumentProspectInterface,
  DocumentProspectToSend,
  DocumentProspectToViewInterface
} from "../../../domain/Prospect/Document/Document";

export default class DocumentGateway implements DocumentGatewayInterface {

  prospectId: string|null

  constructor(
    prospectId: string|null
  ) {
    this.prospectId = prospectId
  }

  getFiles(): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospects/${this.prospectId}/files`, {})
    .then(data => {
      if (data) {
        return data.data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  getFile(fileId: number): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospects/${this.prospectId}/files/${fileId}`, {})
    .then(data => {
      if (data) {
        return data.content
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  save(uuid: string, documents: DocumentToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/prospects/${uuid}/files`, {
      files: documents
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  getRibFiles(personId: string, ribId: string): Promise<DocumentProspectInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/rib/${ribId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getRibFile(personId: string, ribId: string, fileId: number): Promise<DocumentProspectToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/rib/${ribId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveRib(personId: string, ribId: string, documents: DocumentProspectToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/rib/${ribId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getUndividedFiles(personId: string, ribId: string): Promise<DocumentProspectInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/undivided/${ribId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getUndividedFile(personId: string, ribId: string, fileId: number): Promise<DocumentProspectToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/undivided/${ribId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveUndivided(personId: string, ribId: string, documents: DocumentProspectToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/undivided/${ribId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getBeneficialOwnerFiles(personId: string, ribId: string): Promise<DocumentProspectInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/beneficial-owner/${ribId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getBeneficialOwnerFile(personId: string, ribId: string, fileId: number): Promise<DocumentProspectToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/beneficial-owner/${ribId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveBeneficialOwner(personId: string, ribId: string, documents: DocumentProspectToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/beneficial-owner/${ribId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getMoralLegalRepresentativeFiles(personId: string, ribId: string): Promise<DocumentProspectInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/moral-legal-representative/${ribId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getMoralLegalRepresentativeFile(personId: string, ribId: string, fileId: number): Promise<DocumentProspectToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/moral-legal-representative/${ribId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveMoralLegalRepresentative(personId: string, ribId: string, documents: DocumentProspectToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/accounts/${personId}/moral-legal-representative/${ribId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getList(): Promise<DocumentListInterface[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospect/${this.prospectId}/documents`, {})
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(() => {
      return null
    })
  }
  getHistory(signal?: AbortSignal): Promise<DocumentInterface[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts/${this.prospectId}/history/files`, {}, signal)
    .then(data => {
      if (data) {
        return data.data
      }

      return []
    }).catch(response => {
      return []
    });
  }
  deleteFile(fileId: number): Promise<boolean> {
    //TODO: Fonctionnalitée uniquement disponible pour les presouscription https://myrem.atlassian.net/browse/DS-630?atlOrigin=eyJpIjoiMzJkNzMxN2Y0ZGMyNGVhMDljMDQ1NzBhM2Y1OGY2NjYiLCJwIjoiaiJ9
    return Promise.resolve(false)
  }
}
