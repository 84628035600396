import {Presenter} from '../Presenter'
import {ProspectListInterface} from '../../domain/Prospect/ProspectList'

class CustomerListWithProductPresenter extends Presenter {

  private useCase: { getProspects: any }

  constructor(getProspects: any) {
    super({
      viewModel: {
        title: 'account.search-a-customer',
        heading: [
          {name: 'account.heading.Action'},
          {name: 'account.heading.customerId', sort: 'account_code'},
          {name: 'account.heading.lastName', sort: 'lastname'},
          {name: 'account.heading.maidenName', sort: 'maiden_name'},
          {name: 'account.heading.firstName', sort: 'firstname'},
          {name: 'account.heading.socialReason', sort: 'company_name'},
          {name: 'account.heading.products', sort: 'movement.label'},
          {name: 'account.heading.city', sort: 'city'},
          {name: 'account.heading.enabled', sort: 'status'},
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-lastname', 'field': 'lastName', 'type': 'input'},
          {'keyword': 'filters.by-maidenName', 'field': 'maidenName', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'},
          {'keyword': 'filters.by-birthDate', 'field': 'birthDate', 'type': 'input'}
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getProspects,
    }
  }

  async load() {
    try {
      this._setProspects(await this.useCase.getProspects);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setProspects(prospects: { data: ProspectListInterface[]|null, nb_element: number }) {
    this.update({
      data: prospects.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': prospects.nb_element,
      }
    });
  }
}

export default CustomerListWithProductPresenter
