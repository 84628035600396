import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { addBeneficialBeneficiaries, updateBeneficialBeneficiaries } from '../../../../store/component/partner';
import { BeneficialBeneficiaries } from '../../../../../domain/Partner/BeneficialBeneficiaries';
import blockScrollBody from '../../../util/BlockScroll';
import getClassForOverlay from '../../../util/Sidebar';
import { setOpenPartnerAddAddressEvent, setOpenPartnerBeneficialBeneficiariesFormEvent } from '../../../../store/component/event';
import {
  ReferentielInterface
} from '../../../../../domain/Referentiel/ReferentielInterface'
import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';
import { Person } from '../../../../../domain/Partner/Person';
import { Address } from '../../../../../domain/Partner/Address';
import { City } from '../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import CityDatalist from '../../Datalist/City/CityDatalist';
import CountryDatalist from '../../Datalist/Country/CountryDatalist';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { toastSuccess } from '../../../util/Toast';
import { usePrompt } from '../../../util/Navigation';
import SelectCustom from '../../Elements/Select';
import InputDate from '../../Elements/InputDate';
import CityAndPostcode from '../../Datalist/CityAndPostcode/CityAndPostcode';
import Input from '../../Elements/Input'
import CheckboxToggle from '../../Elements/CheckboxToggle'

interface IFormInput {
  id: string
  ppe: boolean
  ppeCategory: string|null
  shareholding: string
  personId: string
  title: string|null
  status: string|null
  lastName: string|null
  maidenName: string|null
  firstName: string|null
  birthdate: string|null
  birthCountry: Country|null
  birthCity: City|null
  nationality: Country|null
  addressId: string
  addressFirstLine: string|null
  addressSecondLine: string|null
  addressThirdLine: string|null
  zipcode: string|null
  city: City|null
  country: Country|null
}

type Props = {
  referential: ReferentielInterface
}

const AddBeneficialBeneficiaries: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const openPartnerBeneficialBeneficiaries = useAppSelector((state) => state.event.openPartnerBeneficialBeneficiariesForm)

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const watchCivility = watch('title');
  const watchPpe = watch('ppe');

  useEffect(() => {
    blockScrollBody(openPartnerBeneficialBeneficiaries.show)

    if (openPartnerBeneficialBeneficiaries.beneficialBeneficiaries) {
      setValue('id', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.id || '')
      setValue('ppe', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries.ppe)
      setValue('ppeCategory', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.ppeCategory || null)
      setValue('shareholding', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries.shareholding)
      setValue('personId', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.id || '')
      setValue('title', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.title || null)
      setValue('status', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries.status)
      setValue('lastName', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.lastname || null)
      setValue('maidenName', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.maidenname || null)
      setValue('firstName', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.firstname || null)
      setValue('birthdate', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.birthdate || null)
      // setValue('birthCountry', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.birthCountry || null)
      setValue('birthCity', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.birthCity || null)
      // setValue('nationality', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.nationality || null)
      setValue('addressId', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.id || '')
      setValue('addressFirstLine', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.addressFirstLine || null)
      setValue('addressSecondLine', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.addressSecondLine || null)
      setValue('addressThirdLine', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.addressThirdLine || null)
      setValue('zipcode', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.zipcode || null)
      setValue('city', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.city || null)
      setValue('country', openPartnerBeneficialBeneficiaries.beneficialBeneficiaries?.person?.address?.country || null)

    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('personId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('title', null)
      setValue('status', null)
      setValue('lastName', null)
      setValue('maidenName', null)
      setValue('firstName', null)
      setValue('birthdate', null)
      // setValue('birthCountry', null)
      setValue('birthCity', null)
      // setValue('nationality', null)
      setValue('addressId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('addressFirstLine', null)
      setValue('addressSecondLine', null)
      setValue('addressThirdLine', null)
      setValue('zipcode', null)
      setValue('city', null)
      setValue('country', null)
      setValue('ppe', false)
      setValue('shareholding', '')
    }
  }, [openPartnerBeneficialBeneficiaries])

  useEffect(() => {
    if (countryByDefault && openPartnerBeneficialBeneficiaries) {
      setValue('birthCountry', openPartnerBeneficialBeneficiaries?.beneficialBeneficiaries?.person?.birthCountry || countryByDefault[0]);
      setValue('nationality',  openPartnerBeneficialBeneficiaries?.beneficialBeneficiaries?.person?.nationality || countryByDefault[0]);
      setValue('country',  openPartnerBeneficialBeneficiaries?.beneficialBeneficiaries?.person?.address?.country || countryByDefault[0]);
    }
  }, [countryByDefault, openPartnerBeneficialBeneficiaries])

  useEffect(() => {
    if (!watchPpe) {
      setValue('ppeCategory', '')
    }
  }, [watchPpe])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const beneficialBeneficiaries = new BeneficialBeneficiaries(
      data.id,
      data.status,
      data.ppe,
      data.shareholding,
      new Person(
        data.personId,
        data.title,
        data.firstName,
        data.lastName,
        data.maidenName,
        data.birthdate,
        data.birthCity,
        data.birthCountry,
        data.nationality,
        data.ppe,
        data.ppeCategory,
        new Address(
          data.addressId,
          false,
          data.title || '',
          data.lastName  || '',
          data.maidenName  || '',
          data.firstName  || '',
          '',
          data.addressFirstLine  || '',
          data.addressSecondLine  || '',
          data.addressThirdLine  || '',
          data.zipcode  || '',
          data.city  || null,
          data.country  || null,
          false,
          null
        ),
        false,
        null,
        null
      )
    )

    if (openPartnerBeneficialBeneficiaries.beneficialBeneficiaries) {
      dispatch(updateBeneficialBeneficiaries(beneficialBeneficiaries))
      dispatch(setOpenPartnerAddAddressEvent({show: false, address: null}))
    } else {
      dispatch(addBeneficialBeneficiaries(beneficialBeneficiaries))
    }
    dispatch(setOpenPartnerBeneficialBeneficiariesFormEvent({show: false, beneficialBeneficiaries: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    setValue('addressId', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('partner.notify.add-beneficial-beneficiaries-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenPartnerBeneficialBeneficiariesFormEvent({show: false, beneficialBeneficiaries: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPartnerBeneficialBeneficiaries.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openPartnerBeneficialBeneficiaries.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.beneficial-beneficiaries.add')}</div>
            <div className="form-bloc__form flex-container">
              <SelectCustom classes="col-md-6"
                            label={t('partner.form.beneficial-beneficiaries.civility')}
                            options={referential.global.title_physic || null}
                            id="title"
                            name="title"
                            register={register}
              />
              <SelectCustom classes="col-md-6"
                            label={t('partner.form.beneficial-beneficiaries.status')}
                            options={referential.global.active || null}
                            id="status"
                            name="status"
                            register={register}
              />
              <Input id={`lastName`}
                     name={`lastName`}
                     label={t('partner.form.beneficial-beneficiaries.lastname')}
                     register={register}
                     classes={`col-md-12`}
                     required={true}
              />
              {watchCivility === 'mrs' &&
                <Input id={`maidenName`}
                       name={`maidenName`}
                       label={t('partner.form.beneficial-beneficiaries.maiden-name')}
                       register={register}
                       classes={`col-md-12`}
                />
              }
              <Input id={`firstName`}
                     name={`firstName`}
                     label={t('partner.form.beneficial-beneficiaries.firstname')}
                     register={register}
                     classes={`col-md-12`}
                     required={true}
              />
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="birthdate"
                         className="form-control__label">{t('partner.form.beneficial-beneficiaries.date-of-birth')}</label>
                  <div className="form-control__input">
                    <InputDate id="birthdate"
                               name="birthdate"
                               register={register}
                               control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="birthCountry"
                         className="form-control__label">{t('partner.form.beneficial-beneficiaries.country-of-birth')}</label>
                   <CountryDatalist control={control} name="birthCountry" defaultValue={getValues('birthCountry')}/>
                </div>
              </div>
              <div className="col-md-12">
                <CityDatalist control={control}
                                  setValue={setValue}
                                  name="birthCity"
                                  defaultValue={getValues('birthCity')}
                                  labelContent={t('partner.form.beneficial-beneficiaries.city-of-birth')}
                                  country={getValues('birthCountry')}
                                  classes="flex"
                                  mode="col"
                />
                  </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="nationality"
                         className="form-control__label">{t('partner.form.beneficial-beneficiaries.nationality')}</label>
                  <div className="form-control__input">
                    <CountryDatalist control={control} name="nationality" defaultValue={getValues('nationality')}/>
                  </div>
                </div>
              </div>

              <Input id={`addressFirstLine`}
                     name={`addressFirstLine`}
                     label={t('partner.form.beneficial-beneficiaries.address-first-line')}
                     register={register}
                     classes={`col-md-12`}
              />
              <Input id={`addressSecondLine`}
                     name={`addressSecondLine`}
                     label={t('partner.form.beneficial-beneficiaries.address-second-line')}
                     register={register}
                     classes={`col-md-12`}
              />
              <Input id={`addressThirdLine`}
                     name={`addressThirdLine`}
                     label={t('partner.form.beneficial-beneficiaries.address-third-line')}
                     register={register}
                     classes={`col-md-12`}
              />
              <CityAndPostcode control={control}
                               nameCity="city"
                               namePostcode="zipcode"
                               country={getValues('country')}
                               register={register}
                               setValue={setValue}
                               getValues={getValues}
                               mode="row"
                               classes="col-md-12 u-px0"
              />
              <div className="col-md-12">
                  <div className="form-control">
                      <label htmlFor="country"
                             className="form-control__label">{t('partner.form.beneficial-beneficiaries.country')}</label>
                      <div className="form-control__input">
                          <CountryDatalist control={control} name="country" defaultValue={getValues('country')}/>
                      </div>
                  </div>
              </div>
              <CheckboxToggle id={`ppe`}
                              name={`ppe`}
                              classes="col-md-12"
                              register={register}
                              label={t('partner.form.beneficial-beneficiaries.ppe')}
              />
              { referential && watchPpe &&
                  <div className="col-md-6">
                      <div className="form-control--label-max-content">
                          <SelectCustom classes="" id="ppeCategory" name="ppeCategory" options={referential.partner.ppe_category} register={register} label={t('account.form.manager.managerPpeCategory')} />
                      </div>
                  </div>
              }
              <Input id={`shareholding`}
                     name={`shareholding`}
                     label={t('partner.form.beneficial-beneficiaries.shareholding')}
                     register={register}
                     classes={`col-md-12`}
              />
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddBeneficialBeneficiaries
