import React, { FunctionComponent, useEffect, useState } from "react"
import InputDate from "../../Elements/InputDate"
import { ActionListInterface } from "../../../../../domain/Movement/Action/Action"
import TransactionGatewayInterface from "../../../../../domain/Movement/TransactionGatewayInterface"
import { useTranslation } from "react-i18next"
import {useForm, useFormContext} from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'
import dayjs from "dayjs"
import { toastError, toastSuccess } from "../../../util/Toast"
import CancelTransactionModal from "../../Modal/CancelTransactionModal"
import { reloadMovement } from "../../../../store/component/movement"
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { useCheckRole } from "../../../../../domain/CustomHooks/useCheckRole"
import envVariable from "../../../util/envVariable"
import iconEdit from '../../../../../assets/images/icons/datalist-edit.svg'
import iconEnabled from '../../../../../assets/images/icons/enabled.svg'
import iconDisabled from '../../../../../assets/images/icons/disabled.svg'

// Typage des props
interface Props {
  gateway: TransactionGatewayInterface
  transactionUuid: string
  uuidRefresh: string
  callback?: (actions: ActionListInterface) => void
  setFormError?: (error: any) => void
}

interface ActionEditInterface {
  engage: boolean
  verify: boolean
  confirm: boolean
  validate: boolean
  cancel: boolean
  invalidate: boolean
}

// Composant principal
const ActionsForm: FunctionComponent<Props> = ({ gateway, transactionUuid, uuidRefresh, callback, setFormError }) => {
  const { t } = useTranslation()
  const [actionArray, setActionArray] = useState<ActionListInterface | undefined>()
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const rolesLabel = useAppSelector((state) => state.me.me?.rolesLabel)
  const movementRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const roleIsAuthorized = useCheckRole("V2")
  const [actionEdit, setActionEdit] = useState<ActionEditInterface>({
    engage: false,
    verify: false,
    confirm: false,
    validate: false,
    cancel: false,
    invalidate: false,
  })

  const { handleSubmit } = useFormContext()
  const { register, control, watch, setValue, getValues } = useForm<ActionListInterface>()
  const watchCancelReason = watch('cancel.reason')

  useEffect(() => {
    gateway.getActions(transactionUuid).then((actions) => setActionArray(actions))
  }, [transactionUuid, uuidRefresh])

  useEffect(() => {
    if (actionArray) {
      Object.entries(actionArray).forEach(([key, value]) => {
        setValue(key as keyof ActionListInterface, value)
      })
      callback && callback(actionArray)
    }
  }, [actionArray, callback, setValue])

  const setAction = (action: keyof ActionListInterface) => {
    handleSubmit((formData) => {
      let date = getValues()[action]?.date
      const reason = getValues()[action]?.reason

      if (!date) {
        date = dayjs(new Date()).format('DD/MM/YYYY')
      }

      if (action === 'cancel' && !reason) {
        setValue('cancel.reason', null)
        setShowCancelModal(true)
        return
      }

      gateway.setAction(transactionUuid, action, date, reason).then((response) => {
        setFormError?.(null)
        setActionArray(response)
        dispatch(reloadMovement())
        toastSuccess(t('movement.notify.action-update'))
      }).catch((e) => {
        setFormError?.(e.data)
        toastError(t('movement.notify.action-error'))
      })
    })()
  }

  const cancelCancelAction = () => {
    setValue('cancel.reason', null)
    setShowCancelModal(false)
  }

  const checkRules = (actionName: keyof ActionListInterface): boolean | undefined => {
    switch (actionName) {
      case 'engage': return movementRules?.action_engage
      case 'verify': return movementRules?.action_verify
      case 'invalidate': return movementRules?.action_invalidate
      case 'confirm': return movementRules?.action_confirm
      case 'validate': return movementRules?.action_validate
      case 'cancel': return movementRules?.action_cancel
    }
  }

  const disabledAccordingRoles = (actionName: keyof ActionListInterface, actionEnabled: boolean): string => {
    if (actionName === 'validate' && envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT')) return "disabled-content"
    if (actionEnabled && rolesLabel?.length) {
      if (['invalidate', 'confirm', 'validate'].includes(actionName) && !roleIsAuthorized) {
        return "disabled-content"
      }
      return ""
    }
    return "disabled-content"
  }

  const disableAccordingWorkflow = (actionName: keyof ActionListInterface, actionEnabled: boolean): string => {
    if (!roleIsAuthorized) return "disabled-content"
    if (!actionEnabled && !actionEdit[actionName]) return "disabled-content"
    if (actionName === 'validate' && envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT')) return "disabled-content"
    return ''
  }

  const editAction = (action: keyof ActionEditInterface, value = true) => {
    setActionEdit((prev) => ({ ...prev, [action]: value }))
  }

  const updateActionDate = (actionName: keyof ActionEditInterface) => {
    const date = getValues()[actionName]?.date

    if (!date) {
      toastError(t('movement.notify.action-error-date-empty'))
      return;
    }

    editAction(actionName, false)
    gateway.updateAction(transactionUuid, actionName, date).then((response) => {
      setFormError?.(null)
      setActionArray(response)
      dispatch(reloadMovement())
      toastSuccess(t('movement.notify.action-update'))
    }).catch((e) => {
      setFormError?.(e.data)
      toastError(t('movement.notify.action-error'))
    })
  }

  const isEditable = (actionName: keyof ActionEditInterface): boolean => {
    if (actionName === 'validate' && envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT')) {
      return false
    }
    if (actionName === 'invalidate' && (actionArray?.confirm.enabled || actionArray?.validate.enabled)) {
      return false
    }
    return actionName !== 'cancel' && !actionEdit[actionName]
  }

  return (
    <>
      <CancelTransactionModal
        register={register}
        name={'cancel.reason'}
        onConfirm={() => setAction('cancel')}
        onClose={cancelCancelAction}
        show={showCancelModal}
        cancelValue={watchCancelReason?.toString()}
      />
      <div className="form-bloc form--bloc--action">
        <div className="form-bloc__title">{t('movement.pre-subscription.form.actions.title')}</div>
        <div className="form-bloc__form flex-container">
          {actionArray && Object.entries(actionArray).map(([actionName, actionItem]) => (
            <div key={uuidV4()} className="col-md-6 movement-actions">
              <button
                type="button"
                className={`button button--white ${disabledAccordingRoles(actionName as keyof ActionListInterface, actionItem.enabled)}`}
                onClick={() => setAction(actionName as keyof ActionListInterface)}
              >
                {t(`movement.pre-subscription.form.actions.${actionName}`)}
              </button>
              <InputDate
                register={register}
                control={control}
                classes={`movement-actions__date ${disableAccordingWorkflow(actionName as keyof ActionListInterface, actionItem.enabled)}`}
                type="text"
                name={`${actionName}.date`}
                id={actionName}
              />
              {isEditable(actionName as keyof ActionEditInterface) &&
                <button onClick={() => editAction(actionName as keyof ActionEditInterface)} type="button" className="button-reset">
                  <img src={iconEdit} alt="edit icon" />
                </button>
              }
              {actionEdit[actionName as keyof ActionEditInterface] &&
                <div className="flex-container">
                  <button onClick={() => updateActionDate(actionName as keyof ActionEditInterface)} type="button" className="button-reset">
                    <img src={iconEnabled} alt="edit icon" />
                  </button>
                  <button onClick={() => editAction(actionName as keyof ActionEditInterface, false)} type="button" className="button-reset">
                    <img src={iconDisabled} alt="edit icon" />
                  </button>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ActionsForm
