import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IAction {
  create: boolean
  export: boolean
  list: boolean
  read: boolean
  update: boolean
  action_engage?: boolean,
  action_cancel?: boolean,
  action_verify?: boolean,
  action_confirm?: boolean,
  action_validate?: boolean,
  action_invalidate?: boolean
}

interface IRules {
  customer: {
    actions: IAction
    visible: boolean
  },
  partner: {
    actions: IAction
    visible: boolean
  },
  product: {
    actions: IAction
    visible: boolean
  },
  prospect: {
    actions: IAction
    visible: boolean
  },
  transaction: {
    actions: IAction
    visible: boolean
  },
  user: {
    actions: IAction
    visible: boolean
  },
  subscription: {
    actions: IAction
    visible: boolean
  },
  commissioning: {
    actions: IAction
    visible: boolean
  },
  setting: {
    actions: {
      update: boolean
    }
    visible: boolean
  }
  recurrence: {
    actions: IAction
    visible: boolean
  },
  distribution: {
    actions: IAction
    visible: boolean
  }
}

interface Me {
  id: string,
  email: string,
  roles: string[],
  rolesLabel: string[],
  firstname: string,
  lastname: string,
  rules: IRules
}

interface event {
  me: Me|null
  bubbleAnimation: {
    state: boolean|null
    stateDefault: boolean|null
  }
}

const initialState: event = {
  me: null,
  bubbleAnimation: {
    state: null,
    stateDefault: null
  }
}

export const meSlice = createSlice({
  name: 'me',
  initialState: initialState,
  reducers: {
    setMe: (state, action: PayloadAction<Me>) => {
      const me = action.payload
      if(action.payload.roles && action.payload.roles.find((role) => role === "ROLE_SUPER_ADMIN")) {
        me.rules = {
          customer: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          partner: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          product: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          prospect: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          transaction: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true,
              action_engage: true,
              action_cancel: true,
              action_verify: true,
              action_confirm: true,
              action_validate: true,
              action_invalidate: true,
            },
            visible: true
          },
          user: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          subscription: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          commissioning: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          setting: {
            actions: {
              update: true
            },
            visible: true
          },
          recurrence: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
          distribution: {
            actions: {
              create: true,
              export: true,
              list: true,
              read: true,
              update: true
            },
            visible: true
          },
        }
      }
      state.me = me
    },

    setBubbleAnimation: (state, action: PayloadAction<boolean>) => {
      state.bubbleAnimation.state = action.payload
    },
    setBubbleAnimationByDefault: (state, action: PayloadAction<boolean>) => {
      state.bubbleAnimation.stateDefault = action.payload
    }
  }
})

export const {
  setMe,
  setBubbleAnimation,
  setBubbleAnimationByDefault
} = meSlice.actions

export default meSlice.reducer
