import React from 'react'
import {Controller, Control, FieldValues, FieldError} from 'react-hook-form'
import DatePicker, {ReactDatePickerProps, registerLocale} from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import FieldErrorMessage from "../FieldErrorMessage/FieldErrorMessage";
registerLocale("fr", fr);

interface ControlledDatePickerProps extends Omit<ReactDatePickerProps, 'selected' | 'onChange'> {
  control: any
  name: string
  dateFormat?: string
  minDate?: Date
  maxDate?: Date
  errorMessage?: string
}

const ControlledDatePicker: React.FC<ControlledDatePickerProps> = ({ control, name, errorMessage, ...props }) => {
  return (
    <div>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              {...props}
            />
          )}
        />
        {errorMessage && <FieldErrorMessage message={errorMessage}/>}
    </div>
  )
}

export default ControlledDatePicker;
