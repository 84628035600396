import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Controller, SubmitHandler, useForm, useFormState} from 'react-hook-form'
import Select from 'react-select'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenBankInformationFormEvent} from '../../../../store/component/event'
import {addBankInformation, updateBankInformation} from '../../../../store/component/prospect'

import {
  BankInformation,
  BankInformationInterface
} from '../../../../../domain/Prospect/BankInformation'

import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'

import AlertInfo from '../../Alert/AlertInfo/AlertInfo'
import ProductGateway from "../../../../../gateway/Product/ProductGateway";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastSuccess} from "../../../util/Toast";
import {usePrompt} from "../../../util/Navigation";
import Iban from "../../Elements/Iban";
import Bic from "../../Elements/Bic";
import RadioChoices from "../../Elements/RadioChoices";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import SelectCustom from "../../Elements/Select";
import MovementBadge from "../../Badge/MovementBadge";

interface IFormInput {
  bankInformation: BankInformationInterface
  products: {
    id: string|null,
    label: string|null,
    value: string|null
  }[]
}

interface ProductReferential {
  id: string
  value: string
  label: string
}

type Props = {
  referential: ReferentielInterface
}

const STATUS_ACTIVE = 'active';

const AddBankInformationForm: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openBankInformationForm = useAppSelector((state) => state.event.openBankInformationForm)
  const bankInformationArray = useAppSelector((state) => state.prospect.bankInformationArray)
  const [alreadyHaveMainRIB, setAlreadyHaveMainRIB] = useState<boolean>(false)
  const [products, setProducts] = useState<ProductReferential[]>([])
  const [productsBank, setProductsBank] = useState<ProductReferential[]>([])
  const [enableValidation, setEnabledValidation] = useState<boolean>(true)
  const bankInformationItemMain = bankInformationArray.filter(bankInformationItem => bankInformationItem.main)

  const onTypeChange = (type: string) => {
    prepareListOfProducts(type)
    setEnabledValidation(type === 'current_account')
    const mainWithSameType = bankInformationItemMain.filter((item) => item.type == type)

    if (
      (mainWithSameType.length > 0 && (!openBankInformationForm?.bankInformation?.main ?? false)) ||
      (mainWithSameType.length > 0 && (openBankInformationForm?.bankInformation?.main ?? false) && (openBankInformationForm?.bankInformation?.type !== type ?? false))
    ) {
      setValue('bankInformation.main', false)
      return setAlreadyHaveMainRIB(true)
    }

    return setAlreadyHaveMainRIB(false)
  }

  const prepareListOfProducts = (type: string|null = 'securities_account') => {
    let listOfProducts = productsBank
    if (type === 'current_account') {
      bankInformationArray.filter((item) => item.type === type).map((bankInformation: BankInformationInterface) => {
        listOfProducts = listOfProducts.filter((product: ProductReferential) => {
          if (openBankInformationForm.bankInformation?.products) {
            return (!bankInformation.products?.includes(product.id) || openBankInformationForm.bankInformation.products.includes(product.id))
          }

          return !bankInformation.products?.includes(product.id)
        })
      })
    }

    setProducts(listOfProducts)
  }

  useEffect(() => {
    new ProductGateway().getAll().then((data) => {
      setProductsBank(data)
    })
  }, [])

  useEffect(() => {
    if (openBankInformationForm.bankInformation && openBankInformationForm.bankInformation.type !== null) {
      onTypeChange(openBankInformationForm.bankInformation.type)
    }

    prepareListOfProducts(null)
  }, [bankInformationArray, openBankInformationForm])

  useEffect(() => {
    blockScrollBody(openBankInformationForm.show)

    if (openBankInformationForm.bankInformation) {

      const productsValues: {id: string, label: string, value: string}[] = []
      productsBank.map((productItem: {id: string, label: string, value: string}) => {
        if (productItem.value) {

          if (openBankInformationForm.bankInformation?.products?.includes(productItem.value)) {
            productsValues.push(productItem)
          }
        }
      })
      setValue('products', productsValues)
      setValue('bankInformation.id', openBankInformationForm.bankInformation.id)
      setValue('bankInformation.createdAt', openBankInformationForm.bankInformation.createdAt)
      setValue('bankInformation.updatedAt', openBankInformationForm.bankInformation.updatedAt)
      setValue('bankInformation.deletedAt', openBankInformationForm.bankInformation.deletedAt)
      setValue('bankInformation.label', openBankInformationForm.bankInformation.label)
      setValue('bankInformation.main', openBankInformationForm.bankInformation.main)
      setValue('bankInformation.bank', openBankInformationForm.bankInformation.bank)
      setValue('bankInformation.iban', openBankInformationForm.bankInformation.iban)
      setValue('bankInformation.swift', openBankInformationForm.bankInformation.swift)
      setValue('bankInformation.debitMandate', openBankInformationForm.bankInformation.debitMandate)
      setValue('bankInformation.file', openBankInformationForm.bankInformation.file)
      setValue('bankInformation.products', openBankInformationForm.bankInformation.products)
      setValue('bankInformation.flux', openBankInformationForm.bankInformation.flux)
      setValue('bankInformation.type', openBankInformationForm.bankInformation.type)
      setValue('bankInformation.status', openBankInformationForm.bankInformation.status)

    } else {
      setValue('products', [])

      setValue('bankInformation.id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('bankInformation.createdAt', null)
      setValue('bankInformation.updatedAt', null)
      setValue('bankInformation.deletedAt', null)
      setValue('bankInformation.label', null)

      if (!alreadyHaveMainRIB) {
        setValue('bankInformation.main', true)
      } else {
        setValue('bankInformation.main', null)
      }

      setValue('bankInformation.bank', null)
      setValue('bankInformation.iban', null)
      setValue('bankInformation.swift', null)
      setValue('bankInformation.debitMandate', null)
      setValue('bankInformation.file', null)
      setValue('bankInformation.products', null)
      setValue('bankInformation.flux', null)
      setValue('bankInformation.type', null)
      setValue('bankInformation.status', STATUS_ACTIVE)
    }

  }, [openBankInformationForm.show])

  const { register, control, handleSubmit, reset, setValue, getValues, setError, formState: { errors, isValid}, clearErrors } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: IFormInput) => {
    reset()
    setValue('products', [])
    const productArray:string[] = []
    if (data.products) {
      data.products.map((productItem) => {
        if (productItem.value) {
          productArray.push(productItem.value)
        }
      })
    }

    const bankInformation = new BankInformation(
      data.bankInformation.id,
      null,
      null,
      null,
      data.bankInformation.label,
      data.bankInformation.main,
      data.bankInformation.bank,
      data.bankInformation.iban,
      data.bankInformation.swift,
      data.bankInformation.debitMandate,
      null,
      productArray,
      data.bankInformation.type,
      data.bankInformation.flux,
      data.bankInformation.transactions,
      data.bankInformation.status
    )

    if (openBankInformationForm?.bankInformation?.transactions &&
      openBankInformationForm.bankInformation.transactions.length > 0
    ) {
      bankInformation.status = STATUS_ACTIVE;
    }

    if (bankInformation.status !== STATUS_ACTIVE &&
      (!openBankInformationForm?.bankInformation?.transactions ||
        (openBankInformationForm?.bankInformation?.transactions && openBankInformationForm.bankInformation.transactions.length == 0)
      )
    ) {
      bankInformation.main = false;
    }

    if (openBankInformationForm.bankInformation) {
      dispatch(updateBankInformation(bankInformation))

    } else {
      dispatch(addBankInformation(bankInformation))
    }
    dispatch(setOpenBankInformationFormEvent({show: false, bankInformation: null}))

    reset()
    setValue('bankInformation.id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    toastSuccess(t('account.notify.add-bankInformation-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    dispatch(setOpenBankInformationFormEvent({show: false, bankInformation: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openBankInformationForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openBankInformationForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--prospect-bankInformation-form">
        <div className="sidebar__content">
        <div className="title">{t('account.form.bank-details.add')}</div>
          <div className="col-md-6">
            <div className="flex-container">
              <RadioChoices classes={'col-md-12'}
                            id="bankInformation.type" name="bankInformation.type"
                            register={register}
                            options={referential.prospect.bank_type}
                            label={t('account.form.bank-details.type')}
                            labelClasses='mandatory-field'
                            required={true}
                            onChange={(e) => {onTypeChange(e.currentTarget.value)}}
              />
              {alreadyHaveMainRIB &&
              <div className="col-md-12">
                <AlertInfo text={t('account.form.bank-details.main-already-exist')} />
              </div>
              }
              {!!openBankInformationForm?.bankInformation &&
                Array.isArray(openBankInformationForm.bankInformation?.transactions) &&
                openBankInformationForm.bankInformation.transactions.length > 0 &&
                  <div className="col-md-12">
                      <AlertInfo text={t('account.form.bank-details.already-used')}/>
                  </div>
              }
              <div className="col-md-6">
                <div className="form-control form-control--center">
                  <label htmlFor="main" className="form-control__label">{t('account.form.bank-details.main')}</label>
                  <div className="checkbox-toggle__wrapper">
                    <input type="checkbox" {...register('bankInformation.main')} id="main" className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default" disabled={alreadyHaveMainRIB}/>
                    <label className="checkbox-toggle__button" htmlFor="main"/>
                  </div>
                </div>
              </div>
              <SelectCustom classes={`col-md-6`}
                            id={`bankInformation.status`}
                            name={`bankInformation.status`}
                            options={referential.global.rib_status}
                            register={register}
                            label={t('account.form.bank-details.status')}
                            noChoiceOption
                            readOnly={!!openBankInformationForm?.bankInformation &&
                              Array.isArray(openBankInformationForm.bankInformation?.transactions) &&
                              openBankInformationForm.bankInformation.transactions.length > 0
                            }
              />
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="bankInformation.flux" className="form-control__label mandatory-field">{t('account.form.bank-details.flux')}</label>
                  <div className="form-control__input">
                    <select required={true} {...register('bankInformation.flux' , {required: true})}>
                      {referential.prospect.bank_flux.map((item, index) => <option key={index}
                                                                                              value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="label" className="form-control__label mandatory-field">{t('account.form.bank-details.owner-name')}</label>
                  <div className="form-control__input">
                    <input type="text" id="label" {...register('bankInformation.label', {required: true})} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="bank" className="form-control__label mandatory-field">{t('account.form.bank-details.bank')}</label>
                  <div className="form-control__input">
                    <input type="text" id="bank" {...register('bankInformation.bank', {required: true})} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <Iban
                  label={t('account.form.bank-details.iban')}
                  id={'iban'}
                  required={true}
                  name={'bankInformation.iban'}
                  error={errors?.bankInformation?.iban}
                  register={register}
                  enableValidation={enableValidation}
                  labelClasses={'mandatory-field'}
                />
              </div>
              <div className="col-md-12">
                <Bic
                  label={t('account.form.bank-details.swift')}
                  id={'swift'}
                  required={true}
                  name={'bankInformation.swift'}
                  register={register}
                  enableValidation={enableValidation}
                  labelClasses={'mandatory-field'}

                />
              </div>
              <div className="col-md-6">
                <div className="form-control form-control--center">
                  <label htmlFor="debitMandate" className="form-control__label">{t('account.form.bank-details.debitMandate')}</label>
                  <div className="checkbox-toggle__wrapper">
                    <input type="checkbox" {...register('bankInformation.debitMandate')} id="debitMandate" className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default" />
                    <label className="checkbox-toggle__button" htmlFor="debitMandate"/>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-6`}>
              <div className="form-control">
                <label htmlFor={`generalInformation.product`} className="form-control__label">{t('account.form.bank-details.products')}</label>
                <div className="form-control__input">
                  <Controller
                    name={`products`}
                    control={control}
                    render={({
                               field: {onChange, value, name}
                             }) => (
                      <Select
                        isMulti
                        name={name}
                        value={value}
                        options={products}
                        onChange={(option) => onChange(option)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {
            openBankInformationForm.bankInformation && openBankInformationForm.bankInformation.transactions &&
              <div className="col-md-12 mt-2">
                  <MovementBadge
                      title={t('account.form.bank-details.rib-used')}
                      transactions={openBankInformationForm.bankInformation.transactions}
                  />
              </div>
          }
      </div>
        <footer className="sidebar__footer">
        <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
        <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
      </footer>
        </form>
      </div>
    </>
  );
}

export default AddBankInformationForm
