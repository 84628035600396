import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import HeaderPage from '../../component/HeaderPage/HeaderPage'

import '../../../../assets/styles/page/_product.scss'
import MatchUrl from "../../../../domain/Utils/MatchedUrl"
import Spinner from "../../component/Spinner/Spinner"
import ErrorPage from "../Error/ErrorPage"
import {TError} from "../../../../domain/Error/Error"
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface"
import TabDistribution from "../../component/Tab/Distribution/TabDistribution"
import { SessionInterface } from '../../../../domain/Distribution'
import SessionGateway from '../../../../gateway/Distribution/SessionGateway'
import { setSessionActions } from '../../../store/component/distribution'
import Form from "../../component/Form/Distribution/Form"
import SessionPresenter from '../../../../presenter/Distribution/SessionPresenter'
import InvestorDatalist from '../../component/Datalist/Distribution/InvestorDatalist'
import InvestorDatalistFilter from '../../component/Sidebar/Distribution/InvestorDatalistFilter'

interface ILayoutProps {
  page: string
  mode: "add" | "edit" | "read"
}

const Layout: React.FC<ILayoutProps> = ({
  page,
  mode
}) => {
  const {t} = useTranslation()
  const { sessionType, uuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { refreshSession } = useAppSelector((state) => state.distribution)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const rules = useAppSelector((state) => state.me.me?.rules.distribution.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const [session, setSession] = useState<SessionInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<TError|null>(null)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  useEffect(() => {
    if(rules) {
      if(!rules?.update && !isLectureMode) {
        navigate(`/${t('url.distribution.read-setting')}/${sessionType}/${uuid}`)
      }
    }
  }, [rules])

  useEffect(() => {
    setIsLoading(true)
    if(uuid) {
      fetchSession(uuid)
      new SessionGateway()
        .getActions(uuid)
        .then(actions => dispatch(setSessionActions(actions)))
    } else {
      setIsLoading(false)
    }
  }, [uuid, refreshSession])

  const fetchSession = (uuid: string) => {
    new SessionGateway()
      .get(uuid)
      .then((data) => {
        const presenter = new SessionPresenter(data);
        setSession(presenter.load())
        setIsLoading(false)
      }).catch((e) => {
      setError(e)
      setIsLoading(false)
    })
  }

  const handleChoosePage = () => {
    if(page === 'investor-list' && session && session.id) {
      return <InvestorDatalist sessionId={session.id} />
    }

    return <Form isLectureMode={isLectureMode} session={session} />
  }

  if(error) {
    return <ErrorPage code={error.code.toString()} />
  }

  return (
    <>
      {page === 'investor-list' && session && session.id && <InvestorDatalistFilter />}
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage
          title={`${t(`distribution.session.${uuid ? "edit" : "add"}.title`)} ${(uuid && session?.id ) ? session?.label : ""}` }
        />
        <TabDistribution mode={mode} uuid={uuid}/>
        {(!isLoading && referential) ? handleChoosePage() : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default Layout
