import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'
import BankInformationDatalist from "../../../Datalist/bankInformationDatalist";

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'

import {ProspectInterface} from '../../../../../../domain/Prospect/Prospect'
import {
  setIsLoading,
  setOpenBankInformationFormEvent,
  setOpenProspectUploadForm
} from '../../../../../store/component/event'
import {
  reloadProspect,
  setBankInformations,
  setProductsReferentiel
} from '../../../../../store/component/prospect'
import {BankInformation} from '../../../../../../domain/Prospect/BankInformation'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import BankInformationGateway
  from '../../../../../../gateway/Prospect/BankInformation/BankInformationGateway'
import InitializeProspect
  from '../../../../../../useCase/Prospect/Initialization/InitializeProspect'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'
import AddUseCase from '../../../../../../useCase/Prospect/Add/AddUseCase'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import '../../../../../../assets/styles/page/_account.scss'
import DeleteUseCase from "../../../../../../useCase/Prospect/BankInformation/Delete/DeleteUseCase";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import DocumentGateway from "../../../../../../gateway/Prospect/Document/DocumentGateway";
import {DocumentCustomerInterface} from "../../../../../../domain/Customer/Document/Document";
import AlertInfo from "../../../Alert/AlertInfo/AlertInfo";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";

type Props = {
  prospect?: ProspectInterface
  isLectureMode?: boolean
  referential: ReferentielInterface
}

const BankDetails: FunctionComponent<Props> = ({prospect, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const openProspectUploadForm = useAppSelector((state) => state.event.openProspectUploadForm)
  const { prospectType } = useParams()
  const [inError, setInError] = useState<boolean>(false);
  const isLoading = useAppSelector((state) => state.event.isLoading)

  const bankInformationArray = useAppSelector((state) => state.prospect.bankInformationArray)
  const [bankInformationList, setBankInformationList] = useState<BankInformation[]|[]>(bankInformationArray)

  useEffect(() => {
    new ProductGateway().getAll().then((data) => {
      dispatch(setProductsReferentiel(data))
    })
  }, [])

  useEffect(() => {
    setBankInformationList(bankInformationArray)
    setInError(!bankInformationArray.some(item => item.main) && bankInformationArray.length > 0)
  }, [bankInformationArray])

  function removeItem(bankInformation: BankInformation) {
    if (prospect) {
      new DeleteUseCase(new BankInformationGateway()).execute(prospect, bankInformation).then((response) => {
        const bankInformationFilteredList = bankInformationList.filter(function(item) {
          return item.id !== bankInformation.id
        })
        setBankInformationList(bankInformationFilteredList)
        dispatch(setBankInformations(bankInformationFilteredList))
      })
    }
  }

  function onSubmit() {
    if (bankInformationList.length == 0) {
      return
    }

    if (undefined === prospect) {
      prospect = new InitializeProspect().initializeProspect()
    }

    if (null == prospect.prospectType ) {
      prospect.prospectType = undefined !== prospectType ? prospectType : 'physical'
    }

    prospect.bankInformations = bankInformationList

    if (null !== prospect.id) {
      dispatch(setIsLoading(true))
      new UpdateUseCase(new ProspectGateway()).execute(prospect).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'))
          dispatch(reloadProspect());
        } else {
          toastError(t('account.notify.update-error'));
        }
      })
      .catch(error => {
        toastError(t('account.notify.update-error'));
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
    } else {
      dispatch(setIsLoading(true))
      new AddUseCase(new ProspectGateway()).execute(prospect).then(uuid => {
        if (null !== uuid) {
          toastSuccess(t('account.notify.add-success'))
          window.location.href = `/${t('url.prospect.edit-general-information')}/${uuid}`

        } else {
          toastError(t('account.notify.add-error'));
        }
        dispatch(setIsLoading(false))
      })
      .catch(error => {
        toastError(t('account.notify.add-error'));
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
    }
  }

  const uploadCallBack = (show: boolean, documents: any[]|null, accountId: string|null, type: string|null, ribId: string|null) => {
    dispatch(setOpenProspectUploadForm( {
      show: show,
      documents: documents,
      prospectId: accountId,
      type: type,
      ribId: ribId
    }))

  }

  const viewFilesCallback = (ribId: string|null) => {
    if (prospect?.id && ribId) {
      new DocumentGateway(prospect?.id).getRibFiles(prospect?.id, ribId).then((response) => {
        if (response) {
          if (response.length > 0) {
            response.map((document: DocumentCustomerInterface) => {
              if (prospect?.id) {
                new DocumentGateway(prospect?.id).getRibFile(prospect?.id, ribId, document.id).then(response => {
                  const file = window.open('')

                  if (null !== file && response !== null) {
                    file.document.write(
                      "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='" + response + "'></iframe></body>"
                    )
                  }
                })
              }
            })
          } else {
            toastError(t('account.notify.ribs-file-not-found-error'));
          }
        }
      })
    }
  }

  const bankDetailsBlock = <div className={`form-bloc form--bloc--bank-details`}>
    <div className="form-bloc__title">{t('account.form.bank-details.title')}</div>
    {!isLectureMode && <div className="form-bloc__shortcut u-txt-right">
      <button type="button" className="button-reset u-txt-with-icon"
              onClick={() => dispatch(setOpenBankInformationFormEvent({show: true, bankInformation: null}))}>
        <img src={iconAdd} alt=""/>
        {t('account.form.bank-details.add')}
      </button>
    </div>}
    <div className="form-bloc__form flex-container">
      <div className="col-md-12">
        <BankInformationDatalist referential={referential} isProspect={true} viewFilesCallback={viewFilesCallback} uploadCallback={uploadCallBack} bankInformation={bankInformationArray} removeItem={removeItem} accountId={prospect?.id ?? null} isLectureMode={isLectureMode}/>
      </div>
    </div>
  </div>

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="button"
              className="button button--ink-2"
              disabled={isLoading}
              onClick={() => onSubmit()}
      >{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
        navigate(`/${t('url.prospect.dashboard')}`)
      }}>{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <div>
      <div className="flex-container" ref={measuredRef}>
        <div className="col-md-12">
          {inError &&
              <AlertInfo text={t('prospect.form.bank-information.missing-default-rib')} />
          }
        </div>
        <div className="col-md-12">{bankDetailsBlock}</div>
      </div>
      {renderFooter()}
    </div>
  )
}

export default BankDetails
