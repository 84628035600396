import {IFormInputGeneralInformationPhysicalPerson} from '../type'
import {CustomerInterface} from '../../../../../../../domain/Customer/Customer'

const updateCustomerKYC = (
  customer: CustomerInterface,
  data: IFormInputGeneralInformationPhysicalPerson
): CustomerInterface => ({
  ...customer,
  kycCategoryValue: data.kycCategoryValue,
  kycCategoryDate: data.kycCategoryDate,
  kycLabft: data.kycLabft,
  kycLabftDate: data.kycLabftDate,
  kycProfession: data.kycProfession,
  kycFatca: data.kycFatca,
  groupRelation: data.groupRelation,
  kycCategoryValueSecond: data.kycCategoryValueSecond,
  kycCategoryDateSecond: data.kycCategoryDateSecond,
  kycLabftSecond: data.kycLabftSecond,
  kycLabftDateSecond: data.kycLabftDateSecond,
  kycProfessionSecond: data.kycProfessionSecond,
  kycFatcaSecond: data.kycFatcaSecond,
  groupRelationSecond: data.groupRelationSecond
})

export default updateCustomerKYC
