import React, {FunctionComponent} from 'react'
import {useForm, useFormState} from "react-hook-form"
import {useTranslation} from 'react-i18next'
import {FilterRecurrenceSessionMandateInterface} from '../../../../../domain/Recurrence/RecurrenceSessionMandateInterface'
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenDatalistFilterRecurrenceSessionMandate} from '../../../../store/component/event'
import ProductDalalist from '../../Datalist/Product/ProductDalalist'
import MultiSelectCustom from '../../Elements/MultiSelect'
import InputDate from '../../Elements/InputDate'
import SelectCustom from "../../Elements/Select";
import {v4 as uuidV4} from "uuid";
import iconHeadingSearch from "../../../../../assets/images/icons/datalist-heading-search.svg";
import Input from "../../Elements/Input";

interface RecurrenceSessionMandateFormProps {
  initialFilters: FilterRecurrenceSessionMandateInterface;
  onClose?: () => void;
}

const RecurrenceSessionMandateForm: FunctionComponent<RecurrenceSessionMandateFormProps> = ({ initialFilters, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const referential = useAppSelector(({referential}) => referential.referential);
  const { register, control, handleSubmit, reset, setValue, getValues } = useForm<FilterRecurrenceSessionMandateInterface>();
  const { isDirty } = useFormState({ control });

  React.useEffect(() => {
    setValue('keyword', initialFilters.keyword);
    setValue('status', initialFilters.status);
  }, [initialFilters, setValue]);

  const onConfirm = (data: any) => {
    reset(data);
    dispatch(
      setOpenDatalistFilterRecurrenceSessionMandate({
        show: false,
        count: 0,
        filters: data,
      })
    );
  };

  const handleClose = () => {
    reset({
      status: [],
      keyword: null
    });

    if (onClose) {
      onClose()
    }
  };

  return (
    <form onSubmit={handleSubmit(onConfirm)} className="form-bloc--partner-address-form">
      <div className="form-bloc__form flex-container">
        <div className="col-md-6">
          <div key={uuidV4()} className="input-no-border">
            <img src={iconHeadingSearch} alt="" />
            <input {...register('keyword')} placeholder={t('common.search-by', {keyword: t('filters.by-keyword-or-id')})} className="u-mxs"/>
          </div>
        </div>
        <div className="col-md-6"></div>
          <SelectCustom
            classes={`col-md-4`}
            id={`status`}
            name={`status`}
            options={referential?.recurrence.session_status || []}
            register={register}
            label={t('recurrence.form.setting.session.status')}
            noChoiceOption={true}
          />
      </div>
      <div className="col-md-12">
        <div className="datalist__header session-filters flex items-center justify-end">
          <div className="filter__actions">
            <button type="submit" className="button button--submit">
              {t("search.submit")}
            </button>
            <button
              type="button"
              className="button button--white"
              onClick={handleClose}
            >
              {t("search.cancel")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RecurrenceSessionMandateForm;
