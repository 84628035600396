import {City} from "../../Referentiel/City/City";
import {Country} from "../../Referentiel/Country/Country";

interface SettingsInterface {
    id: string|null
    createdAt: string|null
    updatedAt: string|null
    deletedAt: string|null
    companyName: string|null
    registration: string|null
    address1: string|null
    address2: string|null
    address3: string|null
    postcode: string|null
    city: City|null
    country: Country|null
    bankName: string|null
    bankIban: string|null
    bankSwift: string|null
    colorPrimary: string|null
    colorSecondary: string|null
    colorFont: string|null
    colorBackground: string|null
    colorHeader: string|null
    commissionPdfFormat: string|null
    commissionCsvFormat: string|null
}

class Settings implements SettingsInterface{

    public id: string|null
    public createdAt: string|null
    public updatedAt: string|null
    public deletedAt: string|null
    public companyName: string|null
    public registration: string|null
    public address1: string|null
    public address2: string|null
    public address3: string|null
    public postcode: string|null
    public city: City|null
    public country: Country|null
    public bankName: string|null
    public bankIban: string|null
    public bankSwift: string|null
    public colorPrimary: string|null
    public colorSecondary: string|null
    public colorFont: string|null
    public colorBackground: string|null
    public colorHeader: string|null
    public commissionPdfFormat: string|null
    public commissionCsvFormat: string|null

    constructor(
        id: string|null,
        createdAt: string|null,
        updatedAt: string|null,
        deletedAt: string|null,
        companyName: string|null,
        registration: string|null,
        address1: string|null,
        address2: string|null,
        address3: string|null,
        postcode: string|null,
        city: City|null,
        country: Country|null,
        bankName: string|null,
        bankIban: string|null,
        bankSwift: string|null,
        colorPrimary: string|null,
        colorSecondary: string|null,
        colorFont: string|null,
        colorBackground: string|null,
        colorHeader: string|null,
        commissionPdfFormat: string|null = 'default',
        commissionCsvFormat: string|null = 'default'
    ) {
        this.id = id
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.deletedAt = deletedAt
        this.companyName = companyName
        this.registration = registration
        this.address1 = address1
        this.address2 = address2
        this.address3 = address3
        this.postcode = postcode
        this.city = city
        this.country = country
        this.bankName = bankName
        this.bankIban = bankIban
        this.bankSwift = bankSwift
        this.colorPrimary = colorPrimary
        this.colorSecondary = colorSecondary
        this.colorFont = colorFont
        this.colorBackground = colorBackground
        this.colorHeader = colorHeader
        this.commissionPdfFormat = commissionPdfFormat
        this.commissionCsvFormat = commissionCsvFormat
    }
}

export {
    Settings
}
export type { SettingsInterface }
