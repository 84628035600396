import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import { FilterSessionInvestorInterface, SessionInvestorListInterface } from '../../../../../domain/Distribution'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import { SortInterface, SortOrder } from '../../../../../domain/Utils/List'
import NumberFormat from '../../../../../domain/Utils/NumberFormat'
import DistributionSessionGateway from '../../../../../gateway/Distribution/SessionGateway'
import SessionInvestorListPresenter from '../../../../../presenter/Distribution/SessionInvestorListPresenter'
import { ListRequest } from '../../../../../useCase/Distribution/Session/Investor/ListRequest'
import ListUseCase from '../../../../../useCase/Distribution/Session/Investor/ListUseCase'
import { setOpenDatalistFilterDistributionSessionInvestor } from '../../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { useInvestorDataLoader } from '../../../customHook/distribution/useInvestorDataLoader'
import downloadBlobFile from '../../../util/DownloadBlobFile'
import Pagination from '../../Pagination/Pagination'
import TableHead from '../../Table/TableHead'
import HeaderRight from '../Element/HeaderRight'
import RowsPerPageSelector from '../RowsPerPageSelector'

type Props = {
  sessionId: string
}

const InvestorDatalist: FunctionComponent<Props> = ({ sessionId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const referential = useAppSelector(state => state.referential.referential) as ReferentielInterface | null
  const filterState = useAppSelector(state => state.event.openDatalistFilterDistributionSessionInvestor)
  const [sortOrder, setSortOrder] = useState<SortInterface>({ sortLabel: null, sortOrder: SortOrder.ASC })
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingExport, setLoadingExport] = useState(false)

  const methods = useForm()
  const { register, handleSubmit, setValue } = methods
  const numberRows = parseInt(methods.watch('numberRows')) || 50

  const dataLoaderParams = useMemo(
    () => ({
      sessionId,
      currentPage,
      numberRows,
      filters: filterState.filters,
      sortOrder,
    }),
    [sessionId, currentPage, numberRows, filterState.filters, sortOrder]
  )

  const { viewModel, setViewModel, loading } = useInvestorDataLoader(dataLoaderParams)

  const resetFilterState = () => {
    setValue('keyword', '')
    setValue('product', { id: '', value: '', label: '' })
    setValue('term', '')
    setValue('periodicity', '')
    setValue('paymentMethod', '')
  }

  const handleRowsChange = useCallback(
    (rows: number) => {
      setValue('numberRows', rows)
      setCurrentPage(1)
    },
    [setValue]
  )

  const onSubmit: SubmitHandler<FilterSessionInvestorInterface> = data => {
    dispatch(
      setOpenDatalistFilterDistributionSessionInvestor({
        show: false,
        count: filterState.count,
        filters: {
          keyword: data.keyword,
          product: data.product,
          term: data.term,
          periodicity: data.periodicity,
          paymentMethod: data.paymentMethod,
        },
      })
    )
  }

  const paginate = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber)
  }, [])

  const handleExport = useCallback(() => {
    setLoadingExport(true)
    new DistributionSessionGateway()
      .investorListExport(sessionId, filterState.filters)
      .then(response => {
        if (response) {
          downloadBlobFile(response, t('export.distributions-investors'))
        }
      })
      .finally(() => setLoadingExport(false))
  }, [])

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(
        setOpenDatalistFilterDistributionSessionInvestor({
          show: true,
          count: filterState.count,
          filters: filterState.filters,
        })
      )
    }
  }

  return (
    <>
      {loading ? (
        <div>{t('common.loading')}</div>
      ) : (
        viewModel && (
          <>
            <div className='datalist'>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='datalist__title'>{t(viewModel.title)}</div>
                  <div className='datalist__header flex'>
                    <div className='w-full flex justify-between items-center'>
                      <div className='filter__input'>
                        {viewModel.filtersShortcut.map((filter: { keyword: string; field: string; type: string }) => (
                          <div key={uuidV4()} className='input-no-border'>
                            <img src={iconHeadingSearch} alt='' />
                            <input
                              {...register(filter.field)}
                              placeholder={t('common.search-by', { keyword: t(filter.keyword) })}
                              className='u-mxs'
                            />
                          </div>
                        ))}
                      </div>
                      <div className='filter__actions flex items-center justify-end'>
                        <button type='submit' className='button button--submit'>
                          {t('search.submit')}
                        </button>
                        <button type='button' className='button button--white' onClick={resetFilterState}>
                          {t('search.cancel')}
                        </button>
                      </div>
                    </div>
                    <HeaderRight
                      numberOfActivatedFilters={filterState.count}
                      handleClickFilter={handleClickFilter}
                      handleClickExport={handleExport}
                      isLoadingExport={isLoadingExport}
                      allowExport
                      hideFilter={false}
                    />
                  </div>
                  <RowsPerPageSelector onRowsChange={handleRowsChange} />
                </form>
              </FormProvider>
              <div className='table-fix-head'>
                <table className='datalist__datas'>
                  <thead>
                    {viewModel.heading && (
                      <TableHead
                        typeFilter='API'
                        heading={viewModel.heading}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        viewModel={viewModel}
                        setViewModel={setViewModel}
                        filter={filterState.filters}
                        investorId={sessionId}
                        watchNumberRows={numberRows}
                        currentPage={currentPage}
                        listRequest={ListRequest}
                        gateway={DistributionSessionGateway}
                        listUseCase={ListUseCase}
                        listPresenter={SessionInvestorListPresenter}
                      />
                    )}
                  </thead>
                  <tbody>
                    {viewModel.data.length > 0 ? (
                      viewModel.data.map((item: SessionInvestorListInterface) => (
                        <tr key={uuidV4()}>
                          <td>{item.product.label}</td>
                          <td>
                            {
                              referential?.product.periodicity.find(option => option.value === item.product.periodicity)
                                ?.label
                            }
                          </td>
                          <td>
                            {
                              referential?.product.distribution_term.find(option => option.value === item.product.term)
                                ?.label
                            }
                          </td>
                          <td>{item.product.ribTitle}</td>
                          <td>{item.subscriber?.accountCode}</td>
                          <td>{item.subscriber?.legalName}</td>
                          <td>{item.coSubscriber?.legalName}</td>
                          <td className={`align-right`}>{NumberFormat.currencyFormat(item.totalNbShare, false, 0)}</td>
                          <td className={`align-right`}>{NumberFormat.currencyFormat(item.financialAmount)}€</td>
                          <td className={`align-right`}>{NumberFormat.currencyFormat(item.propertyAmount)}€</td>
                          <td className={`align-right`}>{NumberFormat.currencyFormat(item.pl)}€</td>
                          <td className={`align-right`}>{NumberFormat.currencyFormat(item.ps)}€</td>
                          <td className={`align-right`}>{NumberFormat.currencyFormat(item.amount)}€</td>
                          <td className={`align-center`}>{item.paymentDate}</td>
                          <td className={`align-center`}>{item.paymentMethod}</td>
                          <td>{item.subscriber?.iban}</td>
                          <td className={`align-center`}>{item.paymentState}</td>
                          <td className={`align-center`}>{item.sendMode}</td>
                          <td className={`align-center`}>{item.sendDate}</td>
                          <td className={`align-center`}>{item.sendState}</td>
                          <td>{item.subscriber.addressFormatted}</td>
                          <td>{item.coSubscriber?.addressFormatted}</td>
                          <td>{item.subscriber?.email}</td>
                          <td>{item.coSubscriber?.email}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={viewModel.heading.length}>{t('common.data-is-empty')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={numberRows || viewModel.pagination.itemsPerPage}
                numberOfItems={viewModel.pagination.numberOfItems}
                callback={paginate}
              />
            </div>
          </>
        )
      )}
    </>
  )
}

export default React.memo(InvestorDatalist)
