import React, {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import HeaderPage, {IHeaderAction} from '../../../component/HeaderPage/HeaderPage'

import '../../../../../assets/styles/page/_product.scss'
import TabCommissioningAdd from "../../../component/Tab/CommissioningAdd/TabCommissioningAdd";
import SettingForm from "../../../component/Form/Commissioning/Setting"
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import CommissioningGateway from "../../../../../gateway/Commissioning/CommissioningGateway";
import Spinner from "../../../component/Spinner/Spinner";
import ErrorPage from "../../Error/ErrorPage";
import {TError} from "../../../../../domain/Error/Error";
import {CommissioningSessionInterface} from "../../../../../domain/Commissioning/CommissioningList";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import Session from "../../../component/Form/Commissioning/Session";
import CommissioningSessionGateway from "../../../../../gateway/Commissioning/CommissioningSessionGateway";
import CommissionCheckList from "../../../component/Card/Comissioning/CommissionCheckList";
import {setOpenCommissionControlListForm} from "../../../../store/component/event";
import CommissionControlList from "../../../component/Sidebar/Commissioning/CommissionControlList";
import {setCommissionControlLists, setIsSelectedAllControl} from "../../../../store/component/commission";
import {COMMISSION_CHECKLIST_FIXTURES} from "../../../../../fixtures/Commissioning";
import {setCommissionActions} from "../../../../store/component/commission";

interface Props {
  page: string
  mode: "add" | "edit" | "read"
}

const CommissioningLayout: FunctionComponent<Props> = ({page, mode}) => {
  const {t} = useTranslation()
  const {commissioningType, uuid} = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const isSettingTab = MatchUrl.find(location.pathname, 'parametrage')
  const { refreshCommission } = useAppSelector((state) => state.commission)
  const commissioningRules = useAppSelector((state) => state.me.me?.rules.commissioning.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const [commissioning, setCommissioning] = useState<CommissioningSessionInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<TError|null>(null)
  const controlLists = useAppSelector((state) => state.commission.controlLists);
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  const headerActions: IHeaderAction = {
    actions: isSettingTab ? [
      <CommissionCheckList
        title={t('commissioning.block.control-list')}
        checkLists={controlLists}
        handleClick={() => dispatch(setOpenCommissionControlListForm({
          show: true,
          checkLists: controlLists,
          commissioning: commissioning
        }))}
      />,
    ] : []
  }

  useEffect(() => {
    if(commissioningRules) {
      if(!commissioningRules?.update && !isLectureMode) navigate(`/${t('url.commissioning.read-session')}/${commissioningType}/${uuid}`)
    }
  }, [commissioningRules])

  useEffect(() => {
    setIsLoading(true)
    if(uuid) {
      fetchCommissioning(uuid)
      // Load commission action status first render.
      new CommissioningSessionGateway()
        .getActions(uuid)
        .then(actions => dispatch(setCommissionActions(actions)))
    } else {
      setIsLoading(false)
    }
  }, [uuid, refreshCommission])

  useEffect(() => {
    dispatch(setIsSelectedAllControl(false))
    const controls = [...COMMISSION_CHECKLIST_FIXTURES.map(item => {
      return {...item}
    })];

    if (commissioning?.controls && commissioning?.controls.length > 0) {
      let totalChecked = 0;

      controls[0].children = controls[0].children.map(item => {
        if (commissioning?.controls?.includes(item.formName)) {
          totalChecked++;

          return {
            ...item,
            status: true
          }
        }

        return item;
      })

      if (totalChecked === controls[0].children.length) {
        controls[0].status = true
        dispatch(setIsSelectedAllControl(true))
      } else {
        controls[0].status = false
      }
    }

    dispatch(setCommissionControlLists(controls));
  }, [commissioning])

  const fetchCommissioning = (uuid: string) => {
    new CommissioningSessionGateway()
      .getCommissioningSession(uuid)
      .then((res) => {
        const copyResponse = {...res} as CommissioningSessionInterface
        if(copyResponse?.criterias?.products?.length) {
          const newArrayProducts = copyResponse.criterias?.products
            .map((
              product: {
                acronyme: string,
                id: string,
                label:string,
                session_id: string
              }) => {
            return {
              id: product.id,
              label: product.label,
              value: product.id
            }
          })
          copyResponse.criterias.products = newArrayProducts

        }

        if(copyResponse?.criterias?.partners?.length) {
          const newArrayPartners = copyResponse.criterias?.partners.map((partner: any) => {
            return {
              id: partner.id,
              label: partner.label,
              value: partner.id
            }
          })
          copyResponse.criterias.partners = newArrayPartners
        }

        setCommissioning({...copyResponse})
        setIsLoading(false)
      }).catch((e) => {
      setError(e)
      setIsLoading(false)
    })
  }

  const handleChoosePage = () => {
    switch (page) {
      case "upfront":
        if (mode === "add") {
          return <SettingForm isLectureMode={isLectureMode}/>
        }
        if (mode === "edit" && commissioning) {
          if(isSettingTab) {
            return <SettingForm commissioning={commissioning} isLectureMode={isLectureMode}/>
          } else {
            return <Session isLectureMode={isLectureMode}/>
          }
        }
        if (mode === "read" && commissioning) {
          if(isSettingTab) {
            return <SettingForm commissioning={commissioning} isLectureMode={isLectureMode}/>
          } else {
            return <Session isLectureMode={isLectureMode}/>
          }
      }
        break
      case "running":
        return console.log('case running')

      default: console.log(`Sorry, we are out of ${page}.`);
    }
  }

  const handleSidesBarDependReferential = (referential: ReferentielInterface) => {
    return (
      <>
        {page === "upfront" && isSettingTab && <CommissionControlList referential={referential} isLectureMode={isLectureMode}/>}
      </>
    )
  }

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      {referential && handleSidesBarDependReferential(referential)}
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage
          title={`${t(`commissioning.${uuid ? "edit" : "add"}.title-${commissioningType}`)} ${(uuid && commissioning?.code ) ? commissioning?.code : ""}` }
          actionHeaderList={headerActions}
        />
        <TabCommissioningAdd mode={mode} uuid={uuid}/>
        {(!isLoading && referential) ? handleChoosePage() : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default CommissioningLayout
