import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'

import SelectCustom from '../../../../Elements/Select'
import {ReferentielInterface} from '../../../../../../../domain/Referentiel/ReferentielInterface'
import RadioYesOrNot from '../../../../Elements/RadioYesOrNot'
import Input from '../../../../Elements/Input'

type Props = {
  referential: ReferentielInterface
  indexPerson: number
}

const FiscalityForm: FunctionComponent<Props> = ({referential, indexPerson}) => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <div className="flex-container">
          {indexPerson < 1 &&
            <SelectCustom classes="col-md-12" label={t('account.form.fiscality.paymentMethod')}
                          id={`persons.${indexPerson}.fiscality.paymentMethod`}
                          name={`persons.${indexPerson}.fiscality.paymentMethod`}
                          options={referential.prospect.payment_method || []}
                          register={register}
            />
          }
          <div className="col-md-12">
            {t('account.form.person')} {indexPerson + 1}
          </div>
          <div className="col-md-12"></div>
          <div className="col-md-6">
            <Input id={`persons.${indexPerson}.fiscality.nif`}
                   name={`persons.${indexPerson}.fiscality.nif`}
                   label={t('account.form.fiscality.nif')}
                   register={register}
            />
          </div>
          <SelectCustom classes="col-md-6" label={t('account.form.fiscality.taxResidency')}
                        id={`person.${indexPerson}.fiscality.taxResidency`}
                        name={`persons.${indexPerson}.fiscality.taxResidency`}
                        options={referential.prospect.tax_residency || []}
                        register={register}
          />
          <div className="col-md-6">
            <RadioYesOrNot id={`persons.${indexPerson}.fiscalityIncomeTax`}
                           name={`persons.${indexPerson}.fiscalityIncomeTax`}
                           label={t('account.form.fiscality.incomeTax')}
                           register={register}
            />
          </div>
          <div className="col-md-6">
            <RadioYesOrNot id={`persons.${indexPerson}.fiscalitySubjectIfi`}
                           name={`persons.${indexPerson}.fiscalitySubjectIfi`}
                           label={t('account.form.fiscality.subjectIfi')}
                           register={register}
            />
          </div>
          <SelectCustom classes="col-md-6" label={t('account.form.fiscality.rate')}
                        id={`persons.${indexPerson}.fiscality.rate`}
                        name={`persons.${indexPerson}.fiscality.rate`}
                        options={referential.prospect.nr_rate || []}
                        register={register}
          />

          <div className="col-md-6">
            <RadioYesOrNot id={`persons.${indexPerson}.fiscalityWithholdingTax`}
                           name={`persons.${indexPerson}.fiscalityWithholdingTax`}
                           label={t('account.form.fiscality.withholdingTax')}
                           register={register}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiscalityForm
