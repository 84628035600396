import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import dayjs from 'dayjs'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import blockScrollBody from '../../../../util/BlockScroll'
import getClassForOverlay from '../../../../util/Sidebar'
import {setOpenEditRecurrenceMandate} from '../../../../../store/component/event'
import InputDate from '../../../Elements/InputDate'
import {EditRecurrenceMandateInterface} from '../../../../../../domain/Recurrence/RecurrenceMandate'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import Spinner from '../../../Spinner/Spinner'
import RecurrenceMandateGateway from '../../../../../../gateway/Recurrence/RecurrenceMandateGateway'
import SelectCustom from '../../../Elements/Select'
import Input from '../../../Elements/Input'
import {toastError, toastSuccess} from '../../../../util/Toast'


const RecurrenceMandateDatalistFilter: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openEditRecurrenceMandate = useAppSelector(
    (state) => state.event.openEditRecurrenceMandate
  );

  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (openEditRecurrenceMandate.mandateId) {
      reset({});
      (new RecurrenceMandateGateway()).get(openEditRecurrenceMandate.mandateId).then(response => {
        if (response) {
          setValue('reference', response.id)
          setValue('status', response.status)
          setValue('start', dayjs(response.start, 'YYYY-MM-DD').format('DD/MM/YYYY'))
          setValue('end', dayjs(response.end, 'YYYY-MM-DD').format('DD/MM/YYYY'))
          setValue('periodicity', response.periodicity)
          setValue('shareCount', response.recurrenceShare)
          setValue('rum', response.rum)
          setValue('rumStatus', response.rumStatus)
          setValue('recurrenceMode', response.recurrenceMode)
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [openEditRecurrenceMandate.mandateId])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm<EditRecurrenceMandateInterface>();

  useEffect(() => {
    blockScrollBody(openEditRecurrenceMandate.show);
  }, [openEditRecurrenceMandate]);

  const onConfirm = (data: any) => {
    setLoading(true);
    (new RecurrenceMandateGateway()).update(data, openEditRecurrenceMandate.mandateId).then(response => {
      if (response) {
        reset(data);
        dispatch(
          setOpenEditRecurrenceMandate({
            show: false,
            mandateId: '',
            readOnly: false
          })
        );
        toastSuccess(t('recurrence.mandate.edit.notify.update-success'))
      }
    }).catch(() => {
      toastError(t('recurrence.mandate.edit.notify.update-error'))
    }).finally(() => {
      setLoading(false)
    })
  };

  function handleClose() {
    reset({});
    dispatch(
      setOpenEditRecurrenceMandate({
        show: false,
        mandateId: '',
        readOnly: false
      })
    );
  }

  const checkRecurrenceDate = (): boolean => {
    const date = getValues('end');
    return dayjs().isAfter(dayjs(date, 'DD/MM/YYYY'))
  }

  return (
    <>
      {referential &&
        <>
          <div
            className={`overlay ${getClassForOverlay(
              openEditRecurrenceMandate.show
            )}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openEditRecurrenceMandate.show ? "sidebar--active" : ""
            }`}
          >
            {isLoading && (
              <section className="container--spinner">
                <Spinner size={150}/>
              </section>
            )}
            {!isLoading && (
              <form
                onSubmit={handleSubmit(onConfirm)}
                className="form-bloc form-bloc--partner-address-form"
              >
                <div className="sidebar__content">
                  <div className="title">{openEditRecurrenceMandate.readOnly ? t("recurrence.mandate.read.title") : t("recurrence.mandate.edit.title")}</div>
                  <div className="form-bloc__form flex-container u-mxAuto">
                    <div
                      className={`form--bloc--recurrence w-full`}>
                      <div className="">
                        <SelectCustom id="status" name="status" classes="col-md-12"
                                      register={register}
                                      label={t('movement.pre-subscription.form.recurrence.status')}
                                      options={referential?.recurrence.mandate_status || null}
                                      readOnly={openEditRecurrenceMandate.readOnly || checkRecurrenceDate()}/>
                        <Input id="reference" name="reference" classes="col-md-12"
                               register={register}
                               label={t('movement.pre-subscription.form.recurrence.num-recurrence')} readOnly={true}/>
                        <InputDate id="start" name="start" classes="col-md-12"
                                   register={register}
                                   control={control}
                                   label={t('movement.pre-subscription.form.recurrence.start-of-recurrence')}
                                   readOnly={openEditRecurrenceMandate.readOnly}/>
                        <SelectCustom id="periodicity" name="periodicity" classes="col-md-12"
                                      register={register}
                                      label={t('movement.pre-subscription.form.recurrence.periodicity-frequency')}
                                      options={referential?.wallet_subscription.periodicity || null}
                                      readOnly={openEditRecurrenceMandate.readOnly}/>
                        <InputDate id="end" name="end" classes="col-md-12"
                                   register={register}
                                   control={control}
                                   label={t('movement.pre-subscription.form.recurrence.end-of-recurrence')}
                                   readOnly={openEditRecurrenceMandate.readOnly}/>
                        <Input id="shareCount" name="shareCount" classes="col-md-12"
                               register={register}
                               label={t('movement.pre-subscription.form.recurrence.number-of-shares')}
                               readOnly={openEditRecurrenceMandate.readOnly}/>
                        <Input id="rum" name="rum" classes="col-md-12" register={register}
                               label={t('movement.pre-subscription.form.recurrence.rum-code')}
                               readOnly={openEditRecurrenceMandate.readOnly}/>
                        <SelectCustom id="rumStatus" name="rumStatus" classes="col-md-12"
                                      register={register}
                                      label={t('movement.pre-subscription.form.recurrence.rum-status')}
                                      options={referential?.wallet_subscription.rum_status || null}
                                      readOnly={openEditRecurrenceMandate.readOnly}/>
                      </div>
                    </div>
                    <footer className="sidebar__footer">
                      {!openEditRecurrenceMandate.readOnly && 
                        <button type="submit" className="button button--ink-2 u-mrm">
                          {t("common.save")}
                        </button>
                      }
                      <button
                        type="button"
                        className="button button--ink-2 button--ink-2--outline"
                        onClick={() => handleClose()}
                      >
                        {openEditRecurrenceMandate.readOnly ? t("common.close") : t("common.cancel")}
                      </button>
                    </footer>
                  </div>
                </div>
              </form>
            )}
          </div>
        </>
      }
    </>
  );
}

export default RecurrenceMandateDatalistFilter
